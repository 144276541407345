import React, { useEffect, useState } from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { GroupEditModal } from "./GroupEditModal";
import {
    deleteGroupDBAction,
    fetchGroupsDBAction,
    updateGroupDBAction,
} from "../store_lib/actions/userDataActions";
import { useAccount, useMsal } from "@azure/msal-react";
import { Overlay } from "../General/Overlay";

const Div = styled.div`
    .list-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 400px;
        max-height: 400px;
    }

    .list-container ul {
        border: 1px solid #dee2e6;
        border-radius: 5px;
        width: 100%;
        height: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        overflow-y: auto;
    }

    .btn-dropdown {
        padding: 0.375rem 0.75rem;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        font-size: 1rem;
        line-height: 1.5;
        background-color: #fff;
        color: #495057;
    }

    .btn-dropdown:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
`;

// let test_groups_data_dict = {
//     1: {
//         id: 1,
//         name: "Group 1",
//         description: "Group 1 description",
//         color: "blue",
//         is_shared: false,
//         owner: "user1",
//     },
//     2: {
//         id: 2,
//         name: "Group 2",
//         description: "Group 2 description",
//         color: "green",
//         is_shared: true,
//         owner: "user2",
//     },
//     3: {
//         id: 3,
//         name: "Group 3",
//         description: "Group 3 description",
//         color: "red",
//         is_shared: false,
//         owner: "user3",
//     },
// }

const GroupsModal = (props) => {
    const { showModal, handleCloseModal } = props;

    const dispatch = useDispatch();

    // group selection
    // const [groups_data_dict, setGroupDict] = useState(test_groups_data_dict);
    const user_data_groups = useSelector((state) => state.user_data.groups);
    const user_data_loading = useSelector((state) => state.user_data.user_data_loading);
    const current_group_global = useSelector((state) => state.user_data.current_group);

    // const [current_group, setCurrentGroup] = useState(Object.keys(user_data_groups)[0]);
    let group0 = current_group_global === undefined ? "none" : current_group_global.id;
    const [current_group_key, setCurrentGroupKey] = useState(group0);

    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const username = account.username;

    // group edit modal
    const [show_edit_modal, setShowEditModal] = useState(false);
    const [edit_modal_mode, setEditModalMode] = useState("add"); //edit or add

    // Well pool on the right
    const [well_pool_right, setWellPoolRight] = useState([]);

    // Selected Wells on the right
    const [selected_wells_right, setSelectedWellsRight] = useState([]);
    const toggleWellRight = (p2_merrick_id) => {
        if (selected_wells_right.includes(p2_merrick_id)) {
            setSelectedWellsRight(
                selected_wells_right.filter((well_p2_merrick_id) => well_p2_merrick_id !== p2_merrick_id)
            );
        } else {
            setSelectedWellsRight([...selected_wells_right, p2_merrick_id]);
        }
    };

    // Well selection pool left
    const [searched_text, setSearchedText] = useState("");
    let well_data = useSelector((state) => state.raw_data.well_data);
    let filtered_well_data;
    if (Array.isArray(well_data)) {
        filtered_well_data = well_data.filter((well) => {
            return well.p2_well_name.toLowerCase().includes(searched_text.toLowerCase());
        });
    } else {
        filtered_well_data = [];
        well_data = [];
    }

    // Selected Wells on the left
    const [selected_wells_left, setSelectedWellsLeft] = useState([]);
    const toggleWellLeft = (p2_merrick_id) => {
        if (selected_wells_left.includes(p2_merrick_id)) {
            setSelectedWellsLeft(
                selected_wells_left.filter((well_p2_merrick_id) => well_p2_merrick_id !== p2_merrick_id)
            );
        } else {
            setSelectedWellsLeft([...selected_wells_left, p2_merrick_id]);
        }
    };

    let edit_group_data;
    if (current_group_key !== "none") {
        edit_group_data = {
            ...user_data_groups[current_group_key],
            wells: well_pool_right,
            };
    } else {
        edit_group_data = undefined;
    }

    const new_group_data = {
        name: "",
        description: "",
        color: "gray",
        is_shared: false,
        owner: username,
        wells: [],
    };

    const handleCreateGroup = (group_data) => {
        const id = group_data.id;
        setCurrentGroupKey(id);
    };

    const handleSave = async () => {
        // dispatch(updateGroupActionDB({
        await dispatch(updateGroupDBAction(edit_group_data));
    };

    const onDeleteButtonClick = async () => {
        if (window.confirm("Are you sure you want to delete this group?")) {
            dispatch(deleteGroupDBAction(edit_group_data.id));
            setCurrentGroupKey("none");
        }
    };

    const handleReset = () => {
        //unselect left and right
        setSelectedWellsLeft([]);
        setSelectedWellsRight([]);
        //reset right
        if (current_group_key !== 'none'){
            setWellPoolRight(user_data_groups[current_group_key].wells);
        } else {
            setWellPoolRight([]);
        }
    };

    const refreshGroups = async () => {
        await dispatch(fetchGroupsDBAction());
    };

    useEffect(() => {
        //load wells_pool_right
        if (Object.keys(user_data_groups).length === 0) return;
        if (current_group_key !== "none"){
            let well_pools_right = user_data_groups[current_group_key].wells;
            setWellPoolRight(well_pools_right);
        } else {
            setWellPoolRight([]);
        }
    }, [current_group_key]);

    // const n_user_data_groups = Object.keys(user_data_groups).length;
    // useEffect(() => {
    //     if (!showModal) return;
    //     setCurrentGroup(Object.keys(user_data_groups)[0]);
    // }, [n_user_data_groups, showModal]);

    let is_edit = edit_modal_mode === "edit";

    const group = user_data_groups[current_group_key];

    const group_disabled = group ? group.owner !== username : true;

    const handleCloseEditModal = (res) => {
        if (res) {
            // update current group
            setCurrentGroupKey(res.id);
        }
        setShowEditModal(false);
    };

    return (
        <div>
            <GroupEditModal
                mode={edit_modal_mode} //edit or add
                group_data={is_edit ? edit_group_data : new_group_data}
                showModal={show_edit_modal}
                onCreateGroup={handleCreateGroup}
                handleCloseModal={handleCloseEditModal}
            />

            <Modal
                // className={user_data_loading ? "modal-disabled" : ""}
                centered
                size="xl"
                show={showModal}
                onHide={handleCloseModal}
            >
                <Overlay disabled={user_data_loading || show_edit_modal} />
                <Modal.Header closeButton>
                    <Modal.Title>Group Assignment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Div>
                        <div className="mb-3 p-4">
                            <label className="form-label">Group</label>
                            <div className="row g-3 align-items-center">
                                <div className="col-lg-8 col-12">
                                    <select
                                        className="form-select form-select-lg"
                                        value={current_group_key}
                                        onChange={(e) => setCurrentGroupKey(e.target.value)}
                                        name=""
                                        id=""
                                    >
                                        <option disabled
                                            key="none"
                                            value="none"
                                            className="gray"
                                            // gray letters
                                            // gray font color
                                            style={{
                                                color: "#6c757d",
                                            }}
                                        >   
                                            Select group...
                                        </option>
                                        {Object.keys(user_data_groups)
                                            .filter((key) => user_data_groups[key].owner === username)
                                            .map((key) => (
                                                <option className="small" key={key} value={key}>
                                                    {user_data_groups[key].name}
                                                </option>
                                            ))}
                                        <option disabled>──────Shared by Others──────</option>
                                        {Object.keys(user_data_groups)
                                            .filter((key) => user_data_groups[key].owner !== username)
                                            .map((key) => (
                                                <option className="small" key={key} value={key}>
                                                    {user_data_groups[key].name} - {user_data_groups[key].owner}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="col-lg-4 col-12 d-flex">
                                    <button
                                        type="button"
                                        className="btn btn-primary ms-2"
                                        onClick={() => {
                                            setEditModalMode("add");
                                            setShowEditModal(true);
                                        }}
                                    >
                                        Add
                                    </button>
                                    <button
                                        onClick={() => {
                                            setEditModalMode("edit");
                                            setShowEditModal(true);
                                        }}
                                        type="button"
                                        className="btn btn-primary ms-3"
                                        disabled={group_disabled}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger ms-3"
                                        onClick={onDeleteButtonClick}
                                        disabled={group_disabled}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                            <label className="form-label mt-3">Description</label>
                            <div className="row g-3 align-items-center">
                                <div className="col-lg-8 col-12">
                                    <textarea
                                        className="form-control"
                                        id="group-description"
                                        value={group ? group.description : ""}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row p-4">
                            <div className="col-lg-5">
                                <div className="list-container">
                                    <div className="input-group rounded mb-2">
                                        <input
                                            type="search"
                                            className="form-control form-control-sm rounded"
                                            value={searched_text}
                                            onChange={(e) => setSearchedText(e.target.value)}
                                            placeholder="Search"
                                            aria-label="Search"
                                            aria-describedby="search-addon"
                                        />
                                        <span className="input-group-text border-0" id="search-addon">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                    {/* LEFT WELL LIST */}
                                    <ul className="list-group">
                                        {filtered_well_data.map((well) => (
                                            <li
                                                className={
                                                    "list-group-item " +
                                                    (selected_wells_left.includes(well.p2_merrick_id)
                                                        ? "active "
                                                        : "") +
                                                    (well_pool_right.includes(well.p2_merrick_id) ? "disabled " : "")
                                                }
                                                aria-current={
                                                    selected_wells_left.includes(well.p2_merrick_id) ? "true" : "false"
                                                }
                                                onClick={() => toggleWellLeft(well.p2_merrick_id)}
                                                key={well.p2_merrick_id}
                                            >
                                                {well.p2_well_name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 align-items-center d-flex justify-content-center">
                                <div className="row justify-content-center mt-3 mb-3 ms-0 me-0 w-100">
                                    <button
                                        type="button"
                                        className="btn btn-primary col-lg-8 col-3 m-1 m-lg-0 mb-lg-3"
                                        disabled={group_disabled}
                                        onClick={() => {
                                            setWellPoolRight([...well_pool_right, ...selected_wells_left]);
                                            setSelectedWellsLeft([]);
                                        }}
                                    >
                                        {" "}
                                        {">>"}{" "}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary col-lg-8 col-3 m-1 m-lg-0 mb-lg-3"
                                        disabled={group_disabled}
                                        onClick={() => {
                                            //remove from well pool right
                                            let new_well_pool_right = well_pool_right.filter(
                                                (well_p2_merrick_id) =>
                                                    !selected_wells_right.includes(well_p2_merrick_id)
                                            );
                                            setWellPoolRight(new_well_pool_right);
                                            setSelectedWellsRight([]);
                                        }}
                                    >
                                        {" "}
                                        {"<<"}{" "}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary col-lg-8 col-4 m-1 m-lg-0 mb-lg-3"
                                        onClick={handleReset}
                                        disabled={group_disabled}
                                    >
                                        Reset{" "}
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="list-container">
                                    {/* RIGHT WELL LIST */}
                                    <ul className="list-group">
                                        {well_pool_right.map((well_p2_merrick_id) => (
                                            <li
                                                className={
                                                    "list-group-item " +
                                                    (selected_wells_right.includes(well_p2_merrick_id) ? "active " : "")
                                                }
                                                onClick={() => toggleWellRight(well_p2_merrick_id)}
                                                aria-current={
                                                    selected_wells_right.includes(well_p2_merrick_id) ? "true" : "false"
                                                }
                                                key={well_p2_merrick_id}
                                            >
                                                {
                                                    well_data.filter(
                                                        (well_data) => well_data.p2_merrick_id === well_p2_merrick_id
                                                    )[0].p2_well_name
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-between w-100 align-items-center">
                        <div className="d-flex align-items-center">
                            <button
                                className="btn btn-outline-primary btn-sm me-2"
                                // className="btn btn-secondary-outline btn-sm me-2"
                                type="button"
                                onClick={refreshGroups}
                            >
                                <i
                                    className="fa-solid fa-arrows-rotate "
                                    //   onClick={updateResolution}
                                    title="Refresh Groups"
                                    aria-label="Refresh Groups"
                                />
                            </button>
                        </div>
                        <div>
                            <Button className="ms-2" variant="primary" disabled={group_disabled} onClick={handleSave}>
                                Save Group Wells
                            </Button>
                            <Button className="ms-2" variant="primary" onClick={handleCloseModal}>
                                Exit
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default GroupsModal;
