import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import BlogComments from './BlogComments'

function CommentModal(props) {
  const {
    show,
    handleClose,
    createComment,
    current_point,
    updateComments,
    account
  } = props

  const { comments } = props

  const onClose = () => {
    handleClose()
  }

  let title = ''

  if (current_point) {
    const value = Math.round(current_point.y * 100) / 100
    const date = new Date(current_point.x)
    let is_12_am = date.toUTCString().split(' ')[4] === '00:00:00'
    let formatted_date;
    if (is_12_am) {
      // ISO 8601 format for date only
      formatted_date = date.toISOString().split('T')[0]
    }
    else {
      // ISO 8601 format for date and time
      formatted_date = date.toString()
    }
    // to UTC
    // const formatted_date = new Date(current_point.x).toUTCString();
    // title = current_point.name + " - " + formatted_date + " - " + value;
    title = formatted_date + ' - ' + value
  }

  return (
    <Modal show={show} onHide={onClose} size='lg'>
      <Modal.Header>
        <Modal.Title className='d-none d-lg-block me-5'>
          {comments.length || 0} Comment{comments.length > 1 && 's'}
        </Modal.Title>
        <Modal.Title style={{ textAlign: 'right' }}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BlogComments
          comments={comments}
          updateComments={updateComments}
          createComment={createComment}
          account={account}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CommentModal
