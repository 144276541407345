import React from "react";
import styled from "@emotion/styled";
import colors from "../styles/colors";
import dimensions from "../styles/dimensions";
// import Logo from "./_ui/Logo";
import logo_img from "../images/bkv_logo.png";
import { useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import {signOut} from "./utils/auth_utils"
// import { Helmet } from "react-helmet";

const HeaderContainer = styled("div")`
    padding-top: 3.75em;
    padding-bottom: 3em;
`;

const HeaderContent = styled("div")`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const HeaderLinks = styled("div")`
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 7em;
    justify-content: flex-end;
    width: 100%;
    max-width: 200px;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        grid-gap: 5.5em;
    }

    @media (max-width: ${dimensions.maxwidthMobile}px) {
        grid-gap: 2.5em;
    }

    a {
        color: currentColor;
        text-decoration: none;
        border-bottom: 3px solid transparent;
        font-weight: 600;
        font-size: 0.95em;
        height: 100%;
        padding-bottom: 1.25em;
        padding-top: 0.25em;
        display: block;
        position: relative;

        &:after {
            position: absolute;
            content: "";
            bottom: 0;
            width: 18px;
            height: 3px;
            background: transparent;
            bottom: -3px;
            right: 50%;
            margin-right: -9px;
            transition: 100ms ease-in-out background;
        }

        &:hover {
            &:after {
                background: ${colors.blue500};
                transition: 100ms ease-in-out background;
            }
        }

        &.Link--is-active {
            &:after {
                background: ${colors.blue500};
                transition: 100ms ease-in-out background;
            }
        }
    }
`;

const Header = () => {

    const { instance } = useMsal();


    const handleLogout = async (e) => {
        e.preventDefault();
        await signOut(instance);
    }
    return (
        <HeaderContainer>
            <HeaderContent>
                <a to="/">
                    {/* <Logo/> */}
                    {/* size width=100px */}
                    <img src={logo_img} alt="logo" style={{ width: "150px" }} />
                </a>
                <div>
                    <button
                        type="button"
                        onClick={(e) => handleLogout(e)}
                        className="btn btn-primary"
                    >
                        SignOut
                    </button>
                </div>
                {/* <HeaderLinks>
                <a
                    activeClassName="a--is-active"
                    to="/">
                    Home
                </a>
                <a
                    activeClassName="a--is-active"
                    to="/projects">
                    Projects
                </a>
            </HeaderLinks> */}
            </HeaderContent>
        </HeaderContainer>
    );
};

export default Header;
