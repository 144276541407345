
// let test_groups_data_dict = {
//     1: {
//         id: 1,
//         name: "Group 1",
//         description: "Group 1 description",
//         color: "blue",
//         is_shared: false,
//         owner: "user1",
//         wells: [] // list of merrick ids
//     },
//     2: {
//         id: 2,
//         name: "Group 2",
//         description: "Group 2 description",
//         color: "green",
//         is_shared: true,
//         owner: "user2",
//         wells: []
//     },
//     3: {
//         id: 3,
//         name: "Group 3",
//         description: "Group 3 description",
//         color: "red",
//         is_shared: false,
//         owner: "user3",
//         wells: []
//     },
// }


const initState = {
    // groups: {},
    groups: {},
    current_user: undefined,
    user_data_loading: false,
    current_group: undefined, //{}//modified from null to undefined
}


const userDataReducer = (state = initState, action) => {
    switch (action.type) {
        case "LOAD_GROUPS":
            return {
                ...state,
                groups: action.payload,
            };
        case "SAVE_GROUPS":
            return {
                ...state,
                groups: action.payload,
            };
        case "ADD_GROUP":
            return {
                ...state,
                groups: {
                    ...state.groups,
                    [action.payload.id]: action.payload,
                },
            };
        case "UPDATE_GROUP":
            let new_state = {
                ...state,
                groups: {
                    ...state.groups,
                    [action.payload.id]: action.payload,
                },
            };
            if (state.current_group === undefined) {
                return new_state;
            } else {
                let current_group_id = state.current_group.id;
                new_state.current_group = new_state.groups[current_group_id];
            }
            return new_state
        case "DELETE_GROUP":
            let new_groups = { ...state.groups };
            delete new_groups[action.payload];
            return {
                ...state,
                groups: new_groups,
            };
        case "SET_USER_DATA_LOADING":
            return {
                ...state,
                user_data_loading: action.payload,
            };
        case "SET_CURRENT_GROUP":
            return {
                ...state,
                current_group: action.payload,
            };
        default:
            return state;
    }
};

export default userDataReducer;
