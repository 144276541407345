import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentUDC } from "bkv-components/store_lib/actions/settingsActions";
import { getP2ScadaIds } from "bkv-components/General/data_transformation";

const all_sensors = ["CMLINE0", "INSFLG0", "PCASE0", "PTUBE0"];

export const getUnavailableUDCs = (active_p2_merrick_ids, well_data_hashed) => {
    // calculate available_sensors and disabled_udcs based on availability
    let available_sensors = [];
    let unavailable_udcs;
    if (active_p2_merrick_ids) {
        active_p2_merrick_ids.forEach((p2_merrick_id) => {
            const well_info = well_data_hashed[p2_merrick_id];
            let sensor_ids = well_info.sensor_ids; //str of shape "['CMLINE0', 'PCASE0', 'PTUBE0']"
            // replace single quotes with double quotes
            sensor_ids = sensor_ids.replace(/'/g, '"');

            let sensor_ids_array = JSON.parse(sensor_ids);
            available_sensors = [...available_sensors, ...sensor_ids_array];
        });
    }
    available_sensors = [...new Set(available_sensors)];
    unavailable_udcs = all_sensors.filter(
        (udc) => available_sensors.indexOf(udc) === -1
    );
    return unavailable_udcs;
};

function SensorSelection() {
    const dispatch = useDispatch();

    const active_udc = useSelector((state) => state.settings.active_udc);
    const active_p2_merrick_ids = useSelector(
        (state) => state.settings.active_p2_merrick_ids
    );
    const well_data_hashed = useSelector(
        (state) => state.raw_data.well_data_hashed
    );
    // const sensor_info_hashed = useSelector(
    //     (state) => state.raw_data.sensor_info_hashed
    // );

    // const active_p2_scada_ids = getP2ScadaIds(
    //     active_p2_merrick_ids,
    //     well_data_hashed
    // );

    let unavailable_udcs = getUnavailableUDCs(
        active_p2_merrick_ids,
        well_data_hashed
    );


    

    //allow max 2 sensors to be selected
    const max_selected = 2;
    // if active_udc is a list
    if (active_udc && typeof active_udc === "object") {
        if (active_udc.length >= max_selected) {
            //disable all sensors that are not in active_udc
            let disabled_udcs_aux = all_sensors.filter(
                (udc) => active_udc.indexOf(udc) === -1
            );
            unavailable_udcs = [...new Set([...unavailable_udcs, ...disabled_udcs_aux])];
        }
    }

    // // OLD VERSION
    // // check if all active_p2_scada_ids are included in sensor_info_hashed
    // let data_is_ready = true;
    // active_p2_scada_ids.forEach((p2_scada_id) => {

    //     if (!sensor_info_hashed[p2_scada_id]) {
    //         data_is_ready = false;
    //     }
    // });

    // let udcs_with_active_p2_scada_ids = [];
    // active_p2_scada_ids.forEach((p2_scada_id) => {
    //     const sensor_info = sensor_info_hashed[p2_scada_id];
    //     if (sensor_info) {
    //         const udcs = sensor_info.sensor_ids;
    //         udcs.forEach((udc) => {
    //             if (udcs_with_active_p2_scada_ids.indexOf(udc) === -1) {
    //                 udcs_with_active_p2_scada_ids.push(udc);
    //             }
    //         });
    //     }
    // });

    // let disabled_udcs = sensor_list.filter(
    //     (udc) => udcs_with_active_p2_scada_ids.indexOf(udc) === -1
    // );

    const handleSensorChange = async (udc) => {
        // add to active_udc list if not already there or remove if already there
        let new_active_udc = active_udc ? [...active_udc] : [];
        if (new_active_udc.indexOf(udc) === -1) {
            new_active_udc.push(udc);
        } else {
            new_active_udc = new_active_udc.filter((item) => item !== udc);
        }
        await dispatch(updateCurrentUDC(new_active_udc));
    };

    const plot_loading = useSelector((state) => state.settings.plot_loading);

    useEffect(() => {
        //check if selected udc is disabled
        if (
            unavailable_udcs.indexOf(active_udc) !== -1 &&
            unavailable_udcs.length > 0
        ) {
            //if so, set udc to null
            dispatch(updateCurrentUDC(null));
            console.log("udc is disabled");
        }
    }, [unavailable_udcs, active_udc, dispatch]);

    return (
        <div>
            <h4>UDC Selection</h4>
            <hr />

            {/* radio selection of sensor */}
            <ul className="list-group sensors">
                {all_sensors.map((udc) => {
                    // let is_disabled = disabled_udcs.indexOf(udc) !== -1 || !data_is_ready || plot_loading;
                    let is_disabled =
                        unavailable_udcs.indexOf(udc) !== -1 || plot_loading;
                    let is_checked = active_udc
                        ? active_udc.indexOf(udc) !== -1
                        : false;
                    return (
                        <label
                            key={udc}
                            id={udc}
                            className="list-group-item small"
                        >
                            <input
                                className="form-check-input me-1"
                                // checked={active_udc === udc} //modified
                                checked={is_checked}
                                name="sensor"
                                type="checkbox"
                                disabled={is_disabled}
                                onChange={(e) => handleSensorChange(udc)}
                            />
                            <span className={is_disabled ? "text-muted" : ""}>
                                {udc}
                            </span>
                        </label>
                    );
                })}
            </ul>
        </div>
    );
}

export default SensorSelection;
