//redux actions

import axios from "axios";
import { getApiDefaults } from "./dataActions";
import { filterBySelectedGroup } from "../../Groups/GroupSelector";
import { updateFiltersFromWellsData } from "./utilities";

//try to avoid
export const setGroupsDict = (groupsDict) => {
    return {
        type: "SAVE_GROUPS",
        payload: groupsDict,
    };
};

//sample group
// group = {
//     id: 1,
//     name: "Group 1",
//     description: "This is a group",
//     owner: "manolosolalinde@gmail.com",
//     wells: [1, 2, 3, 4, 5],
// }

// REDUX ACTIONS

export const addGroupAction = (group) => {
    return {
        type: "ADD_GROUP",
        payload: group,
    };
};

export const updateGroupAction = (group) => {
    return {
        type: "UPDATE_GROUP",
        payload: group,
    };
};

export const deleteGroupAction = (group_id) => {
    return {
        type: "DELETE_GROUP",
        payload: group_id,
    };
};

export const setUserDataLoadingAction = (loading) => {
    // loading is a boolean
    return {
        type: "SET_USER_DATA_LOADING",
        payload: loading,
    };
};

// DB ACTIONS

export const addGroupDBAction = (group) => {
    return async (dispatch, getState) => {
        const { api_endpoint, headers } = await getApiDefaults();

        dispatch(setUserDataLoadingAction(true));

        let data = {
            ...group,
        };

        try {
            var response = await axios.post(
                api_endpoint + "/user_data/current_user/add_group",
                data,
                { headers: headers }
            );
            var fetched_group = response.data;
            await dispatch(addGroupAction(fetched_group));
            await dispatch(setCurrentGroupAndFiltersAction(fetched_group));
        } catch (e) {
            alert("Error adding group to database");
            console.error(e);
            return;
        } finally {
            dispatch(setUserDataLoadingAction(false));
        }

        
        return fetched_group;
    };
};

export const updateGroupDBAction = (group) => {
    return async (dispatch, getState) => {
        const { api_endpoint, headers } = await getApiDefaults();

        dispatch(setUserDataLoadingAction(true));

        let data = {
            ...group,
        };

        try {
            var response = await axios.post(
                api_endpoint + "/user_data/current_user/update_group",
                data,
                { headers: headers }
            );
            var fetched_group = response.data;
            await dispatch(setCurrentGroupAndFiltersAction(fetched_group));
        } catch (e) {
            alert("Error updating group in database");
            console.error(e);
            return;
        } finally {
            dispatch(setUserDataLoadingAction(false));
        }


        await dispatch(updateGroupAction(fetched_group));
        alert("Group updated")
    };
};

export const deleteGroupDBAction = (group_id) => {
    return async (dispatch, getState) => {
        const { api_endpoint, headers } = await getApiDefaults();

        dispatch(setUserDataLoadingAction(true));

        let data = {
            id: group_id,
        };

        try {
            var response = await axios.post(
                api_endpoint + "/user_data/current_user/delete_group",
                data,
                { headers: headers }
            );
            // check if response is 200
            await dispatch(setCurrentGroupAndFiltersAction(undefined));
            await dispatch(deleteGroupAction(group_id));
        } catch (e) {
            alert("Error deleting group from database");
            console.error(e);
            return;
        } finally {
            dispatch(setUserDataLoadingAction(false));
        }

        
    };
};




export const fetchGroupsDBAction = () => {
    return async (dispatch, getState) => {
        const { api_endpoint, headers } = await getApiDefaults();

        dispatch(setUserDataLoadingAction(true));

        try {
            var response = await axios.get(
                api_endpoint + "/user_data/current_user/get_groups",
                { headers: headers }
            );
            var fetched_groups = response.data;
        } catch (e) {
            console.error("Error fetching groups from database. Error:", e);
            return;
        } finally {
            dispatch(setUserDataLoadingAction(false));
        }

        let groups_dict = {};
        for (let i = 0; i < fetched_groups.length; i++) {
            groups_dict[fetched_groups[i].id] = fetched_groups[i];
        }

        await dispatch(setGroupsDict(groups_dict));
    };
}

export const setCurrentGroupAction = (group) => {
    return {
        type: "SET_CURRENT_GROUP",
        payload: group,
    };
}

export const setCurrentGroupAndFiltersAction = (group) => {
    return async (dispatch, getState) => {

        // PART 1: UPDATE GROUP

        await dispatch(setCurrentGroupAction(group));


        // PART 2: UPDATE FILTERS

        const { raw_data, settings } = getState();
        const { well_data } = raw_data;
        const { filters } = settings;

        let filtered_well_data = well_data;

        if (group !== undefined) {
            filtered_well_data = filterBySelectedGroup(well_data, group);
        }

        // clear options and active_options from filters (unless filter.id contains "data_available"")
        const empty_filters = filters.map((filter) => {
            if ((filter.id).includes("data_available")) {
                return filter;
            } else{
                return {
                    ...filter,
                    options: [],
                    active_options: [],
                };
            }
        });
        
        // const base_filters = JSON.parse(JSON.stringify(settingsInitState.filters));
        // let new_filters = updateFiltersFromWellsData(filtered_well_data, base_filters);
        let new_filters = updateFiltersFromWellsData(filtered_well_data, empty_filters);

        await dispatch({
            type: "UPDATE_FILTERS",
            payload: new_filters,
        });
    };
};


