import { setUserPlotSettingsAction } from "bkv-components/store_lib/actions/settingsActions";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const Div = styled.div`
    max-width: 300px;

    .input-group-prepend {
        width: 110px;
    }

    .smoothing-label {
        width: 50px;
        padding: 0px;
        padding-left: 10px;
        /* justify-content: "center"; */
        text-align: center;
        display: flex;
        /* align-items: "center"; */
    }
`;

const FormSwitchSetting = (props) => {
    const { title, value, label, handleChange, disabled, className } = props;
    if (disabled) {
        return null;
    }

    return (
        <div className={className}>
            <div className="form-check form-switch">
                <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault"
                >
                    {title}
                </label>
                <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    value={value}
                    checked={value}
                    onChange={(e) => handleChange(e.target.checked)}
                />
            </div>
        </div>
    );
};

function UserPlotSettings(props) {
    const [inner_plot_height, setInnerPlotHeight] = React.useState(0);
    const [inner_plot_separation, setInnerPlotSeparation] = React.useState(0);
    const [inner_smoothing, setInnerSmoothing] = React.useState(0);

    // const user_plot_settings = useSelector(
    //     (state) => state.settings.user_plot_settings
    // );

    const { user_plot_settings } = props;
    let { disabled_settings } = props;

    if (!disabled_settings || disabled_settings.constructor !== Array) {
        disabled_settings = [];
    }

    let enabled_plot_height = true;
    let enabled_plot_separation = true;
    //check if list
    if (
        disabled_settings &&
        typeof disabled_settings === "object" &&
        disabled_settings.constructor === Array
    ) {
        disabled_settings.map((setting) => {
            if (setting === "plot_height") {
                enabled_plot_height = false;
            }
            if (setting === "plot_separation") {
                enabled_plot_separation = false;
            }
            return null;
        });
    }
    // console.log(disabled_settings)

    let {
        merged_pressure_plots,
        log_INSFLG0,
        hide_comments,
        plot_height,
        plot_separation,
        y_axis_zoom_and_pan,
        connectgaps,
        smoothing
    } = user_plot_settings;

    if (!merged_pressure_plots) {
        merged_pressure_plots = false;
    }
    if (!log_INSFLG0) {
        log_INSFLG0 = false;
    }
    if (!hide_comments) {
        hide_comments = false;
    }
    if (!connectgaps) {
        connectgaps = false;
    }

    if  (!smoothing) {
        smoothing = 0;
    }

    const dispatch = useDispatch();
    const setUserPlotSettings = (e) => dispatch(setUserPlotSettingsAction(e));

    const setPlotHeight = (value) => {
        value = parseInt(value);

        if (value < 500) {
            value = 500;
        }
        if (value > 2000) {
            value = 2000;
        }

        setInnerPlotHeight(value);
        setUserPlotSettings({
            ...user_plot_settings,
            plot_height: value,
        });
    };

    const setPlotSeparation = (value) => {
        value = parseInt(value);
        if (value < 0) {
            value = 0;
        }
        if (value > 200) {
            value = 200;
        }

        setInnerPlotSeparation(value);
        setUserPlotSettings({
            ...user_plot_settings,
            plot_separation: value,
        });
    };

    const setSmoothing = (e) => {
        console.log('new smoothing value: ', inner_smoothing)
        let value = inner_smoothing;
        value = parseInt(value);
        if (value < 0) {
            value = 0;
        }
        setUserPlotSettings({
            ...user_plot_settings,
            smoothing: value,
        });
    };



    useEffect(() => {
        setInnerPlotHeight(plot_height);
        setInnerPlotSeparation(plot_separation);
        setInnerSmoothing(smoothing);
    }, [plot_height, plot_separation]);

    return (
        <Div>
            <h4>Plot Settings</h4>
            <hr />

            {/* {enabled_merged_pressure_plots && (
                <div className="form-check form-switch">
                    <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckDefault"
                    >
                        Merged Pressure Plots
                    </label>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        value={merged_pressure_plots}
                        checked={merged_pressure_plots}
                        onChange={(e) =>
                            setUserPlotSettings({
                                ...user_plot_settings,
                                merged_pressure_plots: e.target.checked,
                            })
                        }
                    />
                </div>
            )} */}
            <FormSwitchSetting
                title="Merged Plots"
                value={merged_pressure_plots}
                disabled={disabled_settings.includes("merged_pressure_plots")}
                handleChange={(value) =>
                    setUserPlotSettings({
                        ...user_plot_settings,
                        merged_pressure_plots: value,
                    })
                }
            />

            <FormSwitchSetting
                title="Logarithmic Gas Rate"
                value={log_INSFLG0}
                disabled={disabled_settings.includes("log_INSFLG0")}
                handleChange={(value) =>
                    setUserPlotSettings({
                        ...user_plot_settings,
                        log_INSFLG0: value,
                    })
                }
            />

            <FormSwitchSetting
                title="Y Axis Zoom & Pan"
                value={y_axis_zoom_and_pan}
                disabled={disabled_settings.includes("y_axis_zoom_and_pan")}
                handleChange={(value) =>
                    setUserPlotSettings({
                        ...user_plot_settings,
                        y_axis_zoom_and_pan: value,
                    })
                }
            />
            <FormSwitchSetting
                title="Connect Gaps"
                value={connectgaps}
                disabled={disabled_settings.includes("connectgaps")}
                handleChange={(value) =>
                    setUserPlotSettings({
                        ...user_plot_settings,
                        connectgaps: value,
                    })
                }
            />

            <FormSwitchSetting
                title="Hide Comments"
                value={hide_comments}
                disabled={disabled_settings.includes("hide_comments")}
                handleChange={(value) =>
                    setUserPlotSettings({
                        ...user_plot_settings,
                        hide_comments: value,
                    })
                }
            />
            <div className="mb-3" />

            {enabled_plot_height && (
                <div className="input-group mb-3 has-validation">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon3">
                            Plot height
                        </span>
                    </div>
                    <input
                        type="number"
                        className="form-control"
                        id="plot_height"
                        step="50"
                        style={
                            inner_plot_height !== plot_height
                                ? { color: "gray" }
                                : {}
                        }
                        aria-describedby="basic-addon3"
                        value={inner_plot_height}
                        //convert to number
                        onChange={(e) =>
                            setInnerPlotHeight(parseInt(e.target.value))
                        }
                    />
                    <div className="input-group-append">
                        <button
                            className="btn btn-outline-secondary"
                            type="button"
                            id="button-addon2"
                            onClick={() => setPlotHeight(inner_plot_height)}
                        >
                            <i className="fa fa-check" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div className="invalid-feedback" htmlFor="plot_height">
                        Range must be between 700 to 1400
                    </div>
                </div>
            )}
            {enabled_plot_separation && (
                <div className="input-group mb-3 has-validation">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon3">
                            Separation
                        </span>
                    </div>
                    <input
                        type="number"
                        className="form-control"
                        id="plot_separation"
                        step="5"
                        style={
                            inner_plot_separation !== plot_separation
                                ? { color: "gray" }
                                : {}
                        }
                        aria-describedby="basic-addon3"
                        value={inner_plot_separation}
                        onChange={(e) =>
                            setInnerPlotSeparation(parseInt(e.target.value))
                        }
                    />
                    <div className="input-group-append">
                        <button
                            className="btn btn-outline-secondary"
                            type="button"
                            id="button-addon2"
                            onClick={() =>
                                setPlotSeparation(inner_plot_separation)
                            }
                        >
                            <i className="fa fa-check" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div className="invalid-feedback" htmlFor="plot_height">
                        Range must be between 0 to 200
                    </div>
                </div>
            )}

            <div className="smoothing mb-3 d-flex justify-content-between">
                <div className="d-flex">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon3">
                        Smoothing
                    </span>
                </div>
                <input
                        type="number"
                        className="form-control smoothing-label"
                        value={inner_smoothing}
                        disabled={true}
                    />
                    </div>
                <div className="d-flex align-items-center">
                <input
                    type="range"
                    className="form-range"
                    style={{padding: "10px 10px"}}
                    min="0"
                    max="50"
                    step="1"
                    value={inner_smoothing}
                    id="smoothing"
                    onChange={(e) => setInnerSmoothing(e.target.value)}
                    onMouseUp={(e) => setSmoothing(e)}
                ></input>
                </div>
            </div>
        </Div>
    );
}

export default UserPlotSettings;
