// import "./styles/index.css";
// import "./styles/plots.css";
// import "./styles/checkboxmenu.css";
// import "./styles/index_2.css"

import React, { useEffect, useState } from "react";

import Plots from "./components/Plots";
import styled from "styled-components";
import { Provider, useDispatch, useSelector } from "react-redux";

import { createStore, applyMiddleware } from "redux";
import rootReducer from "./store/reducers/rootReducer";
import thunk from "redux-thunk";
import { downloadWellTable } from "bkv-components/store_lib/actions/dataActions";
import Header from "bkv-components/Layout/Header";
import Layout from "bkv-components/Layout/Layout";
import InnerAppLayout from "bkv-components/Layout/InnerAppLayout";
import { LeftPane } from "./components/left_pane/LeftPane";
import { fetchGroupsDBAction } from "bkv-components/store_lib/actions/userDataActions";
// const store = createStore(rootReducer);
const store = createStore(rootReducer, applyMiddleware(thunk));

const MainBody = styled.div`
    /* min-height 100vh - height 105.062px */

    min-height: calc(100vh - 105.062px);
`;

const SurveillanceDashboard = (props) => {
    const dispatch = useDispatch();

    const { well_data, well_data_error } = useSelector(
        (state) => state.raw_data
    );

    const user_plot_settings = useSelector(
        (state) => state.settings.user_plot_settings
    );

    const [show_settings, setShowSettings] = useState(true);

    useEffect(() => {
        async function downloadWellTableHelper() {
            dispatch(downloadWellTable());
            dispatch(fetchGroupsDBAction());
        }
        if (Object.keys(well_data).length === 0) {
            //wells table is only loaded once, and only the first time this component is mounted
            //switching to portal or other apps will not reload the wells table
            downloadWellTableHelper();
            console.log('downloadWellTableHelper() called')
        }
    }, []);

    const well_loaded = Object.keys(well_data).length > 0;

    const plot_mounted = useSelector((state) => state.settings.plot_mounted);

    const clearSettings = async () => {
        await dispatch({
            type: "CLEAR_SENSOR_DATA",
        });
        await dispatch({
            type: "CLEAR_SETTINGS",
        });
        await dispatch({
            type: "CLEAR_COMMENTS",
        });
    };


    return (
        <Layout>
            <Header title="Single Well Surveillance" />

            {/* {!well_loaded ? (
                !well_data_error ? (
                    <div
                        className="maincontainer col-12 d-flex justify-content-center align-items-center"
                        style={{ flex: "1" }}
                    >
                        <div>
                            <i className="fas fa-cog fa-spin"></i>
                            <span> Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div
                        className="maincontainer col-12 d-flex justify-content-center align-items-center"
                        style={{ flex: "1" }}
                    >
                        <div>

                            <h4>
                                <i className="fas fa-exclamation-triangle"></i>{" "}
                                Error connecting to server{" "}
                            </h4>
                        </div>
                    </div>
                )
            ) : (
                <MainBody className="d-flex flex-wrap flex-md-nowrap justify-content-center">
                    <div
                        className="maincontainer col-12 col-md-4 col-lg-6 col-xl-5 col-xxl-4"
                        style={{
                            minHeight: "100%",
                            display:
                                show_settings || !plot_mounted
                                    ? "block"
                                    : "none",
                        }}
                    >
                        <div className="p-3">
                            <div className="justify-content-between align-items-center d-flex">
                                <h2>Settings</h2>
                                {plot_mounted && (
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary btn-sm"
                                        onClick={() => setShowSettings(false)}
                                    >
                                        Hide
                                    </button>
                                )}
                            </div>
                            <hr />
                        </div>

                        {pane === "A" ? (
                            <LeftPaneFlat
                                plot_mounted={plot_mounted}
                                user_plot_settings={user_plot_settings}
                                onSwitchPane={onSwitchPane}
                            />
                        ) : (
                            <LeftPaneTabs
                                plot_mounted={plot_mounted}
                                user_plot_settings={user_plot_settings}
                                onSwitchPane={onSwitchPane}
                            />
                        )}
                    </div>

                    <div
                        className={
                            show_settings
                                ? "p-0 col-12 col-md-8 col-lg-6 col-xl-7 col-xxl-8"
                                : "p-0 col-12"
                        }
                    >
                        <RightPane
                            show_settings={show_settings}
                            setShowSettings={setShowSettings}
                        />

                    </div> */}
                    <InnerAppLayout
                        leftPane={
                            <LeftPane/>
                        }
                        rightPane={
                            <RightPane
                                show_settings={show_settings}
                                setShowSettings={setShowSettings}
                            />
                        }
                        wells_loaded={well_loaded}
                        well_data_error={well_data_error}
                        plot_mounted={plot_mounted}
                        show_settings={show_settings}
                        setShowSettings={setShowSettings}
                        clearSettings={clearSettings}
                    />
        </Layout>
    );
};

const App = (props) => {
    return (
        <Provider store={store}>
            <SurveillanceDashboard />
        </Provider>
    );
};

export default App;

const RightPane = ({show_settings,setShowSettings}) => 
    <div className=" maincontainer p-3 h-100">
                            <Plots
                                title="Sensor Data"
                                setShowSettings={setShowSettings}
                                show_settings={show_settings}
                            />
                        </div>