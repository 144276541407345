import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    createCommentAction,
    updateCommentsAction,
} from "bkv-components/store_lib/actions/commentActions_db";
import { setPlotMountedAction } from "bkv-components/store_lib/actions/settingsActions";
import {
    getP2ScadaIds,
} from "bkv-components/General/data_transformation";
import { useAccount, useMsal } from "@azure/msal-react";
import { hashedActiveIds } from "../daily_utils";
import { PlotHeader } from "bkv-components/Layout/PlotHeader";
import { requestErrorToText } from "bkv-components/General/DisplayRequestError";
import SensorPlotV2 from "bkv-components/SensorPlot/SensorPlotV2";
import { getColorFromUDC } from "bkv-components/SensorPlot/Components/utils";
import { getUDCInfo } from "bkv-components/General/utils";
import PlotResolution from "bkv-components/General/PlotResolution";

function DailyMultiWellPlot(props) {
    const { show_settings, setShowSettings } = props;

    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const dispatch = useDispatch();

    const active_p2_merrick_ids = useSelector(
        (state) => state.settings.active_p2_merrick_ids
    );

    const well_data_hashed = useSelector(
        (state) => state.raw_data.well_data_hashed
    );
    const comments_dict = useSelector((state) => state.raw_data.comments_dict);
    const comments_dict_external = useSelector((state) => state.raw_data.comments_dict_external);

    const user_plot_settings = useSelector(
        (state) => state.settings.user_plot_settings
    );
    let user_plot_settings_aux = { ...user_plot_settings };
    user_plot_settings_aux.all_log_scale = true;

    const sensor_multiwell_data_info = useSelector(
        (state) => state.raw_data.sensor_multiwell_data
    );

    const http_request_error = useSelector(
        (state) => state.raw_data.http_request_error
    );


    useEffect(() => {
        dispatch(setPlotMountedAction(false));
    }, [dispatch]);

    //UDC SELECTION
    const udc_selection = useSelector((state) => state.daily_app_settings.udc_selection);

    let status = "ready";



    if (!active_p2_merrick_ids || active_p2_merrick_ids.length === 0) {
        status = "Please select between 1 and 10 Wells";
    } else if (http_request_error) {
        status = requestErrorToText(http_request_error);
    } else if (active_p2_merrick_ids.length > 0) {
        status = "ready";
        try {
            var active_p2_scada_ids = getP2ScadaIds(
                active_p2_merrick_ids,
                well_data_hashed
            );
            // check if all there is sensor_multiwell_data
            if (!sensor_multiwell_data_info) {
                status = "Loading sensor data...";
                throw new Error("Loading sensor data...");
            }
            // var data =
            // remove merrick_id from sensor_data
            var data = {};
            Object.keys(sensor_multiwell_data_info.data).forEach((key) => {
                let condition1 = udc_selection.length > 0 ? udc_selection.includes(key) : true;
                let condition2 = key === 'record_time'
                let condition3 = key !== 'merrick_id'
                if ((condition1 || condition2) && condition3) {
                    data[key] = sensor_multiwell_data_info.data[key];
                }
            });

            // remove unused udcs from comments dict
            var comments_dict_filtered = {};
            Object.keys(comments_dict).forEach((key) => {
                let comment = comments_dict[key];
                let condition1 = udc_selection.length > 0 ? udc_selection.includes(comment.udc) : true;
                if (condition1) {
                    comments_dict_filtered[key] = comment;
                }
            });
                    

            var start_time = sensor_multiwell_data_info.start_date;
            var end_time = sensor_multiwell_data_info.end_date;
            // convert from string ISO 8601 to Date object
            start_time = new Date(start_time);
            end_time = new Date(end_time);

            
            
        } catch (e) {
            status = e.message;
        }
    } else {
        status = "Error";
    }


    const onUpdateResolution = async (start_time, end_time) => {
        // await dispatch(setPlotLoadingAction(true));
        // await dispatch(
        //     downloadSensorMultiwellData(
        //         active_p2_scada_ids,
        //         active_udc,
        //         start_time,
        //         end_time
        //     )
        // );
        // await dispatch(setPlotLoadingAction(false));
    };

    const onCreateComment = (comment) => {
        dispatch(createCommentAction(comment));
    };

    const onUpdateComments = (comment_list) => {
        dispatch(updateCommentsAction(comment_list));
    };

    const onPlotMountedChange = (plot_mounted) => {
        dispatch(setPlotMountedAction(plot_mounted));
    };


    if (status === "ready") {
        var p2_scada_ids_str = hashedActiveIds(active_p2_scada_ids);

    // build trace_info and axis_info -------------- for single well application  -------
    var trace_info = {};
    var axis_info = {};
    let udcs = Object.keys(data).filter((key) => key !== "record_time");
    let gas_rate_present = udcs.includes("INSFLG0");
    // let merged = false;
    var merged = user_plot_settings.merged_pressure_plots;

    const getAxisName = (unit) => {
        switch (unit) {
            case "psi":
                return "PRESSURE";
            case "mcf/d":
                return "GAS RATE";
            case "bbl/d":
                return "FLOW RATE";
            default:
               return "OTHER";
        }
    }
    
    for (let udc of udcs) {
        let unit = getUDCInfo(udc).unit;
        trace_info[udc] = {
            well_id: p2_scada_ids_str,
            sensor_id: udc,
            label: udc,
            // yaxis_name: merged ? (udc!=="INSFLG0" ? "PRESSURE" : "INSFLG0") : udc,
            yaxis_name: merged ? "ALL" : getAxisName(unit),
            color: getColorFromUDC(udc),
            unit: unit,
        };
    }
    
    if (merged){
        axis_info["ALL"] = {
            domain_height: 1,
            unit: '',
        };

        // if (gas_rate_present){
        //     axis_info["INSFLG0"] = {
        //         domain_height: 0.3,
        //         unit: getUDCInfo("INSFLG0").unit,
        //     };
        //     if(udcs.length>1){
        //         axis_info["PRESSURE"] = {
        //             domain_height: 0.7,
        //             unit: 'psi',
        //         };
        //     } else {
        //         axis_info["INSFLG0"]["domain_height"] = 1;
        //     }
        // } else {
        //     axis_info["PRESSURE"] = {
        //         domain_height: 1,
        //         unit: 'psi',
        //     };
        // }
    } else{
        // let sorted_udc = ["INSFLG0","CMLINE0","PTUBE0","PCASE0"];
        // let sorted_existing_udc = sorted_udc.filter((udc) => udcs.includes(udc))
        // for (let udc of sorted_existing_udc){
        // for(let udc of udcs){
        //     axis_info[udc] = {
        //         unit: getUDCInfo(udc).unit,
        //         domain_height: 1/udcs.length,
        //         color: getColorFromUDC(udc),
        //     };
        // }
    }
    


    }

    return (
        <>

            <PlotHeader 
                title="Daily Production Plot"
                show_settings={show_settings}
                setShowSettings={setShowSettings}
            />
            <hr />
            {status !== "ready" && <div>{status}</div>}
            {status === "ready" && (
                <div>
                    {/* <SensorPlot
                        start_time={start_time}
                        end_time={end_time}
                        sensor_data={data}
                        show_settings={show_settings}
                        comments_dict={comments_dict_filtered}
                        // onUpdateResolution={onUpdateResolution}
                        onCreateComment={onCreateComment}
                        onUpdateComments={onUpdateComments}
                        onPlotMountedChange={onPlotMountedChange}
                        user_plot_settings={user_plot_settings_aux}
                        mode="fixed_well"
                        base_unit=""
                        p2_scada_id={p2_scada_ids_str}
                        account={account}
                        application={"daily"}
                    /> */}
                    <PlotResolution
                        base_resolution={sensor_multiwell_data_info.base_resolution}
                        resolution={sensor_multiwell_data_info.resolution==='n/a'?sensor_multiwell_data_info.base_resolution:sensor_multiwell_data_info.resolution}
                        num_rows={sensor_multiwell_data_info.num_rows}
                        />
                    <SensorPlotV2
                        sensor_data={data}
                        axis_info={ merged ? axis_info:undefined}
                        trace_info={trace_info}
                        start_time={start_time}
                        end_time={end_time}
                        show_settings={show_settings}
                        comments_dict={comments_dict_filtered}
                        comments_dict_external={comments_dict_external}
                        // onUpdateResolution={onUpdateResolution}
                        onCreateComment={onCreateComment}
                        onUpdateComments={onUpdateComments}
                        onPlotMountedChange={onPlotMountedChange}
                        user_plot_settings={user_plot_settings_aux}
                        account={account}
                        application={"daily"}
                    />


                </div>
            )}
        </>
    );
}

export default DailyMultiWellPlot;
