import { getP2ScadaIds } from "bkv-components/General/data_transformation";
import { DisplayRequestError, requestErrorToText } from "bkv-components/General/DisplayRequestError";
import { PlotHeader } from "bkv-components/Layout/PlotHeader";
import { setPlotMountedAction } from "bkv-components/store_lib/actions/settingsActions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MultiTracePlot from "./MultiTracePlot";
import AllEngineeringPlotsV1 from "./v1/AllEngineeringPlotsV1";
import { AllEngineeringPlotsV2 } from "./v2/AllEngineeringPlotsV2";
import { getPlotlyColor } from "bkv-components/General/utils";

const EngineeringPlotsV2 = (props) => {
    const { show_settings, setShowSettings, plot_selection } = props;

    const dispatch = useDispatch();

    const active_p2_merrick_ids = useSelector(
        (state) => state.settings.active_p2_merrick_ids
    );

    const engineering_data = useSelector(
        (state) => state.daily_app_data.engineering_data
    );

    const facility_sub_selection = useSelector(
        (state) => state.daily_app_settings.facility_sub_selection
    );

    const http_request_error = useSelector(
        (state) => state.raw_data.http_request_error
    );

    useEffect(() => {
        dispatch(setPlotMountedAction(false));
    }, [dispatch]);

    let status = "ready";

    if (!active_p2_merrick_ids || active_p2_merrick_ids.length === 0) {
        status = "Please select between 1 and 10 Wells";
    } else if (http_request_error) {
        status = requestErrorToText(http_request_error);
    } else if (active_p2_merrick_ids.length > 0) {
        status = "ready";
        try {
            if (!engineering_data) {
                status = "Loading engineering data...";
                throw new Error("Loading engineering data...");
            }

            var plot_colors = {};
            active_p2_merrick_ids.forEach((x, index) => {
                plot_colors[x] = getPlotlyColor(index);
            });

            // var data = engineering_plots;
            var data = {};
            let facility_sub_selection_str = facility_sub_selection.map((x) =>
                x.toString()
            );

            console.log("engineering_data", engineering_data);

            Object.keys(engineering_data).forEach((key) => {
                data[key] = {};
                Object.keys(engineering_data[key]).forEach((sub_key) => {
                    let condition1 =
                        facility_sub_selection_str.length > 0
                            ? facility_sub_selection_str.includes(sub_key)
                            : true;
                    let condition2 = sub_key === "all";
                    if (condition1 || condition2) {
                        data[key][sub_key] = engineering_data[key][sub_key];
                    }
                });
            });
            console.log(data);
        } catch (e) {
            status = e.message;
        }
    } else {
        status = "Error";
        throw new Error("Error in EngineeringPlots");
    }



    const onPlotMountedChange = (plot_mounted) => {
        dispatch(setPlotMountedAction(plot_mounted));
    };

    return (
        <>
            <PlotHeader
                title="Engineering Plots"
                show_settings={show_settings}
                setShowSettings={setShowSettings}
            />
            <hr />
            {status !== "ready" && <div>{status}</div>}
            {status === "ready" && plot_selection === "engineering_data" && (
                <AllEngineeringPlotsV2 data={data} plot_colors={plot_colors} />
            )}
            {status === "ready" && plot_selection === "engineering_plots" && (
                <AllEngineeringPlotsV1 data={data} plot_colors={plot_colors} />
            )}
        </>
    );
};

export default EngineeringPlotsV2;
