const initState = {
  active_p2_merrick_id: null,
  active_p2_merrick_ids: [],//null,//[]
  active_udc: null,
  p2_facility_options: [],
  filters: [
    { name: 'P2 Foreman', id: 'p2_foreman', options: [], active_options: [] },
    { name: 'P2 Pumper', id: 'p2_pumper', options: [], active_options: [] },
    { name: 'P2 Engineer', id: 'p2_engineer', options: [], active_options: [] },
    { name: 'P2 Supervisor', id: 'p2_supervisor', options: [], active_options: [] },
    { name: 'P2 Route', id: 'p2_route', options: [], active_options: [] },
    // { name: 'Aries api', id: 'aries_api', options: [], active_options: [] },
    { name: 'Aries RSV Cat', id: 'rsv_cat', options: [], active_options: [] },
    { name: 'Data Available', id: 'data_available', options: [], active_options: [true] }
  ],
  plot_mounted: false,
  plot_loading: false,
  user_plot_settings: {
    all_log_scale: false,
    log_INSFLG0: true,
    merged_pressure_plots: true,
    hide_comments: false,
    plot_height: 700,
    plot_separation: 0,
    y_axis_zoom_and_pan: false,
    smoothing: 0
  }
}

//creates a deep copy
export const settingsInitState = JSON.parse(JSON.stringify(initState));

const MAX_SELECTED_FACILITIES = 5;



const settingsReducer = (state = {...initState}, action,init_state = {...initState}) => {
  switch (action.type) {
    case 'UPDATE_SETTINGS':
      return { ...action.payload };
    case 'UPDATE_CURRENT_FACILITY':
      return { ...state, active_p2_merrick_id: action.payload.facility_id };
    case 'UPDATE_CURRENT_UDC':
      // if (action.payload.udc === []) {
      if (typeof action.payload.udc === 'object' && action.payload.udc.length === 0) {
        return { ...state, active_udc: null };
      }
      return { ...state, active_udc: action.payload.udc };
    case 'UPDATE_CURRENT_FACILITIES':
      return { ...state, active_p2_merrick_ids: action.payload.facility_ids };
    case 'UPDATE_FILTERS':
      return { ...state, filters: action.payload };
    case 'UPDATE_FILTER_STATE':
      // replace filter with the same id
      let id = action.payload.filter.id;
      let new_filters = state.filters.map(filter => {
        if (filter.id === id) {
          return action.payload.filter;
        }
        return filter;
      }
      );
      console.log('filter state updated');
      return { ...state, filters: new_filters };
    case 'CLEAR_FILTER_STATE':
      let new_filters2 = state.filters.map(filter => {
        return { ...filter, active_options: [] };
      }
      );
      return { ...state, filters: new_filters2 };
    case 'UPDATE_SETTINGS_ERROR':
      console.log('settings error');
      return state;
    case 'UPDATE_PLOT_MOUNTED':
      return { ...state, plot_mounted: action.payload };
      case 'UPDATE_PLOT_LOADING':
      return { ...state, plot_loading: action.payload };
    case 'UPDATE_USER_PLOT_SETTINGS':
      return { ...state, user_plot_settings: action.payload };
    case 'CLEAR_SETTINGS':
      return init_state;
    default:
      return state;
  }
};
export default settingsReducer;
