import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RefreshContext } from "../General/utils";

const Div = styled.div`
    .spinner-border {
        color: unset !important;
    }
`;

export const PlotHeader = (props) => {
    const {
        title = "Sensor Data",
        show_settings,
        setShowSettings,
    } = props;
    const plot_loading = useSelector((state) => state.settings.plot_loading);
    const refreshPlot = React.useContext(RefreshContext);
    return (
        <Div className="justify-content-between align-items-center d-flex">
            <h2>{title}</h2>
            {/* add spiner */}

            {/* icon refresh button */}
            <div>
                {plot_loading && (
                    <h2>
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        >
                            <span
                                style={{ backgroundColor: "#094e8f" }}
                                className="visually-hidden"
                            >
                                Loading...
                            </span>
                        </div>
                    </h2>
                )}
                {!plot_loading && (
                    <button
                        className="btn btn-outline-primary btn-sm me-2"
                        type="button"
                        onClick={refreshPlot}
                    >
                        <i
                            className="fa-solid fa-arrows-rotate "
                            //   onClick={updateResolution}
                            title="Refresh Plot"
                            aria-label="Refresh Plot"
                        />
                    </button>
                )}

                {!show_settings && (
                    <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => setShowSettings(true)}
                    >
                        Show Settings
                    </button>
                )}
            </div>
        </Div>
    );
};
