import axios from "axios";
import { getApiDefaults } from "./dataActions";


export const updateComments = async (comments) => {
    const { api_endpoint, headers } = await getApiDefaults();

    // convert dates for all comments to miliseconds
    let modif_comments = [...comments];
    for (let i = 0; i < modif_comments.length; i++) {
        modif_comments[i].x = new Date(modif_comments[i].x).getTime();
    }

    var response = await axios.post(
        api_endpoint + "/comments/set_comments",
        {
            comments: modif_comments,
        },
        { headers: headers }
    );
    let fetched_comments = response.data;

    return fetched_comments;
};

export const commentDbErrorAction = (e) => {
    return async (dispatch, getState) => {
        let status = e.response ? e.response.status : "";
        status = status.toString();
        if (status === ""){
            if (window.navigator.onLine) {
                status = "ServerOffline";
            } else {
                status = "NoInternet";
            }
        }
        return await dispatch({
            type: "COMMENT_DB_ERROR",
            payload: status,
        });
    };
};


export const setSingleComment = (comment) => {
    // for DEBUG only
    return {
        type: "SET_SINGLE_COMMENT",
        payload: comment,
    };
};

//DB ACTIONS

// export const fetchAllCommentsDBAction = (p2_scada_id) => {
//     return async (dispatch, getState) => {
//         return dispatch(fetchCommentsDBAction(p2_scada_id, null, null));
//     }
// }


export const fetchCommentsDBAction = (
    p2_scada_id,
    start_time,
    end_time
) => {
    return async (dispatch) => {
        const { api_endpoint, headers } = await getApiDefaults();

        const parameters = {
            max_rows: 6000 * 20,
        };

        if (start_time && end_time) {
            //convert start_time to timestamp
            parameters.start_time = start_time.getTime();
            parameters.end_time = end_time.getTime();
        }

        try {
            var response = await axios.get(
                api_endpoint + "/comments/data/" + p2_scada_id,
                { headers: headers, params: parameters }
            );
        } catch (e) {
            dispatch(commentDbErrorAction(e));
        }
        let data = response.data;


        // convert list into dictionary with key = id
        let comments_dict = {};
        for (let i = 0; i < data.length; i++) {
            comments_dict[data[i].id] = data[i];
        }

        await dispatch({
            type: "SET_COMMENTS",
            payload: comments_dict,
        });
    };
};

export const fetchExternalCommentsAction = (
    active_p2_merrick_ids,
    start_time,
    end_time
    ) => {
    return async (dispatch) => {
        const { api_endpoint, headers } = await getApiDefaults();

        let parameters = {}

        if (start_time && end_time) {
            //convert start_time to timestamp
            parameters.start_time = start_time.getTime();
            parameters.end_time = end_time.getTime();
        }

        try {
            var response = await axios.post(
                api_endpoint + "/comments/external_multi/",
                {
                    p2_merrick_id_list: active_p2_merrick_ids,
                },
                { headers: headers, params: parameters }
            );
        } catch (e) {
            await dispatch({
                type: "SET_EXTERNAL_COMMENTS",
                payload: {},
            });
            alert("Error while loading external comments");
            console.error(e);
            // dispatch({
            //     type: "COMMENT_EXTERNAL_ERROR",
            //     payload: e.response.status,
            // });
        }
        let data = response.data;

        // convert list into dictionary with key = id
        let comments_dict_external = {};
        for (let i = 0; i < data.length; i++) {
            comments_dict_external[i] = data[i];
        }

        await dispatch({
            type: "SET_EXTERNAL_COMMENTS",
            payload: comments_dict_external,
        });
    };
};






export const fetchMultiWellCommentsDBAction = (p2_scada_id_list, sensor_id, start_time, end_time) => {
    return async (dispatch) => {
        const { api_endpoint, headers } = await getApiDefaults();

        const parameters = {
            max_rows: 6000 * 20,
        };

        if (start_time && end_time) {
            //convert start_time to timestamp
            parameters.start_time = start_time.getTime();
            parameters.end_time = end_time.getTime();
        }

        try {
            var response = await axios.post(
                api_endpoint + "/comments/multiwell_data",
                {
                    p2_scada_id_list: p2_scada_id_list,
                    sensor_id: sensor_id
                },
                { headers: headers, params: parameters }
            );
        } catch (e) {
            dispatch(commentDbErrorAction(e));
        }
        let data = response.data;

        // convert list into dictionary with key = id
        let comments_dict = {};
        for (let i = 0; i < data.length; i++) {
            comments_dict[data[i].id] = data[i];
        }

        await dispatch({
            type: "SET_COMMENTS",
            payload: comments_dict,
        });
    };
};


export const setAllCommentsAction = (comments_dict) => {
    /*
    for DEBUG only
    Avoid using this function. May lead to unrefreshed states
    */
    return {
        type: "SET_COMMENTS",
        payload: comments_dict,
    };
};

export const createCommentAction = (comment) => {
    return async (dispatch, getState) => {
        try {
            var fetched_comments = await updateComments([comment]);
        } catch (e) {
            dispatch(commentDbErrorAction(e));
        }

        let response_comment = fetched_comments[0];

        // DEBUG implementation
        dispatch(setSingleComment(response_comment));
    };
};

export const updateCommentsAction = (comments_list) => {
    return async (dispatch, getState) => {
        try {
            var fetched_comments = await updateComments(comments_list);
        } catch (e) {
            dispatch(commentDbErrorAction(e));
        }

        // DEBUG implementation
        for (let i = 0; i < fetched_comments.length; i++) {
            dispatch(setSingleComment(fetched_comments[i]));
        }
    };
};

//NOTE: deleteCommentAction done with updateCommentsAction

function compareDictionaries(p0, p1) {
    if (p0 == p1) {
        return true;
    } else {
        const matched = {};
        for (var k0 in p0) {
            matched[k0] = k0;
            if (p0[k0] != p1[k0]) {
                return false;
            }
        }

        for (var k1 in p1) {
            if (matched[k1]) {
                continue;
            } else {
                if (p1[k1] != p0[k1]) {
                    return false;
                }
            }
        }

        return true;
    }
}
