import { useAccount, useMsal } from "@azure/msal-react";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Header from "./components/Header";
import Layout from "./components/Layout";
import ProjectCard from "./components/ProjectCard";

// const image_url_global = 'images/sd.png';
// import project_thumbnail from './images/sd.png';

const PortalContainer = styled("div")`

/* background-color: #f9f9f9; */

a.hover {
    color: black !important;
}

`

const Section = styled("div")`
    margin-bottom: 10em;
    display: flex;
    flex-direction: column;
    width: 50%;


    &:last-of-type {
        margin-bottom: 0;
    }
`;

const WorkTitle = styled("h1")`
    margin-bottom: 1em;
    text-align: center !important;

    font-family: "Ubuntu", sans-serif;
    color: #094e8f;
    font-size: 50px;
    /* font-weight: bold; */
    padding: 10px;
    text-align: center;


`

const projects = [
    {
        title: 'Single Well Surveillance Dashboard',
        description: 'Well data visualization on time series for individual wells',
        redirect_link: '/single',
        thumbnail: require('./images/single_well_surveillance.png')
    },
    {
        title: 'Multi Well Surveillance Dashboard',
        description: 'Well data visualization on time series for multiple wells and single udcs',
        redirect_link: '/multi',
        thumbnail: require('./images/multi_well_surveillance.png')
    },
    {
        title: 'Daily Production & Engineering Plots',
        description: 'Daily production and engineering plots for multiple wells',
        redirect_link: '/daily',
        thumbnail: require('./images/daily_surveillance.png')
    }
]

function Portal() {
    console.log("im in portal");

    // const project_thumbnail = require('./images/multi_well_surveillance.png');

    // const { accounts } = useMsal()
    // const account = useAccount(accounts[0] || {})

    // console.log(account)




    return (
        <PortalContainer>
            
            <Layout>
                {/* <img src={project_thumbnail} alt="sd" /> */}
            <WorkTitle>
                Surveillance Dashboard Apps
            </WorkTitle>
            {/* <Link to="/single">single </Link> */}

            <div className="d-flex flex-wrap justify-content-center ">

                {projects.map((project, index) => (
                    <ProjectCard
                        relativeDirectory={'./'}
                        key={index}
                        category={'BKV Corporation'}
                        title={project.title}
                        description={project.description}
                        thumbnail={project.thumbnail}
                        redirect_link={project.redirect_link}
                        />
                ))} 
                {/* <ProjectCard
                    relativeDirectory={'./'}
                    key={2}
                    category={'category'}
                    title={'title'}
                    description={'descriptio'}
                    thumbnail={project_thumbnail}
                />
                <ProjectCard
                    relativeDirectory={'./'}
                    key={3}
                    category={'category'}
                    title={'title'}
                    description={'descriptio'}
                    thumbnail={project_thumbnail}
                    uid={'uid'}
                />
                <ProjectCard
                    relativeDirectory={'./'}
                    key={4}
                    category={'category'}
                    title={'title'}
                    description={'descriptio'}
                    thumbnail={project_thumbnail}
                    uid={'uid'}
                />
                <ProjectCard
                    relativeDirectory={'./'}
                    key={5}
                    category={'category'}
                    title={'title'}
                    description={'descriptio'}
                    thumbnail={project_thumbnail}
                    uid={'uid'}
                />
                <ProjectCard
                    relativeDirectory={'./'}
                    key={6}
                    category={'category'}
                    title={'title'}
                    description={'descriptio'}
                    thumbnail={project_thumbnail}
                    uid={'uid'}
                /> */}
                </div>
            </Layout>
        </PortalContainer>
    );
}

export default Portal;
