

import React from 'react'

export const LeftPaneFlatLayout = (props) => {
    const {
        LeftChild,
        RightChild,
    } = props;
    return (
        <div className="d-flex flex-wrap flex-lg-nowrap">
            <div className="col-12 col-sm-6 col-md-12 col-lg-6 border-end p-3">
                {LeftChild}
            </div>
            <div className="col-12 col-sm-6 col-md-12 col-lg-6 p-3">
                {RightChild}
            </div>
        </div>
    );
};