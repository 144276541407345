import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  updateFacilities } from "bkv-components/store_lib/actions/settingsActions";

import FacilitySelectWithFilters from "bkv-components/FacilitySelection/FacilitySelectWithFilters";
import ArcgisMapSelection from "bkv-components/General/arcgis/ArcgisMapSelection";


export const SelectionComponent = ({type='facility',onSwitchPane}) => {
    /*
    * type: facility, map
    * */


    const dispatch = useDispatch();


    const filters = useSelector((state) => state.settings.filters);
    const well_data_hashed = useSelector((state) => state.raw_data.well_data_hashed);
    const well_data = useSelector((state) => state.raw_data.well_data);
    const active_p2_merrick_ids = useSelector((state) => state.settings.active_p2_merrick_ids);
    const plot_loading = useSelector((state) => state.settings.plot_loading);
    const current_group = useSelector((state) => state.user_data.current_group);


    // console.log(redux_state)

    const handleChange = async (new_selection) => {
        
        // check for new ids and download sensorsinfo
        let new_ids = [];
        if (active_p2_merrick_ids){
            new_ids = new_selection.filter((id) => !active_p2_merrick_ids.includes(id));
        } else {
            new_ids = new_selection;
        }
        // for (let i=0;i<new_ids.length;i++){
        //     let p2_scada_id = well_data_hashed[new_ids[i]].p2_scada_id;
        //     console.log("download sensor info");
        //     await dispatch(setPlotLoadingAction(true));
        //     await dispatch(downloadSensorInfoHashed(p2_scada_id));
        //     await dispatch(setPlotLoadingAction(false));
        // }
        // update active_p2_merrick_ids
        await dispatch(updateFacilities(new_selection));

        
    };

    // const filtered_well_data = well_data.filter((facility) =>
    //     checkFacilityFilters(facility, filters,well_data_hashed)
    // );

    // useEffect(() => {
    //     let filtered_well_data_aux = well_data.filter((facility) =>
    //         checkFacilityFilters(facility, filters,well_data_hashed,active_p2_merrick_ids)
    //     );
    //     setFilteredWellData(filtered_well_data_aux);
    //     console.log("filtered_well_data_aux changed");
    // }, [filters,well_data]);

    // useEffect(() => {
    //     console.log('filters changed SelectionComponent')
    // }, [filters]);

    // useEffect(() => {
    //     console.log('well_data changed SelectionComponent')
    // }, [well_data]);


    return (
        <>
        {type === "map" ?      
        
        // <MapboxMap
        //     filters={filters}
        //     well_data={well_data}
        //     selection={active_p2_merrick_ids?active_p2_merrick_ids:[]}
        //     onSelectionChange={handleChange}
        //     selector_type="checkbox"
        //     disabled={plot_loading}
        //     max_selected={5}
        // /> 
        <ArcgisMapSelection
            filters={filters}
            current_group={current_group}
            well_data={well_data}
            well_data_hashed={well_data_hashed}
            selection={active_p2_merrick_ids?active_p2_merrick_ids:[]}
            onSelectionChange={handleChange}
            selector_type="checkbox"
            disabled={plot_loading}
            max_selected={5}
        /> 

        
        :  
        
        <FacilitySelectWithFilters
            onSwitchPane={onSwitchPane}
            well_data={well_data}
            well_data_hashed={well_data_hashed}
            selection={active_p2_merrick_ids?active_p2_merrick_ids:[]}
            onSelectionChange={handleChange}
            disabled={plot_loading}
            filters={filters}
            selector_type="checkbox"
            max_selected={5}
        />}
        </>
    )
}
