import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateFiltersFromWellsData } from '../store_lib/actions/utilities';
import { useAccount, useMsal } from '@azure/msal-react';
import { setCurrentGroupAction, setCurrentGroupAndFiltersAction } from '../store_lib/actions/userDataActions';

export const GroupSelector = (props) => {

    const user_data_groups = useSelector((state) => state.user_data.groups);
    // const well_data_hashed = useSelector((state) => state.raw_data.well_data_hashed);
    // const well_data = useSelector((state) => state.raw_data.well_data);
    // const filters = useSelector((state) => state.settings.filters);
    const current_group = useSelector((state) => state.user_data.current_group);

    const dispatch = useDispatch();

    // const setCurrentGroup = (group) => dispatch(setCurrentGroupAction(group));




    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const username = account.username;


    // const onChange = (e) => {
    //     if (e.target.value === "none") {
    //         onGroupChange(null);
    //         return;
    //     }
    //     let group = user_data_groups[e.target.value];
    //     onGroupChange(group);
    // }

    const onChange = async (e) => {
        //updates filters

        
        let group;
        if (e.target.value === "none") {
            group = undefined;
        } else {
            group = user_data_groups[e.target.value];
        }

        await dispatch(setCurrentGroupAndFiltersAction(group));
        
        // let filtered_well_data = well_data;
        
        // if (e.target.value !== "none") {
        //     filtered_well_data = filterBySelectedGroup(well_data, group);
        // }

        // // clear options and active_options from filters (unless filter.id contains "data_available"")
        // const empty_filters = filters.map((filter) => {
        //     if ((filter.id).includes("data_available")) {
        //         return filter;
        //     } else{
        //         return {
        //             ...filter,
        //             options: [],
        //             active_options: [],
        //         };
        //     }
        // });
        
        // // const base_filters = JSON.parse(JSON.stringify(settingsInitState.filters));
        // // let new_filters = updateFiltersFromWellsData(filtered_well_data, base_filters);
        // let new_filters = updateFiltersFromWellsData(filtered_well_data, empty_filters);

        // //update group

        // if (e.target.value === "none") {
        //     await setCurrentGroup(undefined);
        // } else {
        //     await setCurrentGroup(group);
        // }

        // await dispatch({
        //     type: "UPDATE_FILTERS",
        //     payload: new_filters,
        // });
        




    }

  return (
    <div>
        <h5 className="ps-2">Group selection</h5>

        <div>
            <select 
                onChange={onChange}
                value={current_group ? current_group.id : "none"}
                className="form-select" 
                aria-label="Default select example">
                    <option value="none">None</option>
                {Object.keys(user_data_groups)
                    .filter((group_id) => user_data_groups[group_id].owner === username)
                    .map((group_id) => (
                    <option key={group_id} value={group_id}>
                        {user_data_groups[group_id].name}
                    </option>

                ))}
                <option disabled>────Shared by Others────</option>
                {Object.keys(user_data_groups)
                    .filter((group_id) => user_data_groups[group_id].owner !== username)
                    .map((group_id) => (
                    <option key={group_id} value={group_id}>
                        {user_data_groups[group_id].name} - {user_data_groups[group_id].owner}
                    </option>

                ))}
            </select>
        </div>
    </div>

  )
}

export const filterBySelectedGroup = (well_data, current_group) => {
    const wellsSet = new Set(current_group.wells);
    const filtered_well_data = well_data.filter(facility => wellsSet.has(facility.p2_merrick_id));
    return filtered_well_data;
};