// import "./styles/index.css";
// import "./styles/plots.css";
// import "./styles/checkboxmenu.css";
// import "./styles/index_2.css"

import React, { useEffect, useRef, useState } from "react";
import Header from "bkv-components/Layout/Header";
import { Provider, useDispatch, useSelector } from "react-redux";
import { downloadWellTable } from "bkv-components/store_lib/actions/dataActions";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import Layout from "bkv-components/Layout/Layout";
import { getP2ScadaIds } from "bkv-components/General/data_transformation";
import InnerAppLayout from "bkv-components/Layout/InnerAppLayout";
import DailyMultiWellPlot from "./components/daily_production_plot/DailyWellPlot";
import rootReducer from "./store/rootReducer";
import { fetchCommentsDBAction, fetchExternalCommentsAction } from "bkv-components/store_lib/actions/commentActions_db";
import { hashedActiveIds } from "./components/daily_utils";
import {
    downloadDailyMultiWellData,
    downloadEngineeringDataAction,
    updatePlotSelectionAction,
} from "./store/dailyAppActions";
import { RefreshContext } from "bkv-components/General/utils";
import EngineeringPlots from "./components/engineering_plots/EngineeringPlots";
import LeftPane from "./components/left_pane/LeftPane";
import { fetchGroupsDBAction } from "bkv-components/store_lib/actions/userDataActions";
// import FacilitySelection from "./components/FacilitySelection";
// const store = createStore(rootReducer);
const store = createStore(rootReducer, applyMiddleware(thunk));

// Hook
function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

const DailyMultiWellSurveillanceApp = (props) => {
    const dispatch = useDispatch();

    // DATA LOADING
    const active_p2_merrick_ids = useSelector(
        (state) => state.settings.active_p2_merrick_ids
    );
    const well_data_hashed = useSelector(
        (state) => state.raw_data.well_data_hashed
    );
    const active_p2_scada_ids = getP2ScadaIds(
        active_p2_merrick_ids,
        well_data_hashed
    );

    const plot_selection = useSelector(
        (state) => state.daily_app_settings.plot_selection
    );

    let hashed_p2_scadas_ids = hashedActiveIds(active_p2_scada_ids);

    const n_active_p2_merrick_ids = active_p2_merrick_ids
        ? active_p2_merrick_ids.length
        : 0;

    // DOWNLOAD WELLS TABLE ON MOUNT

    useEffect(() => {
        async function downloadWellTableHelper() {
            dispatch(downloadWellTable());
            dispatch(fetchGroupsDBAction());
        }
        if (Object.keys(well_data_hashed).length === 0) {
            downloadWellTableHelper();
        }
    }, []);

    // CHECK IF MUST UPDATE OR NOT
    const sensor_multiwell_data_info = useSelector(
        (state) => state.raw_data.sensor_multiwell_data
    );
    const engineering_data = useSelector(
        (state) => state.daily_app_data.engineering_data
    );

    async function downloadSensorMultiwellDataHelper(var_changed) {
        let condition = n_active_p2_merrick_ids > 0;
        condition = condition && plot_selection !== "none";

        if (condition) {
            if (var_changed === "n_active_p2_merrick_ids") {
                await dispatch({
                    type: "CLEAR_ENGINEERING_DATA",
                });
                await dispatch({
                    type: "CLEAR_SENSOR_DATA",
                });
            }

            if (var_changed === "plot_selection") {
                if (
                    plot_selection === "daily_production" &&
                    sensor_multiwell_data_info
                ) {
                    return;
                }
                if (
                    plot_selection === "engineering_plots" &&
                    engineering_data
                ) {
                    return;
                }
                if (plot_selection === "engineering_data" && engineering_data) {
                    return;
                }
            }
            await dispatch({
                type: "UPDATE_PLOT_LOADING",
                payload: true,
            });

            await dispatch({
                type: "CLEAR_COMMENTS",
            });

            try {
                if (plot_selection === "daily_production") {
                    dispatch(fetchCommentsDBAction(hashed_p2_scadas_ids));
                    dispatch(fetchExternalCommentsAction(active_p2_merrick_ids))
                    await dispatch(
                        downloadDailyMultiWellData(active_p2_merrick_ids)
                    );
                } else if (plot_selection === "engineering_plots") {
                    await dispatch(
                        downloadEngineeringDataAction(active_p2_merrick_ids)
                    );
                } else if (plot_selection === "engineering_data") {
                    await dispatch(
                        downloadEngineeringDataAction(active_p2_merrick_ids)
                    );
                }
            } catch (error) {
                console.log(error);
            }
            await dispatch({
                type: "UPDATE_PLOT_LOADING",
                payload: false,
            });
        }
    }

    // useEffect(() => {
    //     downloadSensorMultiwellDataHelper("n_active_p2_merrick_ids");
    // }, [n_active_p2_merrick_ids]);
    // useEffect(() => {
    //     downloadSensorMultiwellDataHelper("plot_selection");
    // }, [plot_selection]);

    const prev1 = usePrevious(n_active_p2_merrick_ids);
    const prev2 = usePrevious(plot_selection);

    useEffect(() => {
        if (prev1 !== undefined && prev2 !== undefined) {
            // component is mounted
            if (prev1 !== n_active_p2_merrick_ids) {
                downloadSensorMultiwellDataHelper("n_active_p2_merrick_ids");
            } else if (prev2 !== plot_selection) {
                downloadSensorMultiwellDataHelper("plot_selection");
            }
        }
    }, [n_active_p2_merrick_ids, plot_selection]);

    const clearSettings = async () => {
        await dispatch({
            type: "CLEAR_SETTINGS",
        });
        await dispatch({
            type: "CLEAR_COMMENTS",
        });
        //plot selection
        await dispatch(updatePlotSelectionAction("none"));
        await dispatch({
            type: "CLEAR_DAILY_APP_SETTINGS",
        });
        await dispatch({
            type: "CLEAR_ENGINEERING_DATA",
        });
        await dispatch({
            type: "CLEAR_SENSOR_MULTIWELL_DATA",
        });
    };

    //InnerAppLayout props
    const user_plot_settings = useSelector(
        (state) => state.settings.user_plot_settings
    );
    const [show_settings, setShowSettings] = useState(true);
    const { well_data, well_data_error } = useSelector(
        (state) => state.raw_data
    );
    const wells_loaded = Object.keys(well_data).length > 0;
    const plot_mounted = useSelector((state) => state.settings.plot_mounted);

    return (
        <RefreshContext.Provider value={downloadSensorMultiwellDataHelper}>
            <Layout>
                <Header title="Daily Production" />

                <InnerAppLayout
                    leftPane={
                        // <LeftPaneFlat user_plot_settings={user_plot_settings} />
                        <LeftPane />
                    }
                    rightPane={
                        <RightPane
                            show_settings={show_settings}
                            setShowSettings={setShowSettings}
                        />
                    }
                    wells_loaded={wells_loaded}
                    well_data_error={well_data_error}
                    plot_mounted={plot_mounted}
                    show_settings={show_settings}
                    setShowSettings={setShowSettings}
                    clearSettings={clearSettings}
                />
            </Layout>
        </RefreshContext.Provider>
    );
};

const App = (props) => {
    return (
        <>
            <Provider store={store}>
                <DailyMultiWellSurveillanceApp />
            </Provider>
        </>
    );
};

export default App;

const RightPane = (props) => {
    const { show_settings, setShowSettings } = props;

    const plot_selection = useSelector(
        (state) => state.daily_app_settings.plot_selection
    );

    return (
        <div className=" maincontainer p-3 h-100">
            {plot_selection === "daily_production" && (
                <DailyMultiWellPlot
                    setShowSettings={setShowSettings}
                    show_settings={show_settings}
                />
            )}
            {/* {plot_selection === "engineering_plots" && (
                <EngineeringPlots
                    setShowSettings={setShowSettings}
                    show_settings={show_settings}
                />
            )} */}
            {(plot_selection === "engineering_data" ||
                plot_selection === "engineering_plots") && (
                <EngineeringPlots
                    setShowSettings={setShowSettings}
                    show_settings={show_settings}
                    plot_selection={plot_selection}
                />
            )}
            {plot_selection === "none" && (
                <>
                    <div className="justify-content-between align-items-center d-flex">
                        <h2>Plots</h2>
                    </div>
                    <hr />
                    Select wells and plot type...
                </>
            )}
        </div>
    );
};
