

export const getP2ScadaIds = (p2_merrick_ids, well_data_hashed) => {
    try{
        return p2_merrick_ids.map((p2_merrick_id) => {
            return well_data_hashed[p2_merrick_id].p2_scada_id;
        });

    }catch (e) {
        return [];
    }
}


export const getTimeRangeFromSensorInfo = (sensor_info_hashed,active_p2_scada_ids) => {

    // for each active_p2_scada_id, get the start_time and end_time
    // return the min start_time and max end_time
    let start_time = null;
    let end_time = null;
    active_p2_scada_ids.forEach((p2_scada_id) => {
        const sensor_info = sensor_info_hashed[p2_scada_id];
        if (start_time === null) {
            start_time = sensor_info.start_time;
        } else {
            start_time = Math.min(start_time, sensor_info.start_time);
        }
        if (end_time === null) {
            end_time = sensor_info.end_time;
        } else {
            end_time = Math.max(end_time, sensor_info.end_time);
        }
    }
    );
    return [start_time,end_time];
}