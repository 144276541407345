import { updateFilterState } from "bkv-components/store_lib/actions/settingsActions";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
// import { updateFilterState } from "../../store/actions/settingsActions";

const Div = styled.div`
    .selectBox {
        position: relative;
    }

    .selectBox select {
        width: 100%;
    }

    .overSelect {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .filter-ul {
        max-height: 300px;
        margin-bottom: 10px;
        overflow: scroll;
        overflow-x: hidden; //auto
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .list-group-item {
        white-space: nowrap;
    }

    .filter-ul label,
    .sensors label {
        font-size: small;
    }
`;

function useOutsideAlerter(ref, handleClick) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                //   alert("You clicked outside of me!");
                handleClick();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
export function OutsideAlerter(props) {
    const { handleClick } = props;
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, handleClick);

    return <div ref={wrapperRef}>{props.children}</div>;
}

function capitalizeWords(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  

function isNameID(id) {
    switch (id) {
        case "p2_foreman":
            return true;
        case "p2_pumper":
            return true;
        case "p2_engineer":
            return true;
        case "p2_supervisor":
            return true;
        default:
            return false;
    }
}

function FilterCheckBox(props) {
    const { name, id, options, active_options } = props;

    const dispatch = useDispatch();

    const [ishidden, setishidden] = useState(true);

    const handleChange = (option) => {
        //change value in redux store
        // remake and dispatchfilter object
        let new_active_options = [...active_options];
        let index = new_active_options.indexOf(option);
        if (index == -1) {
            new_active_options.push(option);
        } else {
            new_active_options.splice(index, 1);
        }

        let updated_filter = {
            name: name,
            id: id,
            options: options,
            active_options: new_active_options,
        };
        dispatch(updateFilterState(updated_filter));
    };

    const clickOutside = () => {
        // console.log('clickOutside',id);
        setishidden(true);
    };

    const toggleHidden = (e) => {
        e.preventDefault();
        setishidden(!ishidden);
    };

    const filter_has_checks = active_options.length > 0;

    return (
        <Div className="dropdowncheckbox">
            <OutsideAlerter handleClick={clickOutside}>
                <div
                    className="selectBox"
                    onClick={(e) => {
                        toggleHidden(e);
                    }}
                >
                    <select
                        className={
                            "form-select " +
                            (filter_has_checks ? "border-primary" : "")
                        }
                    >
                        <option>{name}</option>
                    </select>
                    <div className="overSelect"></div>
                </div>

                <ul
                    className="list-group filter-ul"
                    style={{ display: ishidden ? "none" : "block" }}
                    id="checkboxes"
                >
                    {options.map((option) => {
                        //check if option is in active options
                        let option_value = active_options.includes(option);
                        return (
                            <label className="list-group-item" key={option}>
                                <input
                                    className="form-check-input me-1"
                                    checked={option_value}
                                    onChange={(e) => {
                                        handleChange(option);
                                    }}
                                    name={"checkbox" + option.id}
                                    type="checkbox"
                                />
                                {typeof option === "boolean"
                                    ? option
                                        ? "True"
                                        : "False"
                                    : 
                                    isNameID(id) ? capitalizeWords(option) : option}
                            </label>
                        );
                    })}
                </ul>
            </OutsideAlerter>
        </Div>
    );
}

export default FilterCheckBox;
