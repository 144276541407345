import { combineReducers } from 'redux'
import commentReducer from './commentReducer'
// import { authReducer } from '@junicus_/react-msal-redux';

const rootReducer = combineReducers({
  comments: commentReducer
})

export default rootReducer

// the key name will be the data property on the state object
