import React, { useEffect, useState } from "react";
// import { generate_fake_data, normalizeTime } from "./Plots/utils";
import { connect, useDispatch } from "react-redux";
import {
    setPlotMountedAction,
    setPlotLoadingAction,
} from "bkv-components/store_lib/actions/settingsActions";
// import { downloadSensorData } from "../store/actions/dataActions";
import {
    createCommentAction,
    fetchCommentsDBAction,
    updateCommentsAction,
} from "bkv-components/store_lib/actions/commentActions_db";
import { useSelector } from "react-redux";
import { useAccount, useMsal } from "@azure/msal-react";
import { getColorFromUDC, normalizeTime } from "bkv-components/SensorPlot/Components/utils";
import {
    downloadSensorData,
} from "bkv-components/store_lib/actions/dataActions";
import { PlotHeader } from "bkv-components/Layout/PlotHeader";
import { RefreshContext, getUDCInfo } from "bkv-components/General/utils";
import { DisplayRequestError } from "bkv-components/General/DisplayRequestError";
import SensorPlotV2 from "bkv-components/SensorPlot/SensorPlotV2";
import PlotResolution from "bkv-components/General/PlotResolution";

const addDays = (date, days) => {
    // add days to date
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

const isANumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
};

function Loading() {
    const dispatch = useDispatch();

    useEffect(() => {
        // set the loading state to true
        dispatch(setPlotLoadingAction(true));

        return () => {
            // set the loading state to false
            dispatch(setPlotLoadingAction(false));
        };
    }, []);

    return <div>Loading...</div>;
}

function Plots(props) {
    // from redux most of them
    const {
        title,
        sensor_data,
        sensor_data_info,
        http_request_error,
        http_request_error_status,
        active_p2_merrick_id,
        show_settings,
        setShowSettings,
        min_time,
        max_time,
        well_data_hashed,
    } = props;

    const [selectedInterval, setSelectedInterval] = React.useState(undefined);

    const [sensor_data_w_loadfactor, setSensorDataWLoadFactor] = useState(sensor_data);

    const dispatch = useDispatch();

    if (well_data_hashed && active_p2_merrick_id) {
        var p2_scada_id = well_data_hashed[active_p2_merrick_id].p2_scada_id;
    }
    if (min_time && max_time) {
        var start_timeline = normalizeTime(min_time);
        var end_timeline = normalizeTime(max_time);
    }

    // SENSORPLOT STUFF
    const comments_dict = useSelector((state) => state.raw_data.comments_dict);
    const user_plot_settings = useSelector(
        (state) => state.settings.user_plot_settings
    );
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const onUpdateResolution = async (
        start_time = undefined,
        end_time = undefined
    ) => {
        await dispatch(setPlotLoadingAction(true));
        try{
            await dispatch(downloadSensorData(p2_scada_id, start_time, end_time));
        } catch (error) {
            console.log(error);
        } finally {
            await dispatch(setPlotLoadingAction(false));
        }


    };

    const onCreateComment = (comment) => {
        // comment.p2_scada_id = comment.label
        // comment.label = active_udc;
        console.log(comment);
        dispatch(createCommentAction(comment));
    };

    const onUpdateComments = (comment_list) => {
        dispatch(updateCommentsAction(comment_list));
    };

    const onPlotMountedChange = (plot_mounted) => {
        dispatch(setPlotMountedAction(plot_mounted));
    };

    const fetchData = async () => {
        await dispatch(setPlotLoadingAction(true));
        if (p2_scada_id) {
            // FROM sensor_data
            await dispatch(
                downloadSensorData(
                    p2_scada_id
                    // selectedStart,
                    // selectedEnd
                )
            );
            await dispatch(
                fetchCommentsDBAction(
                    p2_scada_id
                    // selectedStart, //uncomment
                    // selectedEnd
                )
            );
        } else {
            setSelectedInterval(undefined);
        }
        await dispatch(setPlotLoadingAction(false));
    };

    useEffect(() => {
        //DATA LOADING
        console.log("plot data first load");

        fetchData();
    }, [p2_scada_id, dispatch]);

    if (sensor_data) {
        var sensor_data_start_time = sensor_data.record_time[0];
        var sensor_data_end_time =
            sensor_data.record_time[sensor_data.record_time.length - 1];
    }

    //update timerange slider
    useEffect(() => {
        if (sensor_data) {
            let start_time = normalizeTime(sensor_data_start_time);
            let end_time = normalizeTime(sensor_data_end_time);
            setSelectedInterval([start_time, end_time]);
        }
    }, [sensor_data_start_time, sensor_data_end_time, sensor_data]);

    useEffect(() => {
        dispatch(setPlotMountedAction(false));
    }, [dispatch]);


    
    useEffect(() => {
        if (sensor_data) {
            // add LOADFACTOR to sensor_data
            // formula for calculationg LOADFACTOR = (PCASE0-PTUBE0)/(PCASE0-CMLINE0)

            // create an array of zeros with the same length as sensor_data
            let load_factor_data = Array(sensor_data.record_time.length).fill("");
            let flag = false;
            if (sensor_data && sensor_data.PCASE0 && sensor_data.PTUBE0 && sensor_data.CMLINE0){
                for (let i = 0; i < sensor_data.record_time.length; i++) {
                    let no_gasflow = true;
                    if (sensor_data.INSFLG0) {
                        no_gasflow = sensor_data.INSFLG0[i] < 10;
                    }
                    let condition3 = isANumber(sensor_data.PCASE0[i]) && isANumber(sensor_data.PTUBE0[i]) && isANumber(sensor_data.CMLINE0[i]);
                    let denominator = (sensor_data.PCASE0[i] - sensor_data.CMLINE0[i]);
                    if ( denominator !== 0 && no_gasflow && condition3) {
                        let load_factor_data_i = (sensor_data.PCASE0[i] - sensor_data.PTUBE0[i]) / denominator*100;
                        load_factor_data[i] = load_factor_data_i.toFixed(0);
                        flag = true;
                    }
                }
            }

            // add LOADFACTOR to sensor_data
            if (flag) {
                sensor_data["LOADFACTOR"] = load_factor_data;
            }
            setSensorDataWLoadFactor(sensor_data);
        }
    }, [sensor_data]);


    if (!sensor_data || !(selectedInterval && start_timeline && end_timeline)) {
        if (http_request_error) {
            // dispatch(setPlotMountedAction(false));
            return <DisplayRequestError error={http_request_error} />;
        } else if (active_p2_merrick_id) {
            return <Loading />;
        } else if (!active_p2_merrick_id) {
            return <div>Please select a facility</div>;
        }
    } else {






    // build trace_info and axis_info -------------- for single well application  -------
    let trace_info = {};
    let axis_info = {};
    let udcs = Object.keys(sensor_data_w_loadfactor).filter((key) => key !== "record_time");
    let gas_rate_present = udcs.includes("INSFLG0");
    let load_factor_present = udcs.includes("LOADFACTOR");
    let merged = user_plot_settings.merged_pressure_plots;
    
    for (let udc of udcs) {
        let merged_yaxis_name = udc==="INSFLG0" ?  "INSFLG0": (udc==="LOADFACTOR" ? "LOADFACTOR": "PRESSURE" )
        trace_info[udc] = {
            well_id: p2_scada_id,
            sensor_id: udc,
            label: udc,
            yaxis_name: merged ? merged_yaxis_name : udc,
            color: getColorFromUDC(udc),
            unit: getUDCInfo(udc).unit,
        };
    }
    
    if (merged){
        let domain_left = 1;
        if (gas_rate_present && !load_factor_present){
            axis_info["INSFLG0"] = {
                domain_height: 0.3,
                unit: getUDCInfo("INSFLG0").unit,
            };
            domain_left = 0.7;
        } else if (load_factor_present && !gas_rate_present){
            axis_info["LOADFACTOR"] = {
                domain_height: 0.3,
                unit: getUDCInfo("LOADFACTOR").unit,
            };
            domain_left = 0.7;
        } else if (load_factor_present && gas_rate_present){
            axis_info["LOADFACTOR"] = {
                domain_height: 0.2,
                unit: getUDCInfo("LOADFACTOR").unit,
            };
            axis_info["INSFLG0"] = {
                domain_height: 0.2,
                unit: getUDCInfo("INSFLG0").unit,
            };
            domain_left = 0.6;
        } 
        axis_info["PRESSURE"] = {
            domain_height: domain_left,
            unit: 'psi',
        };
    } else{
        let sorted_udc = ["LOADFACTOR","INSFLG0","CMLINE0","PTUBE0","PCASE0"];
        let sorted_existing_udc = sorted_udc.filter((udc) => udcs.includes(udc))
        for (let udc of sorted_existing_udc){
            axis_info[udc] = {
                unit: getUDCInfo(udc).unit,
                domain_height: 1/udcs.length,
                color: getColorFromUDC(udc),
            };
        }
    }
    



        return (
            <RefreshContext.Provider value={fetchData}>
            <>
                <PlotHeader title={title}
                show_settings={show_settings}
                setShowSettings={setShowSettings}

             />
             <hr/>
                <div>
                    <PlotResolution
                        base_resolution={sensor_data_info.base_resolution}
                        resolution={sensor_data_info.resolution}
                        num_rows={sensor_data_info.num_rows}
                    />

                    {/* <SensorPlot
                        sensor_data={sensor_data}
                        show_settings={show_settings}
                        start_time={start_timeline}
                        end_time={end_timeline}
                        comments_dict={comments_dict}
                        onUpdateResolution={onUpdateResolution}
                        onCreateComment={onCreateComment}
                        onUpdateComments={onUpdateComments}
                        onPlotMountedChange={onPlotMountedChange}
                        user_plot_settings={user_plot_settings}
                        mode="fixed_well"
                        p2_scada_id={p2_scada_id}
                        account={account}
                    /> */}
                    <SensorPlotV2
                        sensor_data={sensor_data_w_loadfactor}
                        trace_info={trace_info}
                        axis_info={axis_info}
                        show_settings={show_settings}
                        start_time={start_timeline}
                        end_time={end_timeline}
                        comments_dict={comments_dict}
                        onUpdateResolution={onUpdateResolution}
                        onCreateComment={onCreateComment}
                        onUpdateComments={onUpdateComments}
                        onPlotMountedChange={onPlotMountedChange}
                        user_plot_settings={user_plot_settings}
                        account={account}
                        application={"single"}
                    />


                </div>
            </>
            </RefreshContext.Provider>
        );
    }
    // } else {
    //     throw Error(
    //         "BUG. This application is still under development. Please report this error to manuel.solalinde@bkvcorp.com"
    //     );
    // }
}

const mapStateToProps = (state) => {
    let sensor_data = undefined;
    let sensor_data_info = undefined;
    if (state.raw_data.sensor_data) {
        sensor_data = state.raw_data.sensor_data.data;
        sensor_data_info = state.raw_data.sensor_data;
    }
    return {
        sensor_data: sensor_data,
        sensor_data_info: sensor_data_info,
        http_request_error: state.raw_data.http_request_error,
        http_request_error_status: state.raw_data.http_request_error_status,
        active_p2_merrick_id: state.settings.active_p2_merrick_id,
        max_time: state.raw_data.max_time,
        min_time: state.raw_data.min_time,
        well_data_hashed: state.raw_data.well_data_hashed,
    };
};

export default connect(mapStateToProps)(Plots);
