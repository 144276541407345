import { useMsal } from '@azure/msal-react'
import React, { Component } from 'react'
import styled from 'styled-components'
import { signIn } from '../utils/auth_utils'

// import { signIn } from '../store/authActions'


// react-msal-redux
// import { login } from '@junicus_/react-msal-redux'

const Div = styled.div`
.main {
    width:100vw;
    background-color: white;
    height: 100vh;
    /* height: fill-available; */

    /* height: -webkit-fill-available; */
}

.sign_in_body{
    border: 1px solid;
    border-color: darkgray;
    border-radius: 10px;
    width: 400px;
    height: 400px;
}

//below widths of 400px
@media (max-width: 410px) {
    .sign_in_body{
        width: 100%;
    }
}


`

const SignIn = (props) => {
    // const dispatch = useDispatch();
    const { instance } = useMsal();

    const handleLogin = async (e) => {
        e.preventDefault();

        await signIn(instance);
        // dispatch(login({
        //     popup: true,
        //     scopes: ['api://f6289fa0-1afd-4cd5-9625-df5b7d63969f/access_as_user']
        // }));
    }
    // const {auth} = this.props;
    // if (auth.uid) return <Redirect to='/'/>

    return (
        <Div >
            <div className='main d-flex justify-content-center align-items-center'>
                <div className="text-center sign_in_body d-flex align-items-center row p-5">
                    <img className="mb-4" src="./bkv_logo.png" alt="" />
                    <h3 className="h4 mb-3 font-weight-normal">Surveillance Dashboard</h3>

                    <button className="btn btn-lg btn-primary btn-block"
                        onClick={(e) => handleLogin(e)}>Sign in</button>
                    {/* <p className="mt-5 mb-3 text-muted">© 2017-2020</p> */}
                </div>
            </div>
        </Div>
    )
}


export default SignIn

