import { setPlotMountedAction } from "bkv-components/store_lib/actions/settingsActions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMeasure } from "react-use";
import MultiTracePlot from "../MultiTracePlot";
import { axis_settings } from "../v2/AllEngineeringPlotsV2";

const v1_plots = {
    gas_vs_date: {
        y: "gas_production",
        x: "production_date",
        x_scale: "date",
        y_scale: "log",
    },
    gas_vs_cumulative_gas: {
        y: "gas_production",
        x: "gas_cumulative",
        x_scale: "log",
        y_scale: "log",
    },
    inverse_gas_vs_sqrt_date: {
        y: "reciprocal_gas_rate",
        x: "sqrt_t",
        x_scale: "linear",
        y_scale: "log",
    },
    water_vs_cumulative_water: {
        y: "water_production",
        x: "water_cumulative",
        x_scale: "log",
        y_scale: "log",
    },
    gas_vs_mbt: {
        y: "gas_production",
        x: "mbt",
        x_scale: "log",
        y_scale: "log",
    },
};

const AllEngineeringPlotsV1 = (props) => {
    const { data, plot_colors } = props;

    const [ref, { width }] = useMeasure();

    const plot_sub_selection = useSelector(
        (state) => state.daily_app_settings.plot_sub_selection
    );

    const dispatch = useDispatch();

    const handlePlotMountedChange = (plot_mounted) => {
        dispatch(setPlotMountedAction(plot_mounted));
    };
    useEffect(() => {
        // on width change
        console.log("width change");
    }, [width]);

    useEffect(() => {
        handlePlotMountedChange(true);
    }, [data]);

    return (
        <div>
            {plot_sub_selection !== "all" ? (
                <SubEngineeringPlot
                    data={data}
                    plot_colors={plot_colors}
                    plot_sub_selection={plot_sub_selection}
                />
            ) : (
                <div className="panel d-flex flex-wrap" ref={ref}>
                    {Object.keys(v1_plots).map((plot_name) => (
                        <SubEngineeringPlot
                            key={plot_name}
                            style={width > 1000 ? { width: "50%" }:{width:"100%"}}
                            data={data}
                            plot_colors={plot_colors}
                            plot_sub_selection={plot_name}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default AllEngineeringPlotsV1;

const SubEngineeringPlot = (props) => {
    const { data, plot_colors, plot_sub_selection, style } = props;

    const plot_x_axis = v1_plots[plot_sub_selection].x;
    const plot_y_axis = v1_plots[plot_sub_selection].y;
    const x_axis_scale = v1_plots[plot_sub_selection].x_scale;
    const y_axis_scale = v1_plots[plot_sub_selection].y_scale;

    const data_x = data[plot_x_axis];
    const data_y = data[plot_y_axis];

    const plot_settings = {
        connectgaps: false,
        plot_height: 700,
        plot_colors: plot_colors,
        square_ratio: plot_x_axis === 'mbt' ? true : false,
    };

    const engineering_settings = {
        x_title: axis_settings[plot_x_axis].title,
        y_title: axis_settings[plot_y_axis].title,
        base_unit: axis_settings[plot_y_axis].unit,
        x_type: x_axis_scale,
        y_type: y_axis_scale,
    };

    const dispatch = useDispatch();

    const onAfterPlot = (figure) => {
        dispatch(setPlotMountedAction(true));
    };

    return (
        <div style={style} className="maincontainer element p-4">
            <h4>
                {axis_settings[plot_y_axis].title +
                    " vs " +
                    axis_settings[plot_x_axis].title}
            </h4>
            <hr />
            <MultiTracePlot
                x={data_x}
                y={data_y}
                plot_settings={plot_settings}
                extra_settings={engineering_settings}
                onAfterPlot={onAfterPlot}
                // revision={revision}
            />
        </div>
    );
};
