import React, { useEffect, useState } from "react";
import FacilitySelectBase from "./FacilitySelectBase";
import PropTypes from "prop-types";
import AdditionalFilters from "./AdditionalFilters";
import { GroupSelector, filterBySelectedGroup } from "../Groups/GroupSelector";
import { useSelector } from "react-redux";

const isDict = (obj) => {
    return typeof obj === "object" && obj !== null && !Array.isArray(obj);
};

const isList = (obj) => {
    return typeof obj === "object" && obj !== null && Array.isArray(obj);
};

// FIXME updates on property is_cached is not reflected do to memoization
// SUGESTION: use memoization to retrieve only ids, and then well_data.filter to get the data
let cache = new Map();

function memoize(func) {

    return function (...args) {

        // simplify key (turn large objects into length of keys)
        let key_args = args.map((arg) => {
            if (isDict(arg)) {
                if (Object.keys(arg).length > 100) {
                    let keys_str = Object.keys(arg).join("");
                    return Object.keys(arg).length + keys_str;
                } else {
                    return arg;
                }
            } else if (isList(arg)) {
                if (arg.length > 100) {
                    if (isDict(arg[0])) {
                        let keys_str = Object.keys(arg[0]).join("");
                        return arg.length + keys_str;
                    } else {
                        return arg.length
                    }
                } else {
                    return arg;
                }
            } else {
                return arg;
            }
        });

        let key = JSON.stringify(key_args);

        //make key more compact
        key = key.replace(/ /g, "");

        if (cache.has(key)) {
            console.log("cache hit");
            return cache.get(key);
        } else {
            console.log("cache added");
        }
        // remove old entries if cache is too big
        if (cache.size > 10) {
            cache.clear();
        }

        const result = func(...args);
        cache.set(key, result);

        return result;
    };
}

function FacilitySelectWithFilters(props) {
    const {
        onSwitchPane,
        well_data,
        well_data_hashed,
        selection,
        onSelectionChange,
        disabled,
        filters,
        selector_type = "checkbox",
        max_selected = 5,
        cached_p2_scada_ids = [],
    } = props;


    
    const current_group = useSelector((state) => state.user_data.current_group);
    
    // const [selected_w_filtered, setSelectedWFiltered] = useState([]);


    let selected_w_filtered = evalFilteredWellData(
        current_group,
        well_data,
        filters,
        selection,
        selector_type,
        well_data_hashed,
    );

    // Add ticks
    let selected_w_filtered_cached = selected_w_filtered;

    if (cached_p2_scada_ids.length > 0) {
        selected_w_filtered_cached = selected_w_filtered_cached.map((facility) => {
            let is_cached = cached_p2_scada_ids.includes(facility.p2_scada_id);
            return {
                ...facility,
                is_cached: is_cached,
            };
        });
    }

    console.log("selected_w_filtered changed");


    return (
        <>
            <FacilitySelectBase
                title={"Well Selection"}
                selection={selection}
                data={selected_w_filtered_cached}
                onSelectionChange={onSelectionChange}
                disabled={disabled}
                max_selected={max_selected}
                selector_type={selector_type}
                onSwitchPane={onSwitchPane}
            />

            <br />

            <GroupSelector />

            <br />

            <AdditionalFilters filters={filters} />
        </>
    );
}

export default FacilitySelectWithFilters;

// PropType definitions
FacilitySelectWithFilters.propTypes = {
    onSwitchPane: PropTypes.func,
    well_data: PropTypes.array.isRequired,
    selection: PropTypes.array.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    filters: PropTypes.array.isRequired,
};

// export const checkFacilityFilters = (facility, filters,well_data_hashed,selection) => {
//     let p2_merrick_id = facility.p2_merrick_id;
//     let is_selected = selection && selection.includes(p2_merrick_id);
//     if (is_selected) {
//         return true; //keep facility selected on filter change
//     }
//     let well_row = well_data_hashed[p2_merrick_id];

//     //loop through filters to see if data can be shown
//     // let filters = settings.filters;
//     return filterRow(well_row, filters);
// };

const filterRow = (well_row, filters) => {
    if (!well_row) {
        console.error("facility_id does not exist in well_data_hashed");
        return false;
    }

    for (let filter of filters) {
        let key = filter.id; //example: p2_foreman
        if (filter.active_options.length > 0 && !filter.active_options.includes(well_row[key])) {
            return false;
        }
    }

    return true;
};

export const filterWellData = (well_data, filters, selection = []) => {
    const selectionSet = new Set(selection);
    const filtered_well_data = well_data.filter((facility) => {
        const p2_merrick_id = facility.p2_merrick_id;
        const is_selected = selectionSet.has(p2_merrick_id);
        return is_selected || filterRow(facility, filters);
    });
    return filtered_well_data;
};





const joinSelectedWithFiltered = (selection, filtered_well_data, selector_type, well_data_hashed) => {
    const selected_data = selection.map((p2_merrick_id) => {
        return well_data_hashed[p2_merrick_id];
    });

    let filtered_p2_meterick_ids = filtered_well_data.map((facility) => {
        return facility.p2_merrick_id;
    });

    let selected_not_in_filtered = selected_data.filter((facility) => {
        return !filtered_p2_meterick_ids.includes(facility.p2_merrick_id);
    });

    // // turn into list of dicts
    // selected_not_in_filtered = selected_not_in_filtered.map((p2_merrick_id) => {
    //     return well_data_hashed[p2_merrick_id];
    // });

    if (selected_not_in_filtered.length === 0) {
        return filtered_well_data;
    } else {
        let result = [...selected_not_in_filtered, ...filtered_well_data];
        if (selector_type === "radio") {
            //sort by p2_well_name
            result = result.sort((a, b) => {
                return a.p2_well_name.localeCompare(b.p2_well_name);
            });
        }
        return result;
    }
};



const calculateFilteredWellDataIds = (current_group, well_data, filters) => {
    let filtered_well_data_aux = well_data;

    // filter by current group
    if (current_group) {
        filtered_well_data_aux = filterBySelectedGroup(well_data, current_group);
    }

    // get filtered well data
    filtered_well_data_aux = filterWellData(filtered_well_data_aux, filters);
    console.log("     filtered_well_data_aux changed");

    let ids = filtered_well_data_aux.map((facility) => {
        return facility.p2_merrick_id;
    });

    return ids;
};

export const evalFilteredWellData = (current_group, well_data, filters, selection, selector_type, well_data_hashed) => {
    //use memoization to avoid re-evaluating filtered_well_data
    let merrick_ids = memoize(calculateFilteredWellDataIds)(current_group, well_data, filters);
    // let merrick_ids = calculateFilteredWellDataIds(current_group, well_data, filters);

    let filtered_well_data_aux = merrick_ids.map((merrick_id) => {
        return well_data_hashed[merrick_id];
    });

    //join selected with filtered
    let selected_w_filtered_aux = memoize(joinSelectedWithFiltered)(
    // let selected_w_filtered_aux = joinSelectedWithFiltered(
        selection,
        filtered_well_data_aux,
        selector_type,
        well_data_hashed,
    );

    // use only the ids
    selected_w_filtered_aux = selected_w_filtered_aux.map((facility) => {
        return well_data_hashed[facility.p2_merrick_id];
    });
        

    return selected_w_filtered_aux;
};