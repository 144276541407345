import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCommentAction, updateCommentsAction } from "bkv-components/store_lib/actions/commentActions_db";
import { downloadSensorMultiwellData } from "bkv-components/store_lib/actions/dataActions";
import { setPlotLoadingAction, setPlotMountedAction } from "bkv-components/store_lib/actions/settingsActions";
import {
    getP2ScadaIds,
} from "bkv-components/General/data_transformation";
import { useAccount, useMsal } from "@azure/msal-react";
import { PlotHeader } from "bkv-components/Layout/PlotHeader";
import { normalizeTime } from "bkv-components/SensorPlot/Components/utils";
import { DisplayRequestError } from "bkv-components/General/DisplayRequestError";
import MultiWellPlotHelper from "./MultiWellPlotHelper";
import PlotResolution from "bkv-components/General/PlotResolution";

function MultiWellPlot(props) {
    const { show_settings, setShowSettings } = props;

    const { accounts } = useMsal()
    const account = useAccount(accounts[0] || {})

    console.log("account", account)

    const dispatch = useDispatch();

    const active_p2_merrick_ids = useSelector(
        (state) => state.settings.active_p2_merrick_ids
    );

    const well_data_hashed = useSelector(
        (state) => state.raw_data.well_data_hashed
    );
    const sensor_info_hashed = useSelector(
        (state) => state.raw_data.sensor_info_hashed
    );
    const comments_dict = useSelector((state) => state.raw_data.comments_dict);

    const user_plot_settings = useSelector((state) => state.settings.user_plot_settings);

    const sensor_multiwell_data_info = useSelector(
        (state) => state.raw_data.sensor_multiwell_data
    );

    const http_request_error = useSelector(
        (state) => state.raw_data.http_request_error
    );

    const active_udc = useSelector((state) => state.settings.active_udc);

    const min_time = useSelector((state) => state.raw_data.min_time);
    const max_time = useSelector((state) => state.raw_data.max_time);

    if (min_time && max_time) {
        var start_timeline = normalizeTime(min_time);
        var end_timeline = normalizeTime(max_time);
    }

    let status = "ready"; // can be "ready", "not_ready", "error"
    let message = "";

    if (!active_p2_merrick_ids || active_p2_merrick_ids.length === 0) {
        status = "not_ready";
        message = "Please select between 1 and 5 Wells";
    } else if (active_p2_merrick_ids.length > 0 && !active_udc) {
        status = "not_ready";
        message = "Please select a UDC";
    } else if (http_request_error) {
        status = "error";
        message = http_request_error;
    } else if (active_p2_merrick_ids.length > 0 && active_udc) {
        status = "ready";
        try {
            var active_p2_scada_ids = getP2ScadaIds(
                active_p2_merrick_ids,
                well_data_hashed
            );
            // // check if all active_p2_scada_ids are included in sensor_info_hashed
            // active_p2_scada_ids.forEach((p2_scada_id) => {
            //     if (!sensor_info_hashed[p2_scada_id]) {
            //         status = "not_ready";
            //         message = "Loading well information...";
                    
            //     }
            // });
            if (status === "ready") {

                // check if all there is sensor_multiwell_data
                if (!sensor_multiwell_data_info) {
                    status = "not_ready";
                    message = "Loading sensor data...";
                } else {
                    var data = sensor_multiwell_data_info.data;
                }
            }

        } catch (e) {
            status = "error";
            message = e.message;
        }
    } else {
        status = "error";
        message = "Unknown error";
    }

    useEffect(() => {
        dispatch(setPlotMountedAction(false));
    }, [dispatch]);


    
    const onUpdateResolution = async (start_time, end_time) => {
        console.log(active_p2_scada_ids)
        await dispatch(setPlotLoadingAction(true));
        await dispatch(
            downloadSensorMultiwellData(
                active_p2_scada_ids,
                active_udc,
                start_time,
                end_time
            )
        );
        await dispatch(setPlotLoadingAction(false));
    };

    const onCreateComment = (comment) => {
        // comment.p2_scada_id = comment.label
        // comment.label = active_udc;
        console.log(comment)
        dispatch(createCommentAction(comment));
    };

    const onUpdateComments = (comment_list) => {
        dispatch(updateCommentsAction(comment_list));
    };

    const onPlotMountedChange = (plot_mounted) => {
        dispatch(setPlotMountedAction(plot_mounted));
    }


    

    return (
        <>
            <PlotHeader title="Sensor Data"
            show_settings={show_settings}
            setShowSettings={setShowSettings}
             />
            <hr />
            {status === "not_ready" && <div>{message}</div>}
            {status === "error" && <DisplayRequestError error={message} />}
            {status === "ready" && (
                <div>
                    <PlotResolution
                        base_resolution={sensor_multiwell_data_info.base_resolution}
                        resolution={sensor_multiwell_data_info.resolution}
                        num_rows={sensor_multiwell_data_info.num_rows}
                        />

                    <MultiWellPlotHelper
                        sensor_data={data}
                        show_settings={show_settings}
                        start_time={start_timeline}
                        end_time={end_timeline}
                        comments_dict={comments_dict}
                        onUpdateResolution={onUpdateResolution}
                        onCreateComment={onCreateComment}
                        onUpdateComments={onUpdateComments}
                        onPlotMountedChange={onPlotMountedChange}
                        user_plot_settings={user_plot_settings}
                        account={account}
                    />
                </div>
            )}
        </>
    );
}

export default MultiWellPlot;


