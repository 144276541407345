import React from 'react'
import SingleWellSurveillanceApp from '../applications/single_well_surveillance/SingleWellSurveillanceApp'

function SingleWellSurveillance() {
  return (
    <>
        <SingleWellSurveillanceApp/>
    </>
  )
}

export default SingleWellSurveillance