import React from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    updatePlotXAxisAction,
    updatePlotXAxisScaleAction,
    updatePlotYAxisAction,
    updatePlotYAxisScaleAction,
} from "../../../store/dailyAppActions";

export const EngineeringDataSelection = (props) => {
    const plot_loading = useSelector((state) => state.settings.plot_loading);
    const plot_x_axis = useSelector(
        (state) => state.daily_app_settings.plot_x_axis
    );
    const plot_y_axis = useSelector(
        (state) => state.daily_app_settings.plot_y_axis
    );
    const x_axis_scale = useSelector(
        (state) => state.daily_app_settings.x_axis_scale
    );
    const y_axis_scale = useSelector(
        (state) => state.daily_app_settings.y_axis_scale
    );

    const dispatch = useDispatch();

    const setPlotXAxis = (plot_x_axis) =>
        dispatch(updatePlotXAxisAction(plot_x_axis));
    const setPlotYAxis = (plot_y_axis) =>
        dispatch(updatePlotYAxisAction(plot_y_axis));
    const setXAxisScale = (x_axis_scale) =>
        dispatch(updatePlotXAxisScaleAction(x_axis_scale));
    const setYAxisScale = (y_axis_scale) =>
        dispatch(updatePlotYAxisScaleAction(y_axis_scale));



    return (
        <div className="mb-4">
            <h4>Sub-Plot Selection</h4>
            <hr />
            <AxisSelect
                axis_key={plot_y_axis}
                setAxisKey={setPlotYAxis}
                title="Y-Axis"
                axis_scale={y_axis_scale}
                setAxisScale={setYAxisScale}
                disabled_production_date={true}
            />
            <AxisSelect
                axis_key={plot_x_axis}
                setAxisKey={setPlotXAxis}
                title="X-Axis"
                axis_scale={x_axis_scale}
                setAxisScale={setXAxisScale}
            />
        </div>
    );
};

const AxisSelect = (props) => {
    const { axis_key, setAxisKey, title, axis_scale, setAxisScale, disabled_production_date } = props;
    return (
        <div>
            <h5>{title}</h5>
            <Form.Select
                size="sm"
                onChange={(e) => {
                    setAxisKey(e.target.value)
                    if (e.target.value === "production_date") {
                        setAxisScale("date");
                    } else {
                        if (axis_scale === "date") {
                            setAxisScale("log");
                        }
                    }
                }
                }
                value={axis_key}
                // disabled={plot_loading}
            >
                {/* <option disabled value="none">Please select...</option> */}
                <option 
                    disabled={disabled_production_date}
                    value="production_date">Production Date</option>
                <option value="t">Days since IP</option>
                <option value="sqrt_t">SQRT time (days^1/2)</option>
                <option value="gas_production">Gas Production</option>
                <option value="water_production">Water Production</option>
                <option value="gas_cumulative">Gas Cumulative</option>
                <option value="water_cumulative">Water Cumulative</option>
                <option value="reciprocal_gas_rate">Reciprocal Gas Rate</option>
                <option value="mbt">Material Balance Time</option>
            </Form.Select>
            <Form.Select
                size="sm"
                onChange={(e) => setAxisScale(e.target.value)}
                value={axis_scale}
                // disabled={axis_key === "production_date"}
            >
                {/* <option disabled value="none">Please select...</option> */}
                {axis_key === "production_date" ? (
                    <option disabled value="date">Date</option>
                ) : (
                    <>
                        <option value="log">Log</option>
                        <option value="linear">Linear</option>
                    </>
                ) }
            </Form.Select>
        </div>
    );
};
