// import "./styles/index.css";
// import "./styles/plots.css";
// import "./styles/checkboxmenu.css";
// import "./styles/index_2.css"

import React, { useEffect, useState } from "react";
import Header from "bkv-components/Layout/Header";

// import Plots from "./components/unused_Plots";
import styled from "styled-components";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
    downloadSensorMultiwellData,
    downloadWellTable,
} from "bkv-components/store_lib/actions/dataActions";
// import UserPlotSettings from "./components/UserPlotSettings";

import { createStore, applyMiddleware } from "redux";
import rootReducer from "bkv-components/store_lib/reducers/rootReducer";
import thunk from "redux-thunk";
import MultiWellPlot from "./components/right_pane/MultiWellPlot";
import Layout from "bkv-components/Layout/Layout";
import { getP2ScadaIds } from "bkv-components/General/data_transformation";
import { fetchMultiWellCommentsDBAction } from "bkv-components/store_lib/actions/commentActions_db";
import InnerAppLayout from "bkv-components/Layout/InnerAppLayout";
import { setPlotLoadingAction, updateCurrentUDC } from "bkv-components/store_lib/actions/settingsActions";
import { RefreshContext } from "bkv-components/General/utils";
import { LeftPane } from "./components/left_pane/LeftPane";
import { getUnavailableUDCs } from "./components/left_pane/SensorSelection";
import { fetchGroupsDBAction } from "bkv-components/store_lib/actions/userDataActions";
// import LeftPane from "./components/LeftPane_old";
// const store = createStore(rootReducer);
const store = createStore(rootReducer, applyMiddleware(thunk));

const MultiWellSurveillanceApp = (props) => {
    const dispatch = useDispatch();

    const well_data_hashed = useSelector(
        (state) => state.raw_data.well_data_hashed
    );

    useEffect(() => {
        async function downloadWellTableHelper() {
            dispatch(downloadWellTable());
            dispatch(fetchGroupsDBAction());
        }
        if (Object.keys(well_data_hashed).length === 0) {
            //wells table is only loaded once, and only the first time this component is mounted
            //switching to portal or other apps will not reload the wells table
            downloadWellTableHelper();
            console.log('downloadWellTableHelper() called')
        }
        // downloadWellTableHelper();
    }, [dispatch]);

    // DATA LOADING
    const active_udc = useSelector((state) => state.settings.active_udc);
    const active_p2_merrick_ids = useSelector(
        (state) => state.settings.active_p2_merrick_ids
    );

    const active_p2_scada_ids = getP2ScadaIds(
        active_p2_merrick_ids,
        well_data_hashed
    );

    const n_active_p2_scada_ids = active_p2_scada_ids.length;

    async function refreshPlot() {
        try {
            if (n_active_p2_scada_ids > 0 && active_udc) {
                await dispatch(setPlotLoadingAction(true));

                // for each active udc, check if at least one well has data
                const unavailable_udcs = getUnavailableUDCs(
                    active_p2_merrick_ids,
                    well_data_hashed
                );
                const new_active_udcs = active_udc.filter(
                    (udc) => !unavailable_udcs.includes(udc)
                );
                if (new_active_udcs.length !== active_udc.length) {
                    if (new_active_udcs.length === 0){
                        await dispatch(setPlotLoadingAction(false));
                    }
                    await dispatch(updateCurrentUDC(new_active_udcs));
                    return; //prevent calling download and fetch twice
                }

                await dispatch({
                    type: "CLEAR_COMMENTS",
                });
                dispatch(
                    fetchMultiWellCommentsDBAction(
                        active_p2_scada_ids,
                        new_active_udcs
                    )
                );
                await dispatch(
                    downloadSensorMultiwellData(active_p2_scada_ids, active_udc)
                );
                await dispatch(setPlotLoadingAction(false));
            }
        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {
        refreshPlot();
    }, [n_active_p2_scada_ids, active_udc]);

    const clearSettings = async () => {
        await dispatch({
            type: "CLEAR_SETTINGS",
        });
        await dispatch({
            type: "CLEAR_COMMENTS",
        });
    };

    //InnerAppLayout props
    const user_plot_settings = useSelector(
        (state) => state.settings.user_plot_settings
    );
    const [show_settings, setShowSettings] = useState(true);
    const { well_data, well_data_error } = useSelector(
        (state) => state.raw_data
    );
    const wells_loaded = Object.keys(well_data).length > 0;
    const plot_mounted = useSelector((state) => state.settings.plot_mounted);

    return (
        <RefreshContext.Provider value={refreshPlot}>
            <Layout>
                <Header title="Multi Well Surveillance" />

                <InnerAppLayout
                    leftPane={
                        <LeftPane user_plot_settings={user_plot_settings} />
                    }
                    rightPane={
                        <RightPane
                            show_settings={show_settings}
                            setShowSettings={setShowSettings}
                        />
                    }
                    wells_loaded={wells_loaded}
                    well_data_error={well_data_error}
                    plot_mounted={plot_mounted}
                    show_settings={show_settings}
                    setShowSettings={setShowSettings}
                    clearSettings={clearSettings}
                />
            </Layout>
        </RefreshContext.Provider>
    );
};

const App = (props) => {
    return (
        <Provider store={store}>
            <MultiWellSurveillanceApp />
        </Provider>
    );
};

export default App;



const RightPane = (props) => {
    const { show_settings, setShowSettings } = props;

    return (
        <div className=" maincontainer p-3 h-100">
            <MultiWellPlot
                setShowSettings={setShowSettings}
                show_settings={show_settings}
            />
        </div>
    );
};
