import {
    default_user_plot_settings,
    getAxisNames,
    normalizeTime,
} from "./utils";

const smoothBasic = (xdata, ydata, smoothing) => {
    /* smooths data by averaging over a window of size 2*smoothing+1
     * takes in only ydata, assumes xdata is evenly spaced
     * returns smoothed ydata
     * */
    if (smoothing === 0) {
        return ydata;
    }

    let new_data = [];
    for (let i = 0; i < ydata.length; i++) {
        let sum = 0;
        let sum_count = 0;

        // move left to look for points different from ""
        let j = i;
        let local_count = 0;
        while (j >= 0 && local_count < smoothing) {
            if (ydata[j] !== "") {
                sum += ydata[j];
                local_count += 1;
                sum_count += 1;
            }
            j -= 1;
        }
        // move right to look for points different from ""
        j = i + 1;
        local_count = 0;
        while (j < ydata.length && local_count < smoothing+1) {
            if (ydata[j] !== "") {
                sum += ydata[j];
                local_count += 1;
                sum_count += 1;
            }
            j += 1;
        }
        if (sum_count === 0) {
            new_data.push("");
        } else {
            new_data.push(sum / sum_count);
        }
    }
    return new_data;
};

export const loadPlotDataV2 = (
    sensor_data,
    trace_info,
    user_plot_settings = default_user_plot_settings
) => {
    // get sensor data keys
    let sensor_keys = Object.keys(sensor_data);
    sensor_keys = sensor_keys.filter((key) => key !== "record_time");

    let record_time_data = sensor_data.record_time.map((el) =>
        normalizeTime(el)
    );

    let axis_names = getAxisNames(trace_info);
    let traces = [];
    for (let i in sensor_keys) {
        i = parseInt(i);
        let key = sensor_keys[i];
        let well_id = trace_info[key].well_id;
        let sensor_id = trace_info[key].sensor_id;
        let label = trace_info[key].label;
        let yaxis_name = trace_info[key].yaxis_name;


        if (sensor_data[key] === undefined || sensor_data[key] === null) {
            //skip if sensor_data[key] is undefined or null
            continue;
        }
        //skip if sensor_data[key] is a dict
        if (sensor_data[key].constructor === Object) {
            let trace = sensor_data[key];
            trace.yaxis = "y" + (axis_names.indexOf(yaxis_name) + 1);
            traces.push(trace);
            continue;
        }


        let y_data = sensor_data[key];
        //count of sensor_data[key] with non-empty values or ''
        let length = sensor_data[key].filter((el) => el !== "").length;



        // smooth data        
        if (user_plot_settings.smoothing > 0) {
            y_data = smoothBasic(
                record_time_data,
                y_data,
                user_plot_settings.smoothing
                );
            }

        //limit values less than 0.001 to 0.001
        // y_data = y_data.map((el) => (el < 0.001 ? 0.001 : el)); //will convert '' into 0.001
        y_data = y_data.map((el) => (el < 0.001 && el !== '' ? 0.001 : el));
            
        //calculate y axis
        if (user_plot_settings.all_log_scale) {
            // y_data = sensor_data[key].map((el) => {
            //     return el === 0 ? 0.001 : el
            // });
        } else if (user_plot_settings.log_INSFLG0) {
            // this is not needed if limit values less than 0.001 to 0.001
            // if (sensor_id === "INSFLG0") {
            //     y_data = y_data.map((el) => (el === 0 ? 0.001 : el));
            // }
        }

        let mymode = "lines+markers";
        if (length < 2000) {
            mymode = "lines+markers";
        }

        // NOTE on performance with many points:
        // https://plotly.com/python/webgl-vs-svg/

        let trace = {
            type: "scattergl",
            mode: mymode,
            connectgaps: user_plot_settings.connectgaps ? true : false,
            // mode: "markers",
            // mode: "line+markers",
            marker: { size: 3 },
            name: label,
            id: key,
            x: record_time_data,
            y: y_data,
        };

        //calculate axis
        //loop through elements of trace_info dict

        trace.yaxis = "y" + (axis_names.indexOf(yaxis_name) + 1);

        // // set color
        // if (sensor_id === "INSFLG0") {
        //     trace.line = { color: 'red' };
        // } else {
        //     let color = getColorFromUDC(sensor_id,i);
        //     trace.line = { color: color };
        // }

        trace.line = { color: trace_info[key].color };

        traces.push(trace);
    }
    

    return traces;
};
