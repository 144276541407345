import { checkFacilityFilters } from "bkv-components/FacilitySelection/FacilitySelectBase";
import FacilitySelectWithFilters from "bkv-components/FacilitySelection/FacilitySelectWithFilters";
import { updateFacilities } from "bkv-components/store_lib/actions/settingsActions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const FacilitySelection = ({onSwitchPane}) => {
    // MULTIFACILITYSELECTBASE PROPS
    const well_data_hashed = useSelector((state) => state.raw_data.well_data_hashed);
    const well_data = useSelector((state) => state.raw_data.well_data);
    const active_p2_merrick_ids = useSelector((state) => state.settings.active_p2_merrick_ids);
    const plot_loading = useSelector((state) => state.settings.plot_loading);
    const filters = useSelector((state) => state.settings.filters);

    //useDispatch
    const dispatch = useDispatch();

    // const handleChange = async (p2_merrick_id, p2_scada_id, is_selected) => {
    //     if (p2_merrick_id) {
    //         // set_active_facility(current_facility_id);

    //         let new_active_p2_merrick_ids = updateActiveP2MerrickIds(
    //             p2_merrick_id,
    //             active_p2_merrick_ids,
    //             10
    //         );
    //         dispatch(updateFacilities(new_active_p2_merrick_ids));
    //     }
    // };

    const handleChange = async (new_selection) => {
        dispatch(updateFacilities(new_selection));
    };


    
    return (
        <>
            {/* <MultiFacilitySelectBase
                title={"Well Selection"}
                active_p2_merrick_ids={active_p2_merrick_ids}
                filters={filters}
                well_data={well_data}
                well_data_hashed={well_data_hashed}
                plot_loading={plot_loading}
                handleChange={handleChange}
                max_facilities={10}
            /> */}

        <FacilitySelectWithFilters
            onSwitchPane={onSwitchPane}
            well_data={well_data}
            well_data_hashed={well_data_hashed}
            selection={active_p2_merrick_ids?active_p2_merrick_ids:[]}
            onSelectionChange={handleChange}
            disabled={plot_loading}
            filters={filters}
            max_selected={10}
            selector_type="checkbox"
        />

            

            {/* <FacilitySelectBase
                title={"Well Selection"}
                selection={active_p2_merrick_ids}
                data={filtered_well_data}
                onSelectionChange={handleChange}
                disabled={plot_loading}
                max_facilities={5}
                selector_type="checkbox"
            />

            <br />

            <h5 className="ps-2">Additional Filters</h5>

            <br />

            {filters.map((filter, index) => (
                <div key={index}>
                    <FilterCheckBox
                        name={filter.name}
                        id={filter.id}
                        options={filter.options}
                        active_options={filter.active_options}
                    />
                    <br />
                </div>
            ))} */}
        </>
    );
};

export default FacilitySelection;
