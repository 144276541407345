import { useMsal } from "@azure/msal-react";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import { signOut } from '../../../utils/auth_utils';
// import { signOut } from '../../../store/authActions';
import { signOut } from "../General/auth_utils";
import preval from 'preval.macro'

const Div = styled.div`
    .header-container {
        display: grid;
        /* grid-template-columns: repeat(5, 1fr); */
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }

    .logo-container {
        /* background-color: aqua; */
        display: flex;
        justify-content: center;
        align-items: center;
        grid-area: 1 / 1 / 2 / 2;
    }

    .title-container {
        /* background-color: yellow; */
        justify-content: center;
        display: flex;
        align-items: center;
        grid-area: 1 / 2 / 2 / 6;
    }

    .title {
        font-family: "Ubuntu", sans-serif;
        color: #094e8f;
        font-size: 50px;
        /* font-weight: bold; */
        padding: 10px;
        text-align: center;
    }
`;

const IconSpan = styled.span`
    font-size: 1.3em;
    color: #094e8f;
    text-align: center;
    margin: 8px;
    cursor: pointer;
`;

const InformationModal = (props) => {
    const { show, onClose } = props;
    return (
        <Modal show={show} onHide={onClose} size="lg">
            {/* align center */}
            <Modal.Header style={{ justifyContent: "center" }}>
            <Modal.Title style={{ textAlign: 'right' }}><h2>About</h2></Modal.Title>
          </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* version information */}
                                    <h3 className="mb-4">Version Information</h3>
                                    <p>
                                        <b>Version:</b> {`${window._env_.REACT_APP_VERSION}`}
                                        {/* <b>Version:</b> {`${preval `module.exports = process.env.REACT_APP_VERSION`}`} */}
                                    </p>
                                    <p>
                                        {/* <b>Build Date:</b> {preval`module.exports = new Date().toLocaleString();`}.
                                        <b>Build Date:</b> {preval`module.exports = new Date().toUTCString();`}. */}
                                        <b>Build Date:</b> {preval`module.exports = new Date().toLocaleString("en-US", {timeZone: "America/Denver"});`}. America/Denver
                                    </p>
                        </div>
                        <div className="col-12">
                                    <h3 className="mb-4">Contact Information</h3>
                                    <p>
                                        For support please contact: <a href="mailto:manuelsolalinde@bkvcorp.com">Manuel Solalinde
                                        </a> or 
                                        <a href="mailto:samidhoda@bkvcorp.com"> Samid Hoda </a>
                                    </p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

function Header(props) {
    // const dispatch = useDispatch();

    const { title } = props;
    const { instance } = useMsal();

    const [show_information, setShowInformation] = React.useState(false);

    const handleLogout = async (e) => {
        e.preventDefault();
        await signOut(instance);
    };

    const showInformationModal = () => {
        setShowInformation(true);
    };

    return (
        <Div>
            <InformationModal show={show_information} onClose={() => setShowInformation(false)} />
            <div className="d-flex flex-wrap flex-md-nowrap justify-content-center">
                <div className="col-12 col-md-4 col-lg-3 col-xl-3 col-xxl-4 maincontainer justify-content-center align-items-center d-flex">
                    <Link className="navbar-brand" to="/">
                        <img
                            src="bkv_logo.png"
                            width="250"
                            className="d-inline-block align-top"
                            alt=""
                        />
                    </Link>
                </div>
                <div className="col-12 col-md-6 col-lg-7 col-xl-7 col-xxl-6 maincontainer d-flex align-items-center justify-content-center">
                    {/* <div className="col-12 col-md-4 col-lg-6 col-xl-6 col-xxl-4 maincontainer d-flex align-items-center justify-content-center"> */}
                    <h1 className="title">{title}</h1>
                </div>
                <div className="col-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2  maincontainer justify-content-center d-flex align-items-center p-3">
                    {/* <div className="col-12 col-md-4 col-lg-3 col-xl-3 col-xxl-4  maincontainer justify-content-center d-flex align-items-center p-3"> */}
                    {/* <button
                        type="button"
                        onClick={(e) => handleLogout(e)}
                        className="btn btn-primary"
                    >
                        SignOut
                    </button> */}
                    <IconSpan
                        onClick={(e) => showInformationModal()}
                        title="About"
                    >
                        <i className="fa-solid fa-circle-info fa-xl  me-3"></i>
                    </IconSpan>
                    <IconSpan 
                        onClick={(e) => handleLogout(e)}
                        title="Log Out">
                        <i className="fa-solid fa-right-from-bracket fa-xl"></i>
                    </IconSpan>
                </div>
            </div>
        </Div>
    );
}

export default Header;
