import React from 'react'
import { normalizeTime, sortDateString } from './utils'
import { ReactTabulator } from 'react-tabulator'

import { TabulatorDiv } from '../Comments/CommentsTable'
import { Form } from 'react-bootstrap'

const generateDataColumns = (sensor_data_dict) => {
  const sensor_ids = Object.keys(sensor_data_dict)
  // remove record_time
  if (sensor_ids.includes('record_time')) {
    sensor_ids.splice(sensor_ids.indexOf('record_time'), 1)
  }
  const data_columns = sensor_ids.map((sensor_id) => {
    return {
      title: sensor_id,
      field: sensor_id,
      // maxWidth: 150,
      headerSort: false,
      hozAlign: 'center',
      headerHozAlign: 'center'
    }
  })
  // include record_time as first element
  if (sensor_data_dict.record_time) {
    data_columns.unshift({
      title: 'record_time',
      field: 'record_time',
      minWidth: 250,
      // maxWidth: 400,
      sorter: sortDateString
      // responsive: 4,
    })
  }

  return data_columns
}

function DataDownloadTable(props) {
  // load sensor data from redux

  const { sensor_data } = props
  const sensor_data_dict = sensor_data

  let data_columns = generateDataColumns(sensor_data_dict)

  // const sensor_data_dict = useSelector(
  //     (state) => state.raw_data.sensor_data.data
  // );
  const [timezone_selector, setTimezoneSelector] = React.useState('local')

  var sensor_data_list = []

  // get first dictionary values
  const first_values = Object.values(sensor_data_dict)[0]

  // for (let i = 0; i < sensor_data_dict.record_time.length; i++) {
  for (let i = 0; i < first_values.length; i++) {
    let row = {}
    Object.keys(sensor_data_dict).forEach((key) => {
      row[key] = sensor_data_dict[key][i]
      if (key === 'record_time') {
        if (timezone_selector === 'utc') {
          row[key] = normalizeTime(row[key])
          row[key] = row[key].toUTCString()
        } else if (timezone_selector === 'local') {
          row[key] = normalizeTime(row[key])
          // row[key] = row[key].toLocaleString();
          // row[key] = row[key].toTimeString();
          // row[key] = row[key].toLocaleTimeString();
          // row[key] = row[key].toISOString();
          row[key] = row[key].toString()
        } else if (timezone_selector === 'excel_local') {
          row[key] = normalizeTime(row[key])
          let date = row[key]
          row[key] =
            25569.0 +
            (date.getTime() - date.getTimezoneOffset() * 60 * 1000) /
              (1000 * 60 * 60 * 24)
        } else if (timezone_selector === 'excel_utc') {
          row[key] = normalizeTime(row[key])
          let date = row[key]
          row[key] = 25569.0 + date.getTime() / (1000 * 60 * 60 * 24)
        } else if (timezone_selector === 'millis') {
          row[key] = normalizeTime(row[key])
          row[key] = row[key].getTime()
        }
      } else if (row[key]) {
        // row[key] = row[key].toFixed(4)
        // // convert to float
        // row[key] = parseFloat(row[key])
        let value = row[key]
        if (typeof value === 'number') {
          value = value.toFixed(4)
          // convert to float
          value = parseFloat(value)
        } else if (typeof value === 'string') {
          try {
            value = parseFloat(value)
            value = value.toFixed(4)
            value = parseFloat(value)
          }
          catch (err) {
            console.log(err)
          }
        } else{
          // do nothing
          console.log('value is not a number or string')
        }
        row[key] = value



      }
    })
    sensor_data_list.push(row)
  }

  let ref = React.useRef()

  const downloadCSV = () => {
    // console.log('ref table: ', ref.current); // this is the Tabulator table instance
    ref.current.download('csv', 'sensor_data.csv', { delimiter: ',' })
  }
  const downloadXLSX = () => {
    ref.current.download('xlsx', 'sensor_data.xlsx', {
      sheetName: 'Sensor Data'
    })
  }

  const timezone_options = [
    { value: 'local', label: 'Local Timezone String' },
    { value: 'utc', label: 'UTC String' },
    { value: 'excel_utc', label: 'Excel format (UTC)' },
    { value: 'excel_local', label: 'Excel format (Local)' },
    { value: 'millis', label: 'UTC Milliseconds' }
  ]

  const onChangeTimezone = (e) => {
    console.log(e.target.value)
    setTimezoneSelector(e.target.value)
  }

  let show_format_selection = false;
  const condition1 = 'record_time' in sensor_data_dict
  if(condition1){
    show_format_selection = typeof(sensor_data_dict.record_time[0]) !== 'string'
  }

  return (
    <TabulatorDiv>
      <div>
        <div className='d-flex justify-content-between align-items-center'>
          {show_format_selection && (
            
          <Form className='p-2'>
            <Form.Group controlId='exampleForm.SelectCustom'>
              <Form.Label>Select Time/Date Format:</Form.Label>
              <Form.Control
                as='select'
                // custom
                className='form-select ms-2'
                style={{
                  width: '230px',
                  display: 'inline-block'
                }}
                value={timezone_selector}
                onChange={onChangeTimezone}
              >
                {timezone_options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
          )}
          {/* <hr/> */}

          <div className='p-2 d-flex justify-content-center align-items-center'>
            <button
              style={{ marginRight: '10px' }}
              type='button'
              onClick={downloadCSV}
              className='btn btn-primary btn-sm m-1'
            >
              Download CSV
            </button>
            <button
              type='button'
              onClick={downloadXLSX}
              className='btn btn-primary btn-sm m-1'
            >
              Download XLSX
            </button>
          </div>
        </div>
      </div>
      <hr />
      <ReactTabulator
        data={sensor_data_list}
        onRef={(r) => (ref = r)}
        columns={data_columns}
        options={{
          // layout:"fitData",
          layout: 'fitColumns',
          // autoResize:false,
          // resizableColumns:false,
          // responsiveLayout: true,
          pagination: 'local',
          paginationSize: 10
        }}
      />
    </TabulatorDiv>
  )
}

export default DataDownloadTable
