import dataReducer from 'bkv-components/store_lib/reducers/dataReducer';
import settingsReducer from 'bkv-components/store_lib/reducers/settingsReducer'
import { combineReducers } from 'redux'
import userDataReducer from './userDataReducer';

const rootReducer = combineReducers({
  settings: settingsReducer,
  raw_data: dataReducer,
  user_data: userDataReducer,
});

export default rootReducer

// the key name will be the data property on the state object