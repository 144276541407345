import { requestErrorToText } from "../../General/DisplayRequestError";

const initState = {
    well_data: {},
    well_data_hashed: {},
    sensor_data: undefined,
    sensor_info: undefined,
    sensor_info_hashed: {},
    sensor_multiwell_data: undefined, //{}
    comments_dict: {},
    comments_dict_external: {},
    max_time: undefined,
    min_time: undefined,
    //error handling
    well_data_error: false, //false or str (error message)
    comment_db_error: false, //false or str (error message)
    comment_external_error: false, //false or str (error message)
    http_request_error: false,
    http_request_error_status: "",
};

const MAX_SENSOR_INFO = 10;

const dataReducer = (state = { ...initState }, action) => {
    switch (action.type) {
        //WELL DATA
        case "LOAD_WELL_DATA":
            return {
                ...state,
                well_data: action.payload,
                well_data_error: false,
            };
        case "LOAD_WELL_DATA_HASHED":
            return {
                ...state,
                well_data_hashed: action.payload,
                well_data_error: false,
            };
        //SENSOR DATA
        case "LOAD_SENSOR_INFO":
            return {
                ...state,
                sensor_info: action.payload,
                http_request_error: false,
            };
        case "LOAD_SENSOR_DATA": {
            let start_time = action.payload.start_time;
            let end_time = action.payload.end_time;
            if (state.max_time === undefined || end_time > state.max_time) {
                state.max_time = end_time;
            }
            if (state.min_time === undefined || start_time < state.min_time) {
                state.min_time = start_time;
            }
            return {
                ...state,
                sensor_data: action.payload,
                http_request_error: false,
            };
        }
        case "LOAD_SENSOR_INFO_HASHED":
            let p2_scada_id = action.payload.p2_scada_id;
            let downloaded_at = new Date();
            let new_sensor_info = state.sensor_info_hashed;
            new_sensor_info[p2_scada_id] = {
                ...action.payload,
                downloaded_at: downloaded_at,
            };
            if (Object.keys(new_sensor_info).length > MAX_SENSOR_INFO) {
                let oldest = Object.keys(new_sensor_info).reduce((a, b) =>
                    new_sensor_info[a].downloaded_at <
                    new_sensor_info[b].downloaded_at
                        ? a
                        : b
                );
                delete new_sensor_info[oldest];
            }
            return {
                ...state,
                sensor_info_hashed: new_sensor_info,
                http_request_error: false,
            };
        case "LOAD_SENSOR_MULTIWELL_DATA": {
            // let start_time = action.payload.start_time;
            // let end_time = action.payload.end_time;
            // if (state.max_time === undefined || end_time > state.max_time) {
            //     state.max_time = end_time;
            // }
            // if (state.min_time === undefined || start_time < state.min_time) {
            //     state.min_time = start_time;
            // }
            if (action.payload.start_time_parameter === undefined) {
                state.min_time = action.payload.start_time;
            }
            if (action.payload.end_time_parameter === undefined) {
                state.max_time = action.payload.end_time;
            }
            return {
                ...state,
                sensor_multiwell_data: action.payload,
                http_request_error: false,
            };
        }
        case "CLEAR_SENSOR_DATA":
            console.log("clearing sensor data");
            return {
                ...state,
                sensor_data: undefined,
                sensor_info: undefined,
                sensor_multiwell_data: undefined,
                http_request_error: false,
                max_time: undefined,
                min_time: undefined,
            };
        case "CLEAR_SENSOR_MULTIWELL_DATA":
            console.log("clearing sensor multiwell data");
            return {
                ...state,
                sensor_multiwell_data: undefined,
                http_request_error: false,
                max_time: undefined,
                min_time: undefined,
            };
        //COMMENTS
        case "SET_COMMENTS": //avoid using
            Object.assign(state.comments_dict, action.payload); //creates new reference
            return { ...state, comments_dict: action.payload };
        case "SET_SINGLE_COMMENT":
            return {
                ...state,
                comments_dict: {
                    ...state.comments_dict,
                    [action.payload.id]: action.payload,
                },
            };
        case "COMMENT_DB_ERROR":
            let error_text = requestErrorToText(action.payload);
            alert("Error while loading comments: " + error_text);
            return {
                ...state,
                comment_db_error: action.payload,
            }
        //EXTERNAL COMMENTS
        case "SET_EXTERNAL_COMMENTS":
            Object.assign(state.comments_dict_external, action.payload); //creates new reference
            return { ...state, comments_dict_external: action.payload };
        case "COMMENT_EXTERNAL_ERROR":
            return {
                ...state,
                comment_external_error: action.payload,
            }
        case "CLEAR_COMMENTS":
            return { ...state, comments_dict: {} };


        //ERROR HANDLING
        case "LOAD_WELL_DATA_ERROR":
            // if (action.payload) {
            //     alert(action.payload);
            // }
            console.error("LOAD_WELL_DATA_ERROR: " + action.payload)
            return { ...state, well_data_error: action.payload };

        case "HTTP_REQUEST_ERROR":
            return {
                ...state,
                http_request_error: action.payload
            };
    

        default:
            return state;
    }
};

export default dataReducer;
