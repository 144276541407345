import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentFacility } from "bkv-components/store_lib/actions/settingsActions";
import {
    clearComments,
    clearSensorData,
} from "bkv-components/store_lib/actions/dataActions";
import {
    getCachedP2ScadaIds,
    waitForCachedP2ScadaId,
} from "bkv-components/General/utils";
import FacilitySelectWithFilters from "bkv-components/FacilitySelection/FacilitySelectWithFilters";
import ArcgisMapSelection from "bkv-components/General/arcgis/ArcgisMapSelection";


export const SelectionComponent = ({type='facility',onSwitchPane}) => {
    /*
    * type: facility, map
    * */


    const [cached_p2_scada_ids, set_cached_p2_scada_ids] = useState([]);

    const filters = useSelector((state) => state.settings.filters);
    const well_data_hashed = useSelector(
        (state) => state.raw_data.well_data_hashed
    );
    const well_data = useSelector((state) => state.raw_data.well_data);
    const active_facility = useSelector(
        (state) => state.settings.active_p2_merrick_id
    );
    const plot_loading = useSelector((state) => state.settings.plot_loading);
    const current_group = useSelector((state) => state.user_data.current_group);

    //useDispatch
    const dispatch = useDispatch();

    const handleChange = async (selection) => {
        let p2_merrick_id = selection[0];

        if (p2_merrick_id) {
            // set_active_facility(current_facility_id);

            let p2_scada_id = well_data_hashed[p2_merrick_id].p2_scada_id;

            await dispatch(updateCurrentFacility(p2_merrick_id));
            if (p2_scada_id) {
                await dispatch(clearComments());
                await dispatch(clearSensorData());
                // await dispatch(downloadSensorInfo(p2_scada_id));

                // let cached_p2_scada_ids_aux = await getCachedP2ScadaIds();
                try {
                    let cached_p2_scada_ids_aux = await waitForCachedP2ScadaId(
                        p2_scada_id
                    );
                    if (cached_p2_scada_ids_aux) {
                        set_cached_p2_scada_ids(cached_p2_scada_ids_aux);
                    }
                } catch (e) {
                    console.log(e);
                }
            } else {
                console.error("no p2_scada_id for this facility");
            }
        }
    };

    // const [well_data_cached, setWellDataCached] = useState([]);

    // //evaluate well_data_cached
    // useEffect(() => {

    //     let well_data_cached_aux = well_data;

    //     if (cached_p2_scada_ids.length > 0) {
    //         well_data_cached_aux = well_data_cached_aux.map((facility) => {
    //             let is_cached = cached_p2_scada_ids.includes(facility.p2_scada_id);
    //             return {
    //                 ...facility,
    //                 is_cached: is_cached,
    //             };
    //         });
    //     }
    //     setWellDataCached(well_data_cached_aux);
    // }, [cached_p2_scada_ids, well_data]);

    useEffect(() => {
        async function getCachedP2ScadaIdsAux() {
            let cached_p2_scada_ids_aux = await getCachedP2ScadaIds();

            set_cached_p2_scada_ids(cached_p2_scada_ids_aux);
            console.log("set cached_p2_scada_ids on mount");
        }

        getCachedP2ScadaIdsAux();

        //   return () => {
        //     second
        //   }
    }, []);


    const [selection, setSelection] = useState([]);

    //selection change 
    useEffect(() => {
        let selection_aux = active_facility ? [active_facility] : []
        setSelection(selection_aux)
    }, [active_facility]);


    let FacilitySelectionComponent = (
        <FacilitySelectWithFilters
            onSwitchPane={onSwitchPane}
            well_data={well_data}
            cached_p2_scada_ids={cached_p2_scada_ids}
            well_data_hashed={well_data_hashed}
            selection={selection}
            onSelectionChange={handleChange}
            disabled={plot_loading}
            filters={filters}
            selector_type="radio"
        />
    );

    // let MapSelectionComponent = (
    //     <MapboxMap
    //         filters={filters}
    //         well_data={well_data_cached}
    //         selection={selection}
    //         onSelectionChange={handleChange}
    //         selector_type="radio"
    //         disabled={plot_loading}
    //     />
    // );

    let MapSelectionComponent = (
        <ArcgisMapSelection
            filters={filters}
            current_group={current_group}
            well_data={well_data}
            well_data_hashed={well_data_hashed}
            selection={selection}
            onSelectionChange={handleChange}
            selector_type="radio"
            disabled={plot_loading}
        />
    );

    return (
        <>
        {type === "map" ? MapSelectionComponent : FacilitySelectionComponent}
        </>
    )
}
