import { default_user_plot_settings, getTraceAxis, normalizeTime } from '../Components/utils'


const limitText = (text, limit=20) => {
    if (text.length > limit) {
        return text.slice(0, limit) + '...';
    }
    return text;
};


export const createAnnotationsFromComments_v2 = (
  comments,
  plot_data_range,
  user_plot_settings = default_user_plot_settings,
  trace_info,
) => {
  let ann_dict = {}

  // if (user_plot_settings.merged_pressure_plots) {
  //     Object.keys(comments_dict).forEach((key) => {
  //         if (comments_dict[key].label === "INSFLG0") {
  //             comments_dict[key].yaxis = "y1";
  //         } else {
  //             comments_dict[key].yaxis = "y2";
  //         }
  //     });
  // }

  // sort comments by time
  // console.log(comments)

  //convert comments dictionary into a sorted list (by time=created_at)
  let comments_list = Object.keys(comments).map((key) => {return {...comments[key], key: key}})
  comments_list.sort((a, b) => a.created_at - b.created_at);
  

  
  // for (let key of Object.keys(comments)) {
  //   let comment = comments[key]

for(let comment of comments_list){

    const key = comment.key
    const sensor_id = comment.label
    const p2_scada_id = comment.p2_scada_id

    if (comment.deleted || comment.edited_and_replaced) {
      continue
    }
    let hidden = false
    if (plot_data_range) {
      let x_date = normalizeTime(comment.x)
      if (x_date < plot_data_range[0] || x_date > plot_data_range[1]) {
        hidden = true
      }
    }
    // add <br> to hovertext when lines are above 30 characters
    let comment_full_text = comment.value
    let hovertext_lines = []
    // separate hovertext into lines of 30 characters
    const max_characters = 60
    while (comment_full_text.length > max_characters) {
      hovertext_lines.push(comment_full_text.substring(0, max_characters))
      comment_full_text = comment_full_text.substring(max_characters)
    }
    hovertext_lines.push(comment_full_text)
    // add all hovertext_lines to a string=hovertext separated by <br>
    comment_full_text = ''
    hovertext_lines.forEach((line) => {
      comment_full_text += `<br>${line}`
    })

    let x_hash_definition
    let annotation_axis
    // if (mode === "fixed_udc") {
    //   x_hash_definition = comment.x
    // } else {
    //   annotation_axis = 'y'+ getAxisFromUDC(comment.label)
    //   x_hash_definition = comment.x + comment.label
    // }
    // const axis_names = getAxisNames(trace_info);
    // const trace_id = 
    // annotation_axis = 'y'+(axis_names.indexOf(yaxis_name) + 1);
    annotation_axis = getTraceAxis(trace_info, p2_scada_id, sensor_id);
    if (annotation_axis === undefined) {
      continue
    }
    x_hash_definition = comment.x + comment.label // TODO check again later



    let current_ann = ann_dict[x_hash_definition]
    // if (user_plot_settings.merged_pressure_plots) {
    //   if (sensor_id === 'INSFLG0') {
    //     annotation_axis = 'y1'
    //   } else {
    //     annotation_axis = 'y2'
    //   }
    // }
    
    let annotation_y = comment.y
    if (user_plot_settings.log_INSFLG0) {
      // Annotations on log scale are not supported by Plotly.js
      // https://github.com/plotly/plotly.js/issues/1258
      // annotation_y = Math.log(comment.y) / Math.log(10);
        if (sensor_id === 'INSFLG0') {
          annotation_y = Math.log10(comment.y)
        }
    }

    if (user_plot_settings.all_log_scale) {
       annotation_y = Math.log10(comment.y)
    }

    if (!current_ann) {
      current_ann = {
        hidden: hidden,
        x: comment.x,
        y: annotation_y,
        label: sensor_id, // label is always = sensor_id
        xref: 'x',
        yref: annotation_axis,
        hovertext:  comment.user_first_name + ': '+ comment_full_text,
        text: limitText(comment_full_text),
        // text: comment.user_first_name,
        // hovertext: comment_full_text,
        showarrow: true,
        arrowhead: 6,
        arrowsize: 1,
        ax: 20,
        ay: -40,
        bordercolor: 'gray',
        borderwidth: 1,
        borderpad: 4,
        bgcolor: 'rgba(255, 255, 255, 0.7)',
        xanchor: 'center',
        yanchor: 'middle',
        captureevents: true,
        comment_ids: [key],
        annotation_point: {
          x: comment.x,
          y: comment.y,
          p2_scada_id: comment.p2_scada_id,
          sensor_id: sensor_id
          // name: comment.label, // current label (used only when clicking an annotation)
          // yaxis: (mode === "fixed_udc") ? 'y2' : annotation_axis,
        }
      }
    } else {
      current_ann.comment_ids.push(key)
      // current_ann.text += '<br>' + comment.user_first_name
      // current_ann.hovertext += '<br>' + Array(30).join('-') + comment_full_text
      current_ann.hovertext += '<br>' + Array(20).join('-') + '<br>' + comment.user_first_name + ': '+ comment_full_text
      current_ann.text += '<br>' + Array(20).join('-') + limitText(comment_full_text)
    }
    ann_dict[x_hash_definition] = current_ann
  }
  // return list of annotations
  // let ann_list = Object.values(ann_dict)
  return ann_dict
}




//trace info sample
// color:'blue'
// label:'water_production'
// sensor_id:'water_production'
// unit:'bbl/d'
// well_id:'BP_A4U_AC_ENGLND_Isle_SA_1H'
// yaxis_name:'FLOW RATE'

//axis info sample
// color:undefined
// domain_height:0.3333333333333333
// unit:'bbl/d'


export const createDataFromCommentsDictExternal = (
  comments_dict_external,
  record_time
) => {

  let external_comments_data = {
    sensor_data: {},
    trace_info: {},
    axis_info: {},
}

  external_comments_data.axis_info['COMMENTS'] = {
    color: undefined,
    domain_height: 0.1,
    unit: '',
  }

  //convert to list
  let comments_list = Object.values(comments_dict_external)

  //get a list of unique event_source
  let event_source_list = [...new Set(comments_list.map(item => item.event_source))]

  for (let j = 0; j < event_source_list.length; j++) {

    // 1. CALCULATE TRACE x values, y values, and text values
    // TODO optimize!!

    // create list of '' object of length = record_time.length
    let comment_values = Array(record_time.length).fill('')
    let comment_texts = Array(record_time.length).fill('')
    let comment_marker_size = Array(record_time.length).fill(0)
    let x_values = [...record_time]
    let event_source = event_source_list[j]
    for (let i = 0; i < comments_list.length; i++) {
      let comment = comments_list[i]

      let comment_date = comment.event_date
      let comment_index = record_time.indexOf(comment_date)

      // if comment_date is in record_time
      if (comment_index > -1 && comment.event_source === event_source) {
        comment_values[comment_index] = 0
        comment_texts[comment_index] = comment.well_name + '\n <br>' + comment.event_text
        comment_marker_size[comment_index] = 20
      }

    }

    //remove '' from comment_values, comment_texts and record_time at the same time
    for (let i = comment_values.length - 1; i >= 0; i--) {
      if (comment_values[i] === '') {
        comment_values.splice(i, 1)
        comment_texts.splice(i, 1)
        x_values.splice(i, 1)
        comment_marker_size.splice(i, 1)
      }
    }

    // 2. ADD TRACE TO external comments data

    // add comment_trace to external_comments_data
    let trace_name = "COMMENT_" + event_source_list[j]

    let comment_trace = {
      type: "scattergl",
      mode: "markers",
      text: comment_texts,
      // width: 0.001,
      id: trace_name,
      name: event_source_list[j],
      x: x_values,
      y: comment_values,
      hoverinfo: "x+text",
      hovertemplate:
      "<b>Date: %{x}</b><br><br>" +
      "%{text}<br>" +
      "<extra></extra>",
      // yaxis: "y2", //to be completed in loadPlotData
      marker: {
          size: comment_marker_size,
          // symbol: "line-ns-open",
          symbol: "triangle-down",
          line:{
              width: 2,
          }
      },
      borderwidth: 1,
  };


    external_comments_data.sensor_data[trace_name] = comment_trace
    external_comments_data.trace_info[trace_name] = {
      // color: event_source_list[j] === 'P2' ? 'blue' : 'red',
      label: trace_name,
      sensor_id: event_source_list[j],
      unit: '',
      well_id: 'comment',
    }

  }

  // 3. FIX TRACES THAT HAVE REPEATED VALUES ON THE X AXIS

  //check for traces that have repeated values on the x axis and change their marker size
  let trace_names = Object.keys(external_comments_data.sensor_data)
  let joined_x_values = []
  for (let i = 0; i < trace_names.length; i++) {
    let trace = external_comments_data.sensor_data[trace_names[i]]
    joined_x_values = joined_x_values.concat(trace.x)
  }

  let repeated_x_values = joined_x_values.reduce((acc, curr) => {
    if (curr in acc) {
      acc[curr]++;
    } else {
      acc[curr] = 1;
    }
    return acc;
  }, {});

  // remove values that are not repeated
  for (const [key, value] of Object.entries(repeated_x_values)) {
    if (value === 1) {
      delete repeated_x_values[key]
    }
  }

  //fix marker size and y values
  for (let i = 0; i < trace_names.length; i++) {
    let n = trace_names.length
    let trace = external_comments_data.sensor_data[trace_names[i]]
    let trace_name = trace_names[i]

    //loop over counts and change marker size and y values
    for (const [key, value] of Object.entries(repeated_x_values)) {
      let index = trace.x.indexOf(key)
      if (index > -1) {
        let nn = n-1;
        let scale = 1; //scale=1 means spread equal to 1
        trace.y[index] = ((i/nn)-nn/2)*scale
        // trace.marker.size[index] = trace.marker.size[index] / value
      }
    }
  }











  return external_comments_data
}