import React from "react";
import ReactDOM from "react-dom";
// import "./styles/index_2.css";
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import { Provider } from "react-redux";
//active directory authentication
import { msalConfig } from "./config/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";


// import { createStore, applyMiddleware } from "redux";
// import rootReducer from "./applications/single_well_surveillance/store/reducers/rootReducer";
// import thunk from "redux-thunk";
// // const store = createStore(rootReducer);
// const store = createStore(rootReducer, applyMiddleware(thunk));

// //used for react-msal-redux
// import { configureStore } from "./store/store_config";
// const initialState = {};
// const store = configureStore({
//   reducer: rootReducer,
//   preloadedState,
// })

// active directory authentication
export const msalInstance = new PublicClientApplication(msalConfig);




// // Catch errors since some browsers throw when using the new `type` option.
// // https://bugs.webkit.org/show_bug.cgi?id=209216
// try {
//   // Create the performance observer.
//   const po = new PerformanceObserver((list) => {
//     for (const entry of list.getEntries()) {
//       console.log(entry);
//     }
//   });
//   // Start listening for `navigation` entries to be dispatched.
//   po.observe({type: 'resource', buffered: true});
// } catch (e) {
//   // Do nothing if the browser doesn't support this API.
// }

ReactDOM.render(
    // <React.StrictMode>
    // <Provider store={store}>
        <App msalInstance={msalInstance} />,
    // </Provider>,
    //</React.StrictMode>
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorker.register();
// serviceWorker.register({ scope: "/" })
serviceWorkerRegistration.register({
    onUpdate: registration => {
      // alert('New version available!  Ready to update?'); //this was there twice
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
      window.location.reload();
    }
  });
