import settingsReducer from './settingsReducer'
// import dataReducer from './dataReducer'
import { combineReducers } from 'redux'
import dataReducer from 'bkv-components/store_lib/reducers/dataReducer';
import userDataReducer from 'bkv-components/store_lib/reducers/userDataReducer';
// import { authReducer } from '@junicus_/react-msal-redux';

const rootReducer = combineReducers({
  settings: settingsReducer,
  raw_data: dataReducer,
  user_data: userDataReducer,
});

export default rootReducer

// the key name will be the data property on the state object