import React from 'react'
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updatePlotSubSelectionAction } from '../../../store/dailyAppActions';


const PlotSubSelection = (props) => {
    // const {udc_selection=[], setUdcSelection} = props;
    // const [plot_sub_selection, setPlotSubSelection] = React.useState("daily_production");

    const plot_loading = useSelector(state => state.settings.plot_loading);
    const plot_sub_selection = useSelector(state => state.daily_app_settings.plot_sub_selection);

    const dispatch =  useDispatch();

    const setPlotSubSelection = (plot_sub_selection) => dispatch(updatePlotSubSelectionAction(plot_sub_selection));



    console.log(plot_sub_selection);

    // gas_vs_date: {
    //     x: "record_time",
    //     x_title: "Production Date",
    //     y_title: "GAS (mcf)",
    //     base_unit: "mfc",
    //     x_type: "date",
    // },
    // gas_vs_cumulative_gas: {
    //     x: "cumulative",
    //     x_title: "GAS Cumulative (mcf)",
    //     y_title: "GAS (mcf)",
    //     base_unit: "mfc",
    //     x_type: "log",
    // },
    // inverse_gas_vs_sqrt_date: {
    //     x: "sqrt_t",
    //     x_title: "Square Root Time (days^1/2)",
    //     y_title: "Inverse GAS (1/mcf)",
    //     base_unit: "1/mfc",
    // },
    // water_vs_cumulative_water: {
    //     x: "cumulative",
    //     x_title: "WATER Cumulative (bbl)",
    //     y_title: "WATER (bbl)",
    //     base_unit: "bbl",
    //     x_type: "log",
    // },

    return (
        <div className="mb-4">
            <h4>Sub-Plot Selection</h4>
            <hr/>
            <Form.Select size="sm"
                onChange={(e) => setPlotSubSelection(e.target.value)}
                value={plot_sub_selection}
                // disabled={plot_loading}

            >
                {/* <option disabled value="none">Please select...</option> */}
                <option value="gas_vs_date">Gas vs Production Date</option>
                <option value="gas_vs_cumulative_gas">Gas vs Cumulative Gas</option>
                <option value="inverse_gas_vs_sqrt_date">Inverse Gas vs SQRT Time</option>
                <option value="water_vs_cumulative_water">Water vs Cumulative Water</option>
                <option value="gas_vs_mbt">Gas Rate vs MBT</option>
                <option value="all">Show All</option>
            </Form.Select>
      
        </div>
    );
};

export default PlotSubSelection;