// import fake_comments from "../../fake_data/fake_comments";

const initState = {
  current_annotation_x: undefined, // xposition + label
  show_menu: false,
  xPos: 0,
  yPos: 0,
  current_point: undefined,
  show_modal: false,
  db_latest_fetch_time: undefined
}

const commentReducer = (state = {...initState}, action) => {
  switch (action.type) {
    case 'SET_CURRENT_ANNOTATION_X':
      return { ...state, current_annotation_x: action.payload }
    case 'SET_CURRENT_POINT':
      return { ...state, current_point: action.payload }
    case 'SET_SHOW_MENU':
      return { ...state, show_menu: action.payload }
    case 'SET_X_POS':
      return { ...state, xPos: action.payload }
    case 'SET_Y_POS':
      return { ...state, yPos: action.payload }
    case 'SET_SHOW_MODAL':
      return { ...state, show_modal: action.payload }
    default:
      return state
  }
}

export default commentReducer
