// import { useAccount, useMsal } from '@azure/msal-react'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCurrentAnnotationXAction,
  setShowMenuAction,
  setShowModalAction
} from '../store/actions/commentActions'
import CommentModal from './CommentModal'

function CommentMenus(props) {
  const {
    ann_dict,
    onCreateComment,
    onUpdateComments,
    mode,
    comments_dict,
    // sensor_id,
    // p2_scada_id,
    account,
    application,
  } = props

  const dispatch = useDispatch()

  // get user
  //   const { accounts } = useMsal()
  //   const account = useAccount(accounts[0] || {})

  // state definitions for comment menu
  const show_menu = useSelector((state) => state.comments.show_menu)
  const setShowMenu = (value) => dispatch(setShowMenuAction(value))
  const current_point = useSelector((state) => state.comments.current_point)
  const xPos = useSelector((state) => state.comments.xPos)
  const yPos = useSelector((state) => state.comments.yPos)

  // modal comments statte
  const [callToUpdate, setCallToUpdate] = useState(false)
  // const [current_annotation_x, setCurrentAnnotationX] = useState(undefined)
  const current_annotation_x = useSelector(
    (state) => state.comments.current_annotation_x
  )
  const setCurrentAnnotationX = (value) =>
    dispatch(setCurrentAnnotationXAction(value))

  // state definition for comment annotations
  // const comments_dict = useSelector((state) => state.raw_data.comments_dict);

  // create comment_list for current_annotation_x (includes x+sensor_id)
  let comment_list = []
  if (current_annotation_x) {
    if (current_annotation_x in ann_dict) {
      let comment_ids = ann_dict[current_annotation_x].comment_ids
      for (let i = 0; i < comment_ids.length; i++) {
        comment_list.push(comments_dict[comment_ids[i]])
      }
    }
  }

  // console.log(ann_dict)

  // modal stuff
  const show_modal = useSelector((state) => state.comments.show_modal)
  const setShowModal = (value) => dispatch(setShowModalAction(value))

  if (!account) {
    return null
  }
  let user_first_name = account.name.split(' ')[0]

  const createComment = (comment_text) => {
    let new_p2_scada_id = current_point.p2_scada_id
    let new_sensor_id = current_point.sensor_id
    // if (!new_p2_scada_id) {
    //   console.log(current_point)
    //   new_p2_scada_id = current_point.p2_scada_id
    // }
    // if (!new_sensor_id) {
    //   new_sensor_id = current_point.sensor_id
    // }
    // add comment to comments
    let accountInfo = { ...account }
    delete accountInfo.idTokenClaims
    let x_pos = new Date(current_point.x)
    x_pos = x_pos.getTime()
    let now = new Date()
    let long_id =
      new_sensor_id +
      '-' +
      accountInfo.localAccountId.slice(0, 8) +
      '-' +
      new_p2_scada_id +
      '-' +
      x_pos +
      now.getTime()
    const comment = {
      x: x_pos,
      y: current_point.y,
      p2_scada_id: new_p2_scada_id,
      label: new_sensor_id,
      // yaxis: 'y'+getAxisFromLabel(sensor_id), //current_point.yaxis || 'y1',
      value: comment_text,
      user_first_name: user_first_name,
      account: accountInfo,
      id: long_id,
      created_at: now.getTime(),
      updated_at: now.getTime(),
      edited_and_replaced: false,
      deleted: false,
      application: application,
    }
    // comments_dict[long_id] = comment;
    // setComments(comments_dict);
    if (onCreateComment) {
      onCreateComment(comment)
    } else {
      console.warning('onCreateComment not defined')
      // dispatch(createCommentAction(comment));
    }
    setCallToUpdate(!callToUpdate)
  }

  const updateComments = (comment_list) => {
    // for (let i = 0; i < comment_list.length; i++) {
    //     let comment = comment_list[i];
    //     comments_dict[comment.id] = comment;
    // }
    if (comment_list.length > 0) {
      if (onUpdateComments) {
        onUpdateComments(comment_list)
      } else {
        console.warning('onUpdateComments not defined')
        // dispatch(updateCommentsAction(comment_list));
      }
      // setComments(comments_dict);
      setCallToUpdate(!callToUpdate)
      if (mode === "fixed_udc") {
        setCurrentAnnotationX(comment_list[0].x)
      } else {
        setCurrentAnnotationX(comment_list[0].x + comment_list[0].label)
      }
    }
  }

  const displayCommentModal = () => {
    setShowModal(true)
    setShowMenu(false)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setCurrentAnnotationX(undefined)
  }

  // convert to utc string
  const x_time = current_point ? new Date(current_point.x).toUTCString() : ''

  return (
    <div>
      <CommentModal
        current_point={current_point}
        show={show_modal}
        comments={comment_list}
        createComment={createComment}
        handleClose={handleCloseModal}
        updateComments={updateComments}
        account={account}
      />
      <div>
        {show_menu && current_point && (
          // this is the context menu for adding new comments
          <ul
            className={
              'dropdown-menu ' +
              (show_menu && current_point ? 'd-block' : 'd-none')
            }
            style={{
              top: yPos,
              left: xPos,
              opacity: 0.8
            }}
            aria-labelledby='dropdownMenu2'
          >
            <li>
              <span className='dropdown-item-text'>{x_time}</span>
            </li>
            <li>
              <button
                className='dropdown-item small'
                onClick={displayCommentModal}
              >
                Add comment
              </button>
            </li>
          </ul>
        )}
      </div>
    </div>
  )
}

export default CommentMenus

