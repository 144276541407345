import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

const Div = styled.div`
    tr td {
        white-space: normal !important; // To consider whitespace.
        word-wrap: normal;
        min-width: 100px;
        max-width: 100px;
    }
    tbody td:nth-child(3) {
        word-wrap: break-word;
    }
    tbody td {
        //all td
        word-wrap: break-word;
    }
`;
export const WellInfo = ({ well_information }) => {
    return (
        <Div>
            <table className="table table-hover small p-1 table-responsive">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Attribute</th>
                        <th scope="col">Value</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(well_information).map((key, index) => (
                        <tr key={index} className="table-default">
                            <th scope="row">{index}</th>
                            <td>{key}</td>
                            <td>{well_information[key]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Div>
    );
};

function WellInformation(props) {
    const { p2_merrick_id, well_data_hashed } = props;

    let well_information = null;
    try {
        well_information = well_data_hashed[p2_merrick_id];
    } catch (e) {}
    // console.log(well_information)

    return (
        <>
            <h4>Well information</h4>
            <hr />

            {well_information ? (
                <WellInfo well_information={well_information} />
            ) : (
                <div>Please select a facility</div>
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        p2_merrick_id: state.settings.active_p2_merrick_id,
        well_data_hashed: state.raw_data.well_data_hashed,
    };
};

export default connect(mapStateToProps)(WellInformation);
