import React from 'react'
import DailyMultiWellSurveillanceApp from '../applications/daily_production_plots/DailyMultiWellSurveillanceApp'

function DailyMultiWellSurveillance() {
  return (
    <>
        <DailyMultiWellSurveillanceApp />
    </>
  )
}

export default DailyMultiWellSurveillance