import dataReducer from "bkv-components/store_lib/reducers/dataReducer";
import settingsReducer, {
    settingsInitState,
} from "bkv-components/store_lib/reducers/settingsReducer";
import { combineReducers } from "redux";
import { udc_list } from "../components/daily_production_plot/UdcSelection";
import userDataReducer from "bkv-components/store_lib/reducers/userDataReducer";

let settingsInitStateModif = {
    ...settingsInitState,
    filters: [
        {
            name: "P2 Foreman",
            id: "p2_foreman",
            options: [],
            active_options: [],
        },
        { name: "P2 Pumper", id: "p2_pumper", options: [], active_options: [] },
        {
            name: "P2 Engineer",
            id: "p2_engineer",
            options: [],
            active_options: [],
        },
        {
            name: "P2 Supervisor",
            id: "p2_supervisor",
            options: [],
            active_options: [],
        },
        { name: "P2 Route", id: "p2_route", options: [], active_options: [] },
        { name: "Aries api", id: "aries_api", options: [], active_options: [] },
        {
            name: "Aries RSV Cat",
            id: "rsv_cat",
            options: [],
            active_options: [],
        },
        {
            name: "Daily Data Available",
            id: "daily_data_available",
            options: [],
            active_options: [true],
        },
    ],
};

settingsInitStateModif.user_plot_settings.plot_separation = 15;
settingsInitStateModif.user_plot_settings.merged_pressure_plots = false;

const newSettingsReducer = (state = { ...settingsInitStateModif }, action) =>
    settingsReducer(state, action, settingsInitStateModif);

const dailyAppInitState = {
    udc_selection: [],
    facility_sub_selection: [],
    // plot_selection: "daily_production",
    // plot_selection: "engineering_plots",
    plot_selection: "none",
    plot_sub_selection: "gas_vs_date", // used by v1 engineering plots
    plot_x_axis: "production_date", //used by v2 engineering plots
    plot_y_axis: "gas_production", //used by v2 engineering plots
    x_axis_scale: "date",
    y_axis_scale: "log",
    // udc_selection: ['gas_production'],
};

const dailyAppSettingsReducer = (state = { ...dailyAppInitState }, action) => {
    switch (action.type) {
        case "UPDATE_UDC_SELECTION":
            return { ...state, udc_selection: action.payload.udc_selection };
        case "UPDATE_WELL_SUB_SELECTION":
            return {
                ...state,
                facility_sub_selection: action.payload.facility_sub_selection,
            };
        case "UPDATE_PLOT_SELECTION":
            return { ...state, plot_selection: action.payload.plot_selection };
        case "UPDATE_PLOT_SUB_SELECTION":
            return { ...state, plot_sub_selection: action.payload.plot_sub_selection };
        case "UPDATE_PLOT_X_AXIS":
            return { ...state, plot_x_axis: action.payload.plot_x_axis };
        case "UPDATE_PLOT_Y_AXIS":
            return { ...state, plot_y_axis: action.payload.plot_y_axis };
        case "UPDATE_X_AXIS_SCALE":
            return { ...state, x_axis_scale: action.payload.x_axis_scale };
        case "UPDATE_Y_AXIS_SCALE":
            return { ...state, y_axis_scale: action.payload.y_axis_scale };
        case "CLEAR_DAILY_APP_SETTINGS":
            return { ...state, ...dailyAppInitState };
        default:
            return state;
    }
};

const dailyAppDataReducer = (
    state = {
        engineering_data: null,
        error: false,
    },
    action
) => {
    switch (action.type) {
        case "DOWNLOAD_ENGINEERING_DATA":
            return {
                ...state,
                engineering_data: action.payload.engineering_data,
            };
        case "DOWNLOAD_ENGINEERING_ERROR":
            return { ...state, error: action.payload.error };
        case "CLEAR_ENGINEERING_DATA":
            return { ...state, 
                    engineering_plots: null, 
                    engineering_data: null,
                    error: false };
        default:
            return state;
    }
};

const rootReducer = combineReducers({
    daily_app_settings: dailyAppSettingsReducer,
    daily_app_data: dailyAppDataReducer,
    settings: newSettingsReducer,
    raw_data: dataReducer,
    user_data: userDataReducer
});

export default rootReducer;

// the key name will be the data property on the state object
