// import { useAccount, useMsal } from '@azure/msal-react';
import React, { useState } from 'react'
import styled from 'styled-components'

const Div = styled.div`
  .content-item {
    padding: 30px 0;
    background-color: #ffffff;
  }

  .content-item.grey {
    background-color: #f0f0f0;
    padding: 50px 0;
    height: 100%;
  }

  .content-item h2 {
    font-weight: 700;
    font-size: 35px;
    line-height: 45px;
    text-transform: uppercase;
    margin: 20px 0;
  }

  .content-item h3 {
    font-weight: 400;
    font-size: 20px;
    color: #555555;
    margin: 10px 0 15px;
    padding: 0;
  }

  .content-headline {
    height: 1px;
    text-align: center;
    margin: 20px 0 70px;
  }

  .content-headline h2 {
    background-color: #ffffff;
    display: inline-block;
    margin: -20px auto 0;
    padding: 0 20px;
  }

  .grey .content-headline h2 {
    background-color: #f0f0f0;
  }

  .content-headline h3 {
    font-size: 14px;
    color: #aaaaaa;
    display: block;
  }

  form {
    margin-bottom: 30px;
  }

  .btn {
    margin-top: 7px;
  }

  form fieldset {
    clear: both;
  }

  form textarea {
    height: 100px;
  }

  .media {
    padding: 20px 0;
    margin: 0;
  }

  .media > .pull-left {
    margin-right: 20px;
  }

  .media img {
    max-width: 100px;
  }

  .media h4 {
    margin: 0 0 10px;
  }

  .media h4 span {
    font-size: 14px;
    float: right;
    color: #999999;
  }

  .media p {
    margin-bottom: 15px;
    text-align: justify;
  }

  .media-detail {
    margin: 0;
  }

  .media-detail li {
    color: #aaaaaa;
    font-size: 12px;
    padding-right: 10px;
    font-weight: 600;
  }

  .media-detail a:hover {
    text-decoration: underline;
  }

  .media-detail li:last-child {
    padding-right: 0;
  }

  .media-detail li i {
    color: #666666;
    font-size: 15px;
    margin-right: 10px;
  }

  .list-inline {
    padding-left: 0;
    margin-left: -5px;
    list-style: none;
  }

  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }

  .pull-right {
    float: right !important;
  }

  .pull-left {
    float: left !important;
  }

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .list-inline > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
  }
`

function BlogComments(props) {
  const { comments, updateComments, createComment, account } = props

  // filter comments that are deleted or edited_and_replaced
  let filteredComments = comments.filter(
    (comment) =>
      comment.deleted !== true && comment.edited_and_replaced !== true
  )
  // sort filtered comments by created_at
  filteredComments.sort((a, b) => {
    return new Date(a.created_at) - new Date(b.created_at)
  })

  // get user
  // const { accounts } = useMsal();
  // const account = useAccount(accounts[0] || {});
  const current_username = account.username

  const [brand_new_comment, setBrandNewComment] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()
    createComment(brand_new_comment)
    setBrandNewComment('')
  }

  const [editing, setEditing] = useState(false)
  const [edited_comment_text, setNewCommentText] = useState('')
  const [edited_comment_index, setNewCommentIndex] = useState(null)

  const handleEdit = (e, comment, index) => {
    e.preventDefault()
    setNewCommentText(comment.value)
    setNewCommentIndex(index)
    setEditing(true)
  }

  const handleDelete = (e, comment) => {
    e.preventDefault()
    let edited_current_comment = {
      ...comment,
      deleted: true
    }
    updateComments([edited_current_comment])
    setEditing(false)
  }

  const handleSave = (comment) => {
    let now = new Date()
    let edited_current_comment = {
      ...comment,
      edited_and_replaced: true
    }
    let new_id =
      comment.label +
      '-' +
      comment.account.localAccountId +
      '-' +
      comment.x +
      now.getTime()
    let new_comment = {
      ...comment,
      id: new_id,
      value: edited_comment_text,
      edited_and_replaced: false,
      updated_at: now.getTime()
    }
    updateComments([edited_current_comment, new_comment])
    setEditing(false)
  }

  const handleCancel = (comment) => {
    setEditing(false)
  }

  return (
    <Div>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12'>
            {/* <h3>{n_comments} Comments</h3> */}

            {/* <!-- COMMENT 1 - START --> */}
            {filteredComments.map((comment, index) => {
              let date = new Date(comment.updated_at)
              let date_str = date.toLocaleDateString()
              let text = comment.value
              let author = comment.account.name
              let username = comment.account.username
              return (
                <div className='media border-bottom' key={index}>
                  <div className='media-body'>
                    <h4 className='media-heading'>{author}</h4>
                    {editing && edited_comment_index === index ? (
                      <textarea
                        className='form-control'
                        value={edited_comment_text}
                        onChange={(e) => setNewCommentText(e.target.value)}
                        id='message'
                        placeholder='Your message'
                        required=''
                      />
                    ) : (
                      <p>{text}</p>
                    )}
                    <div className='d-flex justify-content-between'>
                      <ul className='list-unstyled list-inline media-detail'>
                        <li>
                          <i className='fa fa-calendar' />
                          {date_str}
                        </li>
                        <li> - {comment.p2_scada_id}</li>
                        <li> - {comment.label}</li>
                        {/* <li><i className="fa fa-thumbs-up"></i>13</li> */}
                      </ul>
                      {current_username === username &&
                        (editing && edited_comment_index === index ? (
                          <ul className='list-unstyled list-inline media-detail'>
                            <li className=''>
                              <button
                                onClick={(e) => handleSave(comment)}
                                className='btn btn-sm btn-outline-success'
                              >
                                Save
                              </button>
                            </li>
                            <li className=''>
                              <button
                                onClick={(e) => handleCancel(comment)}
                                className='btn btn-sm btn-outline-secondary'
                              >
                                Cancel
                              </button>
                            </li>
                          </ul>
                        ) : (
                          // save and cancel buttons
                          <ul className='list-unstyled list-inline media-detail'>
                            <li className=''>
                              <a
                                href='/#'
                                onClick={(e) => handleEdit(e, comment, index)}
                              >
                                Edit
                              </a>
                            </li>
                            <li className=''>
                              <a
                                href='/#'
                                onClick={(e) => handleDelete(e, comment, index)}
                              >
                                Delete
                              </a>
                            </li>
                          </ul>
                        ))}
                    </div>
                  </div>
                </div>
              )
            })}

            <form className='mt-4' onSubmit={onSubmit}>
              <h4 className='pull-left'>New Comment</h4>
              <fieldset>
                <div className='row'>
                  {/* <div className="col-sm-3 col-lg-2 hidden-xs">
                                        <img className="img-responsive" src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="" />
                                    </div> */}

                  <div className='form-group col-xs-12 col-sm-12 col-lg-12'>
                    <textarea
                      className='form-control'
                      value={brand_new_comment}
                      onChange={(e) => setBrandNewComment(e.target.value)}
                      id='message'
                      placeholder='Your new comment'
                      required=''
                    />
                  </div>
                </div>
              </fieldset>
              <button
                type='submit'
                className='btn btn-outline-primary btn-sm small pull-right'
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </Div>
  )
}

export default BlogComments

// from https://www.bootdey.com/snippets/view/blog-item-filteredComments#css
