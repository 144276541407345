import React,{ useState } from "react";
import LeftPaneFlat from "./LeftPaneFlat";
import LeftPaneTabs from "./LeftPaneTabs";

const LeftPane = (props) => {
    const [pane, setPane] = useState("A");

    const onSwitchPane = () => {
        if (pane === "A") {
            setPane("B");
        } else {
            setPane("A");
        }
    };

    // const FacilitySelectionComponent = <SelectionComponent type='facility' onSwitchPane={onSwitchPane} />;
    // const MapSelectionComponent = <SelectionComponent type='map' />;

    return (
        <div>
            {pane === "A" ? (
                <LeftPaneFlat onSwitchPane={onSwitchPane} />
            ) : (
                <LeftPaneTabs onSwitchPane={onSwitchPane} />
            )}
        </div>
    );
};

export default LeftPane;