export const loadMultiTraceData = (
    x_data,
    y_data,
    plot_settings,
    extra_settings
) => {
    let traces = [];
    let y_keys = Object.keys(y_data);
    let x_keys = Object.keys(x_data);
    for (let key in y_data) {
        let trace = {
            type: "scattergl",
            // mode: "lines+markers",
            mode: "markers",
            connectgaps: plot_settings.connectgaps,
            // mode: "markers",
            // mode: "line+markers",
            marker: { size: 3 },
            name: key,
            x: x_keys.includes(key) ? x_data[key] : Object.values(x_data)[0],
            y: y_data[key],
        };
        if (plot_settings.plot_colors) {
            trace.marker.color = plot_settings.plot_colors[key];
        }
        traces.push(trace);
    }

    // remove all null values on the x axis
    // TODO check if this is really necesary
    for (let trace of traces) {
        let x = trace.x;
        let y = trace.y;
        let new_x = [];
        let new_y = [];
        // let count_nulls = 0
        for (let i = 0; i < x.length; i++) {
            if (x[i] !== '') {
                new_x.push(x[i]);
                new_y.push(y[i]);
            } 
            // else{
            //     count_nulls += 1        
            // }
        }
        trace.x = new_x;
        trace.y = new_y;
        // console.log("trace: ", trace.name)
        // console.log("count nulls: ", count_nulls)
        // console.log("trace x length: ", new_x.length)
    }

    // console.log(y_data,x_data)
    // console.log(traces)
    return traces;
};

export const loadMultiTraceLayout = (x_data, plot_settings, extra_settings) => {
    // for (let el of ["yaxis1", "yaxis2"]) {
    //     yaxes[el] = {
    //         // autorange: true,
    //         showspikes: true,
    //         spikemode: "across",
    //         domain: [0, 1],
    //         ticksuffix: " " + base_unit,
    //         type: "log",
    //         title:  {
    //             text: sensor_id,
    //             standoff: 20,
    //             font: {
    //               family: 'Courier New, monospace',
    //               size: 14,
    //             //   color:
    //             },
    //         },

    //     };

    // }

    const { base_unit, plot_height, x_title, y_title, x_type , y_type} = extra_settings;

    const x_data_list = Object.values(x_data)[0];
    const first_x = x_data_list[0];
    const last_x = x_data_list[x_data_list.length - 1];

    var layout = {
        
        autosize: true,
        margin: {l:100,r:60}, //was 50,40
        // title: title,
        //vertical line
        // hovermode: 'x',
        hovermode: "closest", //better for missing data
        hoverlabel: { namelength: -1 },
        showlegend: false,
        // legend: {
        //     orientation: "h",
        //     x: 0,
        //     y: -0.6*500/plot_height,
        // },
        // end of vertical line
        height: plot_height,
        // legend: { traceorder: 'reversed' },
        xaxis: {
            title: x_title,
            // vertical line
            showspikes: true,
            spikemode: "across+toaxis",
            spikesnap: "cursor",
            spikedash: "solid",
            showline: true,
            showgrid: true,
            type: x_type ? x_type : "linear",
            //end of vertical line
            autorange: true,
            // fixedrange: true,
            // range: [first_x, last_x],
            // range: [1, 100000],
            // NOTE: enabling rangeslider disables panning on the y axis
            rangeslider: false,
            // type: "date",
        },
        yaxis: {
            showspikes: true,
            spikemode: "across",
            ticksuffix: " " + base_unit,
            type: y_type ? y_type : "log",
            title: {
                text: y_title,
                standoff: 20,
                font: {
                    // family: "Courier New, monospace",
                    size: 14,
                },
            },
        },
    };
    if (plot_settings.square_ratio) {
        if (layout.xaxis.type === "log" && layout.yaxis.type === "log") {
            layout.yaxis.scaleanchor = "x";
            layout.yaxis.scaleratio = 1;
            layout.xaxis.dtick = 1;
            // layout.yaxis.range = [1, 100000];
        } 
    }
    console.log(first_x,last_x)

    return layout;
};
