import React, { useEffect } from 'react'

export const requestErrorToText = (error) => {
    if (error === "401" || error === "404") {
        return "Data not available for this well";

    }
    else if (error === "429") {
        return "Too many requests are being processed by the server. Please try again later.";
    } else if (error === "ServerOffline") {
        return "Error loading data. Network error. Server might be offline.";
    } else {
        return `Error loading data. Status code: ${error}`;
    }
}

export const DisplayRequestError = ({error}) => {

    return (
        <div>
            {requestErrorToText(error)}
        </div>
    );

}
