import React from 'react'
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updatePlotSelectionAction } from '../../store/dailyAppActions';


const PlotSelection = (props) => {
    // const {udc_selection=[], setUdcSelection} = props;
    // const [plot_selection, setPlotSelection] = React.useState("daily_production");

    const plot_selection = useSelector(state => state.daily_app_settings.plot_selection);
    const plot_loading = useSelector(state => state.settings.plot_loading);

    const dispatch =  useDispatch();

    const setPlotSelection = (plot_selection) => dispatch(updatePlotSelectionAction(plot_selection));



    return (
        <div className="mb-4">
            <h4>Plot Selection</h4>
            <hr/>
            <Form.Select size="sm"
                onChange={(e) => setPlotSelection(e.target.value)}
                value={plot_selection}
                disabled={plot_loading}
            >
                <option disabled value="none">Please select...</option>
                <option value="daily_production">Daily Production</option>
                <option value="engineering_plots">Engineering Plots</option>
                <option value="engineering_data">Engineering Plots V2</option>
            </Form.Select>
      
        </div>
    );
};

export default PlotSelection;