import React from "react";
import styled from "styled-components";

const Div = styled.div`
    #overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        //transparent gray overlay to catch mouse events
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 20;
        display: block;
    }

`;

export const Overlay = (props) => {
    const { disabled } = props;

    return <Div>{disabled && <div id="overlay"></div>}</Div>;
};
