import { getP2ScadaIds } from "bkv-components/General/data_transformation";
import React from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveFacilitySubSelection } from "../../store/dailyAppActions";
import { getPlotlyColor } from "bkv-components/General/utils";

const FacilitySubSelection = (props) => {
    // const {well_selection=[], setWellSelection} = props;
    // const [well_selection, setWellSelection] = React.useState([]);

    const active_p2_merrick_ids = useSelector(
        (state) => state.settings.active_p2_merrick_ids
    );

    const well_data_hashed = useSelector(
        (state) => state.raw_data.well_data_hashed
    );

    const facility_sub_selection = useSelector(
        (state) => state.daily_app_settings.facility_sub_selection
    );

    const dispatch = useDispatch();

    const setWellSelection = (well_selection) =>
        dispatch(updateActiveFacilitySubSelection(well_selection));

    console.log(facility_sub_selection);

    return (
        <div className="mb-4">
            <h4>Well Sub-Selection</h4>
            <hr />
            <div className="list-group">
                {active_p2_merrick_ids.map((p2_merrick_id, index) => {
                    let well = well_data_hashed[p2_merrick_id];
                    let p2_well_name = well.p2_well_name;
                    return (
                        <label
                            key={p2_merrick_id}
                            className="list-group-item"
                            style={{ fontSize: "small" }}
                        >
                            <input
                                key={p2_merrick_id}
                                className="form-check-input me-1"
                                type="checkbox"
                                checked={facility_sub_selection.includes(
                                    p2_merrick_id
                                )}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setWellSelection([
                                            ...facility_sub_selection,
                                            p2_merrick_id,
                                        ]);
                                    } else {
                                        setWellSelection(
                                            facility_sub_selection.filter(
                                                (item) => item !== p2_merrick_id
                                            )
                                        );
                                    }
                                }}
                            />
                            <span
                                style={{
                                    display: "inline-block",
                                    width: "0.8em",
                                    height: "0.8em",
                                    borderRadius: "50%",
                                    backgroundColor: getPlotlyColor(index),
                                    marginRight: "0.2em",
                                }}
                            ></span>
                            {/* well name with tooltip */}
                            <span
                                className="tooltip-wrapper"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={
                                    "p2_scada_id:" +
                                    well.p2_scada_id +
                                    " - p2_merrick_id:" +
                                    well.p2_merrick_id
                                }
                            >
                                {p2_well_name}
                            </span>
                        </label>
                    );
                })}
            </div>
        </div>
    );
};

export default FacilitySubSelection;
