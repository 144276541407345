import axios from "axios";
import { getApiDefaults, httpRequestErrorAction } from "bkv-components/store_lib/actions/dataActions";

export const updateActiveUdcAction = (udc_selection) => ({
    type: "UPDATE_UDC_SELECTION",
    payload: { udc_selection: udc_selection },
});

export const updatePlotSelectionAction = (plot_selection) => ({
    type: "UPDATE_PLOT_SELECTION",
    payload: { plot_selection: plot_selection },
});

export const updatePlotSubSelectionAction = (plot_sub_selection) => ({
    type: "UPDATE_PLOT_SUB_SELECTION",
    payload: { plot_sub_selection: plot_sub_selection },
});

export const updatePlotXAxisAction = (plot_x_axis) => ({
    type: "UPDATE_PLOT_X_AXIS",
    payload: { plot_x_axis: plot_x_axis },
});

export const updatePlotYAxisAction = (plot_y_axis) => ({
    type: "UPDATE_PLOT_Y_AXIS",
    payload: { plot_y_axis: plot_y_axis },
});

export const updatePlotXAxisScaleAction = (x_axis_scale) => ({
    type: "UPDATE_X_AXIS_SCALE",
    payload: { x_axis_scale: x_axis_scale },
});

export const updatePlotYAxisScaleAction = (y_axis_scale) => ({
    type: "UPDATE_Y_AXIS_SCALE",
    payload: { y_axis_scale: y_axis_scale },
});

export const updateActiveFacilitySubSelection = (facility_sub_selection) => ({
    type: "UPDATE_WELL_SUB_SELECTION",
    payload: { facility_sub_selection: facility_sub_selection },
});

export const downloadDailyMultiWellData = (
    p2_merrick_id_list,
    start_time,
    end_time
) => {
    return async (dispatch, getState) => {
        const { api_endpoint, headers } = await getApiDefaults();

        let parameters = {
            max_rows: 6000 * 20,
        };

        if (start_time && end_time) {
            //convert timestamp to date string ISO 8601
            parameters.start_date = start_time.toISOString().split("T")[0];
            parameters.end_date = end_time.toISOString().split("T")[0];
        }

        try {
            var response = await axios.post(
                api_endpoint + "/daily_data/multi",
                {
                    p2_merrick_id_list: p2_merrick_id_list,
                },
                { headers: headers, params: parameters }
            );
        } catch (e) {
            dispatch(httpRequestErrorAction(e));
        }
        let data = response.data;

        await dispatch({
            type: "LOAD_SENSOR_MULTIWELL_DATA",
            payload: data,
        });
    };
};

// export const downloadEngineeringPlotsAction = (
//     p2_merrick_id_list,
//     start_time,
//     end_time
// ) => {
//     return async (dispatch, getState) => {
//         const { api_endpoint, headers } = await getApiDefaults();

//         let parameters = {
//             max_rows: 6000 * 20,
//         };

//         if (start_time && end_time) {
//             //convert timestamp to date string ISO 8601
//             parameters.start_date = start_time.toISOString().split("T")[0];
//             parameters.end_date = end_time.toISOString().split("T")[0];
//         }

//         try {
//             var response = await axios.post(
//                 api_endpoint + "/daily_data/engineering_plots",
//                 {
//                     p2_merrick_id_list: p2_merrick_id_list,
//                 },
//                 { headers: headers, params: parameters }
//             );
//         } catch (e) {
//             let status = e.response ? e.response.status : "";
//             return await dispatch(
//                 {
//                     type: "DOWNLOAD_ENGINEERING_PLOTS_ERROR",
//                     payload: { error: true, status: status },
//                 },
//                 console.log("error", e)
//             );
//         }
//         let data = response.data;

//         await dispatch({
//             type: "DOWNLOAD_ENGINEERING_PLOTS",
//             payload: { engineering_plots: data },
//         });
//     };
// };

export const downloadEngineeringDataAction = (
    p2_merrick_id_list,
    start_time,
    end_time
) => {
    return async (dispatch, getState) => {
        const { api_endpoint, headers } = await getApiDefaults();

        let parameters = {
            max_rows: 6000 * 20,
        };

        if (start_time && end_time) {
            //convert timestamp to date string ISO 8601
            parameters.start_date = start_time.toISOString().split("T")[0];
            parameters.end_date = end_time.toISOString().split("T")[0];
        }

        try {
            var response = await axios.post(
                api_endpoint + "/daily_data/engineering_data",
                {
                    p2_merrick_id_list: p2_merrick_id_list,
                },
                { headers: headers, params: parameters }
            );
        } catch (e) {
            dispatch(httpRequestErrorAction(e));
        }
        let data = response.data;

        await dispatch({
            type: "DOWNLOAD_ENGINEERING_DATA",
            payload: { engineering_data: data },
        });
    };
};
