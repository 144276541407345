import React from "react";
import PropTypes from "prop-types";

const PlotResolution = (props) => {
    const { base_resolution, resolution, num_rows } = props;
    return (
        <div className="d-flex justify-content-center justify-content-sm-between align-items-center mb-3 m-2 ms-5 me-5">
            {/* <div className="d-flex justify-content-center">
        <label className="align-self-center">RESOLUTION: </label>
        <button type="button" className="btn btn-sm btn-outline-primary ml-2 active">1day</button>
        <pre> </pre>
        <button type="button" className="btn btn-sm btn-outline-primary ml-2 ">1week</button>
        <pre> </pre>
        <button type="button" className="btn btn-sm btn-outline-primary  ml-2">1month</button>
    </div> */}
            <div className="d-none d-md-block">
                <label className="align-self-center me-1">
                    Base resolution:
                </label>
                <span>{base_resolution}</span>
            </div>
            <div>
                <label className="align-self-center me-1">Resolution:</label>
                <span>{resolution}</span>
            </div>
            <div className="d-none d-sm-block">
                <label className="align-self-center me-1">
                    Number of rows:{" "}
                </label>
                <span>{num_rows}</span>
            </div>
        </div>
    );
};

PlotResolution.propTypes = {
    base_resolution: PropTypes.string.isRequired,
    resolution: PropTypes.string.isRequired,
    num_rows: PropTypes.number.isRequired,
};

export default PlotResolution;
