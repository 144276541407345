import UserPlotSettings from "bkv-components/SensorPlot/UserPlotSettings";
import SensorSelection from "./SensorSelection";
import { SelectionComponent } from "./SelectionComponent";
import { LeftPaneFlatLayout } from "bkv-components/Layout/LeftPaneFlatLayout";


const LeftPaneFlat = (props) => {
    const { user_plot_settings, onSwitchPane } = props;
    return (
        <LeftPaneFlatLayout
            LeftChild={<SelectionComponent type='facility' onSwitchPane={onSwitchPane} />}
            RightChild={<>
                <div>
                    <SensorSelection />
                    <br />
                </div>
                <div>
                    <UserPlotSettings
                        user_plot_settings={user_plot_settings}
                        disabled_settings={["merged_pressure_plots"]}
                    />
                    <br />
                </div>
                </>
            }
        />
        
    );
};

export default LeftPaneFlat;