import React from "react";
// import { Link } from "gatsby";
// import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";
import dimensions from "../styles/dimensions";
import colors from "../styles/colors";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";

const ProjectCardContainer = styled("div")`
    display: grid;
    grid-template-columns: 5fr 9fr;
    /* grid-template-columns: 5fr 9fr; */
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.06);
    margin-bottom: 4em;
    transition: all 150ms ease-in-out;
    text-decoration: none;
    color: currentColor;
    /* max-height: 400px; */

    max-width: 500px;
    margin-left: 2em;
    margin-right: 2em;

    @media (max-width: 950px) {
        grid-template-columns: 4.5fr 7fr;
    }

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        grid-template-columns: 1fr;
    }

    @media (max-width: ${dimensions.maxwidthMobile}px) {
        margin-bottom: 2em;
    }

    &:hover {
        box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
        transition: all 150ms ease-in-out;

        .ProjectCardAction {
            color: ${colors.blue500};
            transition: all 150ms ease-in-out;

            span {
                transform: translateX(0px);
                opacity: 1;
                transition: transform 150ms ease-in-out;
            }
        }

        .ProjectCardContent::before {
            opacity: 0.02;
            transition: all 150ms ease-in-out;
        }

        .ProjectCardImageContainer::before {
            opacity: 0.2;
            transition: all 150ms ease-in-out;
        }
    }
`;

const ProjectCardContent = styled("div")`
    background: white;
    padding: 4em 3em 2.25em 3em;
    position: relative;
    overflow: auto;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: ${colors.blue500};
        mix-blend-mode: multiply;
        opacity: 0;
        transition: all 150ms ease-in-out;
    }

    @media (max-width: 950px) {
        padding: 3.25em 2.5em 2em 2.5em;
    }

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        grid-row: 2;
    }
`;

const ProjectCardCategory = styled("h6")`
    font-weight: 600;
    color: ${colors.grey600};
`;

const ProjectCardTitle = styled("h3")`
    margin-bottom: 0.5em;
    margin-top: 0.5em;
`;

const ProjectCardBlurb = styled("div")`
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    margin-bottom: 5em;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        margin-bottom: 2.5em;
    }
`;

const ProjectCardAction = styled("div")`
    font-weight: 600;
    text-decoration: none;
    color: currentColor;
    transition: all 150ms ease-in-out;

    span {
        margin-left: 1em;
        transform: translateX(-8px);
        display: inline-block;
        transition: transform 400ms ease-in-out;
    }
`;

const ProjectCardImageContainer = styled("div")`
    background: ${colors.grey200};
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    position: relative;
    /* padding-left: 2em;
    padding-right: 2em; */
    /* max-height: 400px; */

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        /* padding-top: 3em; */
        /* max-height: 200px; */
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: ${colors.blue500};
        mix-blend-mode: multiply;
        opacity: 0;
        transition: all 150ms ease-in-out;
    }

    img {
        /* max-height: 400px; */
        min-height: 500px;
        width: 100%;
        height: 100%;
        min-width: 0;
    }
`;
// max-width: 400px;
// width: 100%;
// box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);

// @media(max-width:${dimensions.maxwidthTablet}px) {
//     max-width: 300px;
// }

const ProjectCard = ({
    category,
    title,
    description,
    thumbnail,
    redirect_link,
}) => {
    let history = useHistory();
    const goto = (redirect_link) => {
        //redirect pushing url with react-router-dom history
        history.push(redirect_link);
    };

    return (
        <ProjectCardContainer onClick={() => goto(redirect_link)}>
            {/* <Link to={redirect_link}> */}
            <ProjectCardContent className="ProjectCardContent">
                <ProjectCardCategory>
                    {/* BKV Corporation */}
                    {category}
                </ProjectCardCategory>
                <ProjectCardTitle>
                    {/* Surveillance Dashboard */}
                    {title}
                </ProjectCardTitle>
                <ProjectCardBlurb>
                    {/* {RichText.render(description)} */}
                    {/* This is a large description. This is a large description. This is a large description. This is a large description. This is a large description. This is a large description.  */}
                    {description}
                </ProjectCardBlurb>
                <ProjectCardAction className="ProjectCardAction">
                    Go to application <span>&#8594;</span>
                </ProjectCardAction>
            </ProjectCardContent>
            <ProjectCardImageContainer className="ProjectCardImageContainer">
                <img src={thumbnail} alt={title} />
            </ProjectCardImageContainer>
            {/* </Link> */}
        </ProjectCardContainer>
    );
};

export default ProjectCard;

ProjectCard.propTypes = {
    category: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};
