import { getColorFromUDC } from "bkv-components/SensorPlot/Components/utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveUdcAction } from "../../store/dailyAppActions";

// gas_production: '#d62728', // brick red
// oil_production: 'green', // green
// water_production: 'blue', // blue
// casing_pressure: 'gray', // gray
// tubing_pressure: 'black', // black
// line_pressure: 'gold', // orange
// gas_target: 'pink', // pink
// gas_budget: 'purple', // purple
// gas_forecast: 'orange', // orange

export const udc_list = [
    "gas_production",
    "oil_production",
    "water_production",
    "casing_pressure",
    "tubing_pressure",
    "line_pressure",
    "gas_target",
    "gas_budget",
    "gas_forecast",
    "gasbuyback_whp"
];

const udc_names = {
    gas_production: "GAS (mcf/d)",
    oil_production: "OIL (bbl/d)",
    water_production: "WATER (bbl/d)",
    casing_pressure: "CASING (psi)",
    tubing_pressure: "TUBING (psi)",
    line_pressure: "LINE (psi)",
    gas_target: "GAS TARGET (mcf/d)",
    gas_budget: "GAS BUDGET (mcf/d)",
    gas_forecast: "GAS FORECAST (mcf/d)",
    gasbuyback_whp: "GAS BUYBACK WHP(mcf/d)",
};

const UdcSelection = (props) => {
    // const {udc_selection=[], setUdcSelection} = props;
    // const [udc_selection, setUdcSelection] = React.useState([]);

    const udc_selection = useSelector(
        (state) => state.daily_app_settings.udc_selection
    );

    const plot_loading = useSelector((state) => state.settings.plot_loading);

    const dispatch = useDispatch();

    const setUdcSelection = async (udc_selection) => {
        await dispatch(updateActiveUdcAction(udc_selection));

    }
        
    console.log(udc_selection);

    return (
        <div className="mb-4">
            <h4>Series Selection</h4>
            <hr />
            <div className="list-group">
                {udc_list.map((udc) => {
                    return (
                        <label
                            key={udc}
                            className="list-group-item"
                            style={{ fontSize: "small" }}
                        >
                            <input
                                key={udc}
                                className="form-check-input me-1"
                                type="checkbox"
                                checked={udc_selection.includes(udc)}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setUdcSelection([
                                            ...udc_selection,
                                            udc,
                                        ]);
                                    } else {
                                        setUdcSelection(
                                            udc_selection.filter(
                                                (item) => item !== udc
                                            )
                                        );
                                    }
                                }}
                            />
                            <span
                                style={{
                                    display: "inline-block",
                                    width: "0.8em",
                                    height: "0.8em",
                                    borderRadius: "50%",
                                    backgroundColor: getColorFromUDC(udc),
                                    marginRight: "0.2em",
                                }}
                            ></span>
                            {udc_names[udc]}
                        </label>
                    );
                })}
            </div>
        </div>
    );
};

export default UdcSelection;
