// sensor_data={data}
// show_settings={show_settings}
// // start_time={start_time}
// // end_time={end_time}
// start_time={start_timeline}
// end_time={end_timeline}
// comments_dict={comments_dict}
// onUpdateResolution={onUpdateResolution}
// onCreateComment={onCreateComment}
// onUpdateComments={onUpdateComments}
// onPlotMountedChange={onPlotMountedChange}
// user_plot_settings={user_plot_settings}
// mode="fixed_udc"
// base_unit={active_udc[0] === "INSFLG0" ? "mfc":"psi"}
// sensor_id={active_udc[0]}
// account={account}

import React from "react";
import PropTypes from "prop-types";
import { getPlotlyColor, getUDCInfo } from "bkv-components/General/utils";
import SensorPlotV2 from "bkv-components/SensorPlot/SensorPlotV2";

// trace_info_example
// const trace_info = {
//     BP1-CMLINE0: {
//         well_id: "BP_A3U_YARBROUGH_B_2H",
//         sensor_id: "CMLINE0",
//         label: "BP1-CMLINE0",
//         yaxis_name: "CMLINE0",
//         color: 'blue',
//         unit: 'psi'
//     },
// }

const getUDCFromKey = (key) => {
    const key_split = key.split("-");
    return key_split[1];
};

const getWellFromKey = (key) => {
    const key_split = key.split("-");
    return key_split[0];
};

const MultiWellPlotHelper = (props) => {
    const {
        sensor_data, //dictionary
        show_settings,
        start_time,
        end_time,
        comments_dict,
        onUpdateResolution,
        onCreateComment,
        onUpdateComments,
        onPlotMountedChange,
        user_plot_settings,
        account,
    } = props;

    // build trace_info
    let wells = Object.keys(sensor_data).map((key) => {
        return getWellFromKey(key);
    });
    //make wells unique
    wells = [...new Set(wells)];
    let trace_info = {};
    Object.keys(sensor_data).forEach((key) => {
        if (key === "record_time") {
            return;
        }
        // const trace = sensor_data[key];
        const well_id = getWellFromKey(key);
        // get well_id index
        const well_id_index = wells.indexOf(well_id);
        const sensor_id = getUDCFromKey(key);
        trace_info[key] = {
            well_id: well_id,
            sensor_id: sensor_id,
            label: key,
            yaxis_name: sensor_id,
            color: getPlotlyColor(well_id_index),
            unit: getUDCInfo(sensor_id).unit,
        };
    });

    // sort trace_info by yaxis_name (reverse alphabetical)
    const trace_info_sorted = {};
    const sorted_keys = Object.keys(trace_info).sort((b, a) => {
        return trace_info[a].yaxis_name.localeCompare(trace_info[b].yaxis_name);
    });
    sorted_keys.forEach((key) => {
        trace_info_sorted[key] = trace_info[key];
    });

    console.log(start_time, end_time)

    return (
        <div>
            <SensorPlotV2
                sensor_data={sensor_data}
                trace_info={trace_info_sorted}
                show_settings={show_settings}
                start_time={start_time}
                end_time={end_time}
                comments_dict={comments_dict}
                onUpdateResolution={onUpdateResolution}
                onCreateComment={onCreateComment}
                onUpdateComments={onUpdateComments}
                onPlotMountedChange={onPlotMountedChange}
                user_plot_settings={user_plot_settings}
                account={account}
                application={'multi'}
            />
        </div>
    );
};

MultiWellPlotHelper.propTypes = {
    // sensor data must be a dictionary
    sensor_data: PropTypes.object.isRequired,
    show_settings: PropTypes.bool.isRequired,
    // start_time of type date
    start_time: PropTypes.objectOf(Date).isRequired,
    end_time: PropTypes.objectOf(Date).isRequired,
};

export default MultiWellPlotHelper;
