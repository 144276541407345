import React, { Suspense, useState } from 'react'
// import ArcgisPlot from './ArcgisPlot';
import { evalFilteredWellData, filterWellData } from '../../FacilitySelection/FacilitySelectWithFilters';
import styled from 'styled-components';

// dynamic import ArcgisPlot
const ArcgisPlot = React.lazy(() => import('./ArcgisPlot'));

const ArcgisPlotLoader = (props) => {
    return (
        <Suspense fallback={<div>Page is Loading...</div>}>
            <ArcgisPlot {...props} />
        </Suspense>
    );
};

const isNaNorZero = (x) => {
    // if string, convert to number
    if (typeof x === "string") {
        x = Number(x);
    } else {
        if (x === null) {
            return true;
        } else if (x === undefined) {
            return true;
        } 
    }
    return isNaN(x) || x === 0;
};

// [-118.24532, 34.0524],
//     [-118.24088, 34.04999],
// [-118.24838, 34.05332],

let points_test = [
  {
      x: -118.24532,
      y: 34.0524,
      name: "point 1",
  },
  {
      x: -118.24088,
      y: 34.04999,
      name: "point 2",
  },
  {
      x: -118.24838,
      y: 34.05332,
      name: "point 3",
  },
]

const Div = styled.div`
    position: relative;

    #overlay,
    .mapDiv {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .mapDiv {
        z-index: 10;
    }
    #overlay {
        //transparent gray overlay to catch mouse events
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 20;
        display: block;
    }
`;


function getZoomLevel(maxLat, minLat, maxLng, minLng, mapWidth, mapHeight) {
    // Calculate the distance between the maximum and minimum longitude values in meters
    const lngDistance = haversine(maxLng, maxLat, minLng, minLat);

    //500px = 0.13 meters
    
    // Calculate the scale of the map in meters per pixel
    const scale = lngDistance / (mapWidth/500*0.13);
    
    let zoom_equivalence = {
        1:295828763.8,
        2:147914381.9,
        3:73957190.95,
        4:36978595.47,
        5:18489297.73,
        6:9244648.867,
        7:4622324.434,
        8:2311162.217,
        9:1155581.108,
        10:577790.554,
        11:288895.277,
        12:144447.638,
        13:72223.819,
        14:36111.909,
        15:18055.954,
        16:9027.977,
        17:4513.988,
        18:2256.994,
        19:1128.497,
        20:564.249,
        21:282.124,
        22:141.062,
        23:70.531,
    }
        
    // Calculate the zoom level closest to the scale
    const zoomLevel = Object.keys(zoom_equivalence).reduce((a, b) => {
        return Math.abs(zoom_equivalence[a] - scale) < Math.abs(zoom_equivalence[b] - scale) ? a : b;
    });
    
    return zoomLevel;
  }
  
  // Haversine formula to calculate distance between two points in meters
  function haversine(lng1, lat1, lng2, lat2) {
    const R = 6371e3; // metres
    const φ1 = toRadians(lat1);
    const φ2 = toRadians(lat2);
    const Δφ = toRadians(lat2-lat1);
    const Δλ = toRadians(lng2-lng1);
  
    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  
    const d = R * c;
    return d;
  }
  
  function toRadians(degrees) {
    return degrees * Math.PI / 180;
  }
  


const ArcgisMapSelection = (props) => {
    const {
        well_data,
        well_data_hashed,
        filters,
        onSelectionChange,
        selector_type,
        selection,
        current_group,
        max_selected = 5,
        disabled=false
    } = props;


    let filtered_well_data = evalFilteredWellData(
        current_group,
        well_data,
        filters,
        selection,
        selector_type,
        well_data_hashed
    );


    let points = filtered_well_data.map((el) => {
        return {
            x: el.p2_surface_longitude,
            y: el.p2_surface_latitude,
            name: el.p2_well_name,
            id: el.p2_merrick_id,
            p2_scada_id: el.p2_scada_id,
        };
    });

    // discard pointw where x="" or x is null
    points = points.filter((el) => !isNaNorZero(el.x) && !isNaNorZero(el.y));

    //get top, bottom, left, right boudaries
    let top = Math.max(...points.map((el) => el.y));
    let bottom = Math.min(...points.map((el) => el.y));
    let left = Math.min(...points.map((el) => el.x));
    let right = Math.max(...points.map((el) => el.x));
    let center = [(left + right) / 2, (top + bottom) / 2];
    //calculate map zoom base on boundaries
    let zoom = getZoomLevel(top, bottom, left, right, 500, 1000);

    console.log(zoom)

    



    
    const handleChange = (el_id) => {
        if (selector_type === "checkbox") {
            //multiple selection
            if (selection.includes(el_id)) {
                //remove element
                let new_selection = selection.filter((e) => e !== el_id);
                onSelectionChange(new_selection);
            } else {
                //add element
                if (selection.length < max_selected) {
                    let new_selection = [...selection, el_id];
                    onSelectionChange(new_selection);
                } else {
                    alert("Max selection reached (" + max_selected + ")");
                }
            }
        } else {
            //single selection
            if (!selection.includes(el_id)) {
                onSelectionChange([el_id]);
            }
        }
    };

    let filtered_selection = selection.filter((el) => {
        return points.map((el) => el.id).includes(el);
    });




  return (
    <Div
    style={{width: "100%", height: "100%"}} >
        {disabled && <div id="overlay"></div>}
        {/* <ArcgisPlot */}
        <ArcgisPlotLoader
            points={points}
            selection={filtered_selection}
            center={center}
            // zoom={11}
            zoom={zoom}
            onPointClick={handleChange}

        />
        
    </Div>
  )
}

export default ArcgisMapSelection