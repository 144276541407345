import React, { useEffect, Suspense, lazy } from "react";
import "./styles/App.css";
import "./styles/Tabs.css";

import { Route, BrowserRouter, Switch } from "react-router-dom"; //import router
// import { downloadFacilityTable, downloadWellTable } from './store/actions/dataActions';


// import {ErrorBoundary} from 'react-error-boundary'

//tabulator settings
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";

// authentication
import SignIn from "./pages/SignIn";
import {
    MsalProvider,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import { GlobalDebug } from "./utils/remove-consoles";
import SingleWellSurveillance from "./pages/SingleWellSurveillance";
import Portal from "./pages/Portal";
import MultiWellSurveillance from "./pages/MultiWellSurveillance";
import AdminDashboard from "./pages/AdminDashboard";
import DailyMultiWellSurveillance from "./pages/DailyMultiWellSurveillance";
// import TestDailyPlot from "./component_testing/unused_TestDailyPlot";
import UnderMaintenance from "./pages/UnderMaintenance";
import {
    // checkApiConnectionAction,
    getApiDefaults,
} from "bkv-components/store_lib/actions/dataActions";
import axios from "axios";
import PageNotFound from "./pages/404Page";
// import { TestArcgisMapSelection } from "./component_testing/TestArcgisMapSelection";
// import TestPlot from "./component_testing/TestPlot";
// const TestPlot = lazy(() => import("./component_testing/unused_TestPlot"));
const TestPlotV2 = lazy(() => import("./component_testing/TestPlot_v2"));

// include modules if using development mode
// import TestPlot from "./component_testing/TestPlot";
// import GeneralTesting from "./component_testing/GeneralTesting";
// import TestComments from "./component_testing/TestComments";
// import TestAnnotation from "./component_testing/TestAnnotation";
// import TestCommentTable from "./component_testing/TestCommentTable";
// import GetCacheData from "./component_testing/GetCacheData";

// const MyTestPlot = () => {
//     return (
//         <Suspense fallback={<div>Page is Loading...</div>}>
//             <TestPlot />
//         </Suspense>
//     );
// };

const MyTestPlotV2 = () => {
    return (
        <Suspense fallback={<div>Page is Loading...</div>}>
            <TestPlotV2 />
        </Suspense>
    );
};

// window.onerror = function(message, url, lineNumber) {  
//     //save error and send to server for example.
//     alert('custom error1');
//     console.log('custom error2', message, url, lineNumber);
//     return true;
//   };

//   window.addEventListener('error', function (e) {
//     console.log('custom listener error', e);
//     alert('custom listener error');
//   })

// window.onunhandledrejection = (e) => {
//     console.error('unhandledrejection', e);
//     throw e;
// }

function App(props) {
    const { msalInstance } = props;

    const [api_connection_error, setApiConnectionError] = React.useState(false);

    useEffect(() => {
        const apiConnectionCheck = async () => {
            const { api_endpoint, headers } = await getApiDefaults();
            try {
                var res1 = await axios.get(api_endpoint + "/", {
                    headers: headers,
                });
            } catch (e) {
                let response_exist = e.response ? true : false;
                if (window.navigator.onLine && !response_exist) {
                    setApiConnectionError(true);
                }
                if (response_exist) {
                    console.warn('api connection error with status code:', e.response.status);
                } else {
                    console.warn('api connection error with no response');
                }
                return false;
            }
            setApiConnectionError(false);
            return true;
        };

        apiConnectionCheck();

        // // check connection every 10 seconds
        // const interval = setInterval(async () => {
        //     let value = await apiConnectionCheck();
        //     if (value) {
        //         clearInterval(interval);
        //     }
        // }, 10000);

        (process.env.NODE_ENV === "production" ||
            process.env.REACT_APP_ENV === "STAGING") &&
            GlobalDebug(false);


    }, []);


    if (api_connection_error) {
        //redirect to under maintenance page
        return <UnderMaintenance />;
    }

    return (
    //     <ErrorBoundary 
    // //    FallbackComponent={MyFallbackComponent}
    //    onError={(error, errorInfo) => alert('error', error, errorInfo)}
    //    onReset={() => {
    //      // reset the state of your app
    //    }}
    // >
        <MsalProvider instance={msalInstance}>
            <div className="App">
                <AuthenticatedTemplate>
                    <BrowserRouter>
                        <Switch>
                            <Route
                                exact
                                path="/single"
                                component={SingleWellSurveillance}
                            />
                            <Route
                                exact
                                path="/multi"
                                component={MultiWellSurveillance}
                            />
                            <Route
                                exact
                                path="/daily"
                                component={DailyMultiWellSurveillance}
                            />
                            <Route exact path="/" component={Portal} />
                            <Route exact path="/plotsv2" component={MyTestPlotV2} />
                            <Route
                                exact
                                path="/admin"
                                component={AdminDashboard}
                            />
                            {/* <Route
                                exact
                                path="/dailytest"
                                component={TestDailyPlot}
                            /> */}
                            <Route
                                exact
                                path="/maintenance"
                                component={UnderMaintenance}
                            />
                            <Route
                                component={PageNotFound}
                            />
                            {/* <Route exact path="/arcgis" component={TestArcgisMapSelection} /> */}
                            {/* <Route exact path="/testing" component={GeneralTesting} />
                        <Route exact path="/comments" component={TestComments} />
                        <Route exact path="/anno" component={TestAnnotation} />
                        <Route exact path="/tabletest" component={TestCommentTable} />
                        <Route exact path="/cache" component={GetCacheData} /> */}
                        </Switch>
                    </BrowserRouter>
                </AuthenticatedTemplate>

                <UnauthenticatedTemplate>
                    <SignIn />
                </UnauthenticatedTemplate>
            </div>
        </MsalProvider>
        // </ErrorBoundary>
    );
}

export default App;
