import React from 'react'

import PortalApp from '../applications/portal/PortalApp'

function Portal() {
  return (
    <PortalApp/>
  )
}

export default Portal