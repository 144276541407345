// Under Mainteinanc Page

import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
//center horizontally and vertically
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    background-color: #f9f9f9;
    color: #094e8f;

    .mycontainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 60%;
    }        

    h1 {
        // horizontal left
        text-align: left;
        //bold
        font-weight: bold;
        margin-bottom: 1em;
    }

    p {
        font-size: 20px;
    }

`;

const PageNotFound = (props) => {

        return (
            <Div>
                <div className='mycontainer'>
                {/* <img src={"http://images.mycomplia.com/public/under-maintenance.png"} alt="under_maintenance" /> */}
                <h1>404 Error</h1>
                <h2>Page Not Found</h2>
                <p>If this is unexpected behaviour contact <a href="mailto: manuelsolalinde@bkvcorp.com"> Manuel Solalinde </a> 
                or <a href="mailto: samidhoda@bkvcorp.com"> Sam Hoda </a>
                </p>
                </div>
            </Div>
        )
}

export default PageNotFound;