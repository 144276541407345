import React, { useState } from "react";
import LeftPaneFlat from "./LeftPaneFlat";
import LeftPaneTabs from "./LeftPaneTabs";

export const LeftPane = ({ plot_mounted, user_plot_settings }) => {
    const [pane, setPane] = useState("A");

    const onSwitchPane = () => {
        if (pane === "A") {
            setPane("B");
        } else {
            setPane("A");
        }
    };

    // const FacilitySelectionComponent = <SelectionComponent type='facility' onSwitchPane={onSwitchPane} />;
    // const MapSelectionComponent = <SelectionComponent type='map' />;

    return (
        <div>
            {pane === "A" ? (
                <LeftPaneFlat
                    user_plot_settings={user_plot_settings}
                    plot_mounted={plot_mounted}
                    onSwitchPane={onSwitchPane}
                />
            ) : (
                <LeftPaneTabs
                    onSwitchPane={onSwitchPane}
                />
            )}
        </div>
    );
};




