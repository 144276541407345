import React, { useEffect, useState } from "react";
// import InfiniteScroll from "react-bidirectional-infinite-scroll";
import InfiniteScroll from "../General/ReactBidirectionalInfiniteScroll";
import styled from "styled-components";

const Div = styled.div`
    .context-menu {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        background-color: #fff;
        border: 1px solid #ccc;
    }

    .selected-items {
        overflow-y: scroll;
        padding-top: 5px;
        padding-bottom: 5px;
        /* border-radius: 5px;
        border-color: #ccc;
        border: 1px solid #ccc; */
    }

    .selected-items label {
        /* border-left: unset; */
        /* border-radius: 0px; */
    }

    .list-group-item:first-child {
        border-top-left-radius: 5px !important;
    }

    .unselected-items {
        margin-top: 5px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;

        border-radius: 5px;
        border-color: #ccc;
        /* padding-top: 5px; */
        /* padding-bottom: 5px;  this breaks infiniteScroll */
        padding-bottom: -100px; //this fixes infiniteScroll
    }

    .unselected-items .list-group-item:first-child {
        border-top: unset;
    }

    .list-group {
        overflow: hidden;
    }

    .list-group-item {
        white-space: nowrap;
    }



    .facilities {
        max-height: 300px;
        /* margin-bottom: 10px;
        overflow: scroll;
        overflow-x: hidden; //auto
        overflow-y: scroll;*/
    }

    .facilities label,
    .sensors label {
        font-size: small;
    }

    hr {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        border: 0;
        border-top: 1px solid #ccc;
    }
`;

const sliceData = (data, position, max_items) => {
    let start = position;
    let end = position + max_items;
    let data_aux = data.slice(start, end);
    return data_aux;
};

const max_items = 800;

const WellElements = ({
    processed_data,
    handleChange,
    selector_type,
    disabled,
}) => {
    // // CONTEXT MENU
    // const [showMenu, setShowMenu] = useState(false);
    // const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    // const clickOutside = () => {
    //     setShowMenu(false);
    // };
    // const handleContextMenu = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     console.log("ellipsis clicked");
    //     setShowMenu(true);
    //     console.log(e);
    //     // get the bottom and right position of the target element
    //     const target_position = e.target.getBoundingClientRect();
    //     console.log(target_position);
    //     let x = target_position.right;
    //     let y = target_position.top;
    //     setMenuPosition({ x, y });
    // };



    //useRef
    const ref = React.useRef(null);

    const [position, setPosition] = useState(0);
    const [sliced_data, setSlicedData] = useState(
        sliceData(processed_data, 0, max_items)
    );
    const [prev_process_data_length, setPrevProcessDataLength] = useState(0);

    let onReachBottom = () => {
        console.log("onReachBottom");
        let new_position = position + max_items / 2;
        new_position =
            new_position > processed_data.length - max_items
                ? processed_data.length - max_items
                : new_position;
        let new_data = sliceData(processed_data, new_position, max_items);
        setPosition(new_position);
        setSlicedData(new_data);
    };

    let onReachTop = () => {
        console.log("onReachTop");
        let new_position = position - max_items / 2;
        new_position = new_position < 0 ? 0 : new_position;
        let new_data = sliceData(processed_data, new_position, max_items);
        // if (ref.current){
        //     ref.current.scrollTop += 100;
        //     console.log(ref.current)
        // }
        if (ref.current && position !== 0) {
            const scroller = ref.current.scroller;
            scroller.scrollTop += 200;
        }
        setPosition(new_position);
        setSlicedData(new_data);
    };

    let onScroll = (position, previousPosition) => {

    };


    useEffect(() => {
        if (processed_data.length !== prev_process_data_length) {
            //on change of processed_data
            let new_data = sliceData(processed_data, 0, max_items);
            setSlicedData(new_data);
            setPosition(0);
            setPrevProcessDataLength(processed_data.length);
        }
        else {
            //on change of position
            let n_selected = processed_data.filter(
                (facility) => facility.is_selected
            ).length;
            let new_data = sliceData(processed_data, position, max_items+n_selected);
            setSlicedData(new_data);
        }
    }, [processed_data]);

    const selected_data = processed_data.filter(
        (facility) => facility.is_selected
    );





    return (
        <Div>
            {/* CONTEXT MENU */}
            {/* <OutsideAlerter handleClick={clickOutside}>
                {showMenu && (
                    <ContextMenu
                        position={menuPosition}
                        className="context-menu"
                    />
                )}
            </OutsideAlerter> */}
            {selector_type === "checkbox" && (
                <div>
                    <ul className="list-group facilities selected-items">
                        {selected_data.map((facility) => {
                            return (
                                <WellElement
                                    key={facility.p2_merrick_id}
                                    facility={facility}
                                    handleChange={handleChange}
                                    selector_type={selector_type}
                                    disabled={disabled}
                                    // handleContextMenu={handleContextMenu}
                                />
                            );
                        })}
                    </ul>
                </div>
            )}
            <ul className="list-group facilities unselected-items">
                <InfiniteScroll
                    ref={ref}
                    onReachBottom={onReachBottom}
                    onReachTop={onReachTop}

                >
                    {sliced_data.map((facility) => {
                        return (
                            <WellElement
                                key={facility.p2_merrick_id}
                                facility={facility}
                                handleChange={handleChange}
                                selector_type={selector_type}
                                disabled={disabled}
                                // handleContextMenu={handleContextMenu}
                                hidden={
                                    selector_type === "checkbox" &&
                                    facility.is_selected
                                }
                            />
                        );
                    })}
                </InfiniteScroll>
            </ul>
        </Div>
    );
};

export default WellElements;

const Div2 = styled.div`
    /* input:active {
        filter: none;
    } */

    /* label > div {
        overflow-x: hidden !important; //auto
        padding-bottom: 15px;
        -webkit-overflow-scrolling: touch;
    } */

    label {
        //parent
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }

    label > div {
        //child1
        /* flex-grow: 1;
        min-width: 0;
        overflow-x: hidden !important; //auto */
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        white-space: normal;
    }

    label > i {
        //child2
        flex-shrink: 0;
    }





    .fa-ellipsis {
        padding: 0.25rem 0.25rem;
    }

    .fa-ellipsis:hover {
        cursor: pointer;
        /* border: 1px solid #ced4da; */
        background-color: #e9ecef;
        border-radius: 0.25rem;
    }
`;

const WellElement = ({
    facility,
    handleChange,
    handleContextMenu,
    selector_type,
    disabled,
    hidden,
}) => {

    const [isMouseDown, setIsMouseDown] = useState(false);

    //prevent label to become active
    const handleMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsMouseDown(true);
        console.log("label clicked");
    };

    const handleMouseUp = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsMouseDown(false);
    }
    const style = {
        display: hidden ? "none" : "block",
    }


    return (
        <Div2
            className="list-group-item"
            style={style}
        >
            <label
                id={facility.p2_merrick_id}
                className={
                    "justify-content-between " +
                    (hidden ? "" : " d-flex")
                }
            >
                <div className="me-2">
                    <input
                        className="form-check-input me-1"
                        checked={facility.is_selected?facility.is_selected:false}
                        name="facility"
                        type={selector_type}
                        onChange={(e) => handleChange(facility.p2_merrick_id)}
                        disabled={facility.is_disabled || disabled}
                    />
                    {/* display facility.p2_well_name in gray if disabled */}
                    <span
                        className={
                            facility.is_disabled || disabled ? "text-muted" : ""
                        }
                    >
                        {facility.p2_well_name}
                        {facility.is_cached && (
                            <i
                                className="fas fa-check ms-1"
                                style={{ color: "green" }}
                            ></i>
                        )}
                    </span>
                </div>

                {/* <i class="fa-regular fa-ellipsis fa-beat"></i> */}

                {handleContextMenu && (
                <i
                    className="fas fa-ellipsis"
                    onClick={handleContextMenu}
                ></i>
                )}
            </label>
        </Div2>
    );
};
