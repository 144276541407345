import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import { addGroupAction, addGroupDBAction, updateGroupAction, updateGroupDBAction } from "../store_lib/actions/userDataActions";
import { useDispatch } from "react-redux";

const Div = styled.div`
.color-picker {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }

      .color-picker:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      .color-picker input[type="color"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }


`;

export const GroupEditModal = (props) => {
    const { mode, showModal, handleCloseModal, group_data } = props;

    const {color, name, description, is_shared} = group_data;

    const dispatch = useDispatch();

    let title;
    if (mode === "add") {
        title = "Add Group";
    } else if (mode === "edit") {
        title = "Edit Group";
    } else {
        console.error("Invalid mode");
    }

    
    //changed properties
    const [new_color, setColor] = React.useState(color);
    const [new_name, setName] = React.useState(name);
    const [new_is_shared, setIsShared] = React.useState(is_shared);
    const [new_description, setDescription] = React.useState(description);

    const style = {
        backgroundColor: new_color,
    }

    // console.log(group_data)

    const handleSaveAndClose = async () => {
        let res = null;
        try {
            if (mode === "edit") {
                // await dispatch(updateGroupAction({
                await dispatch(updateGroupDBAction({
                    id: group_data.id,
                    name: new_name,
                    description: new_description,
                    is_shared: new_is_shared,
                    color: new_color,
                    wells: group_data.wells,
                    owner: group_data.owner,
                }));
            }
            else if (mode === "add") {
                // await dispatch(addGroupAction({
                res = await dispatch(addGroupDBAction({
                    name: new_name,
                    description: new_description,
                    is_shared: new_is_shared,
                    color: new_color,
                    owner: group_data.owner,
                    wells: [],
                }));
            }
            handleCloseModal(res);
        } catch (error) {
            alert(error);
        }
    };

    const discardAndClose = () => {
        setColor(color);
        setName(name);
        setIsShared(is_shared);
        setDescription(description);
        handleCloseModal();
    };

    useEffect(() => {
        if (!showModal) return;
        setColor(color);
        setName(name);
        setIsShared(is_shared);
        setDescription(description);
    }, [showModal]);

    return (
        <Modal centered show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Div>
                <form>
                <div className="mb-3">
                    <label htmlFor="group-name" className="form-label">
                        Group Name
                    </label>
                    <div className="d-flex align-items-center">
                        <div 
                            style={style}
                        className="color-picker me-2">
                            <input 
                                style={style}
                                value={new_color}
                                onChange={(e) => setColor(e.target.value)}
                            type="color" />
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            // id="group-name"
                            value={new_name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter group name"
                            required
                        />
                        <div className="invalid-feedback">
                            Please enter a group name.
                        </div>
                    </div>
                </div>
                {/* description */}
                <div className="mb-3">
                    <label htmlFor="group-description" className="form-label">
                        Description
                    </label>
                    <textarea
                        className="form-control"
                        id="group-description"
                        value={new_description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Enter group description"
                        required
                    />
                    <div className="invalid-feedback">
                        Please enter a group description.
                    </div>
                </div>
                {/* is shared (boolean) */}
                <div className="mb-3 form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="group-is-shared"
                        checked={new_is_shared}
                        onChange={(e) => setIsShared(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="group-is-shared">
                        Is shared
                    </label>
                </div>

            </form>
        </Div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"
                    disabled={!new_name}
                    onClick={handleSaveAndClose}>
                    Save and Close
                    </Button>
                    <Button variant="primary" onClick={discardAndClose}>
                    Discard and Close
                    </Button>
                </Modal.Footer>
            </Modal>
    );
};
