import React from 'react'
import MultiWellSurveillanceApp from '../applications/multi_well_surveillance/MultiWellSurveillanceApp'

function MultiWellSurveillance() {
  return (
    <>
        <MultiWellSurveillanceApp/>
    </>
  )
}

export default MultiWellSurveillance