
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { requestErrorToText } from "../General/DisplayRequestError";
import GroupsModal from "../Groups/GroupsModal";

const MainBody = styled.div`
    /* min-height 100vh - height 105.062px */

    min-height: calc(100vh - 105.062px);
`;

const InnerAppLayout = (props) => {

    const { wells_loaded, well_data_error, plot_mounted, leftPane, rightPane,show_settings, setShowSettings, clearSettings } = props;

    // //oncomponentmount
    // useEffect(() => {
    //     console.log('component mounted')
    // }, []);

    // //component update
    // useEffect(() => {
    //     console.log('component updated')
    // });

    const [showGroupsMenu, setShowGroupsMenu] = useState(false);

    const handleCloseModal = () => setShowGroupsMenu(false);



    return (
        <>
        {showGroupsMenu && (
        <GroupsModal
            showModal={showGroupsMenu}
            handleCloseModal={handleCloseModal}
         />
        )}
        {!wells_loaded ? (
            !well_data_error ? (
                <div
                    className="maincontainer col-12 d-flex justify-content-center align-items-center"
                    style={{
                        flex: "1",
                    }}
                >
                    <div>
                        {/* <i className="fa fa-spinner" aria-hidden="true"></i> */}
                        {/* <i className="fa-solid fa-spinner fa-spin"></i> */}
                        <i className="fas fa-cog fa-spin"></i>
                        <span> Loading...</span>
                    </div>
                </div>
            ) : (
                <div
                    className="maincontainer col-12 d-flex justify-content-center align-items-center"
                    style={{ flex: "1" }}
                >
                    <div>
                        {/* <i className="fa fa-spinner" aria-hidden="true"></i> */}
                        {/* <i className="fa-solid fa-spinner fa-spin"></i> */}

                        <h4>
                            <i className="fas fa-exclamation-triangle"></i>{" "}
                            {requestErrorToText(well_data_error)}
                        </h4>
                        {/* <i className="fas fa-exclamation-triangle"></i> */}
                    </div>
                </div>
            )
        ) : (
            <MainBody className="d-flex flex-wrap flex-md-nowrap justify-content-center">
                <div
                    className="maincontainer col-12 col-md-4 col-lg-6 col-xl-5 col-xxl-4"
                    style={{
                        minHeight: "100%",
                        display:
                            show_settings || !plot_mounted
                                ? "block"
                                : "none",
                    }}
                >
                    <div className="p-3">
                        <div className="justify-content-between align-items-center d-flex">
                            <h2>Settings</h2>
                            <div>
                            {plot_mounted && (
                                <button
                                    type="button"
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={() => setShowSettings(false)}
                                >
                                    Hide
                                </button>
                            )}
                            <button
                                    type="button"
                                    className="btn btn-outline-primary btn-sm ms-2"
                                    onClick={() => clearSettings()}
                                >
                                    Reset
                                </button>
                            <button
                                    type="button"
                                    className="btn btn-outline-primary btn-sm ms-2"
                                    onClick={() => setShowGroupsMenu(true)}
                                >
                                    Groups
                                </button>
                                </div>
                        </div>
                        <hr />
                    </div>

                    {leftPane}
                </div>

                <div
                    className={
                        show_settings
                            ? "p-0 col-12 col-md-8 col-lg-6 col-xl-7 col-xxl-8"
                            : "p-0 col-12"
                    }
                >
                    {rightPane}

                    {/* <CommentsTable /> */}
                </div>
            </MainBody>
        )}
        </>
    )
}

export default InnerAppLayout;