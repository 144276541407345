import { setPlotMountedAction } from "bkv-components/store_lib/actions/settingsActions";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MultiTracePlot from "../MultiTracePlot";


// const engineering_settings = {
//   gas_vs_date: {
//       x: "record_time",
//       x_title: "Production Date",
//       y_title: "GAS (mcf)",
//       base_unit: "mfc",
//       x_type: "date",
//   },
//   gas_vs_cumulative_gas: {
//       x: "cumulative",
//       x_title: "GAS Cumulative (mcf)",
//       y_title: "GAS (mcf)",
//       base_unit: "mfc",
//       x_type: "log",
//   },
//   inverse_gas_vs_sqrt_date: {
//       x: "sqrt_t",
//       x_title: "Square Root Time (days^1/2)",
//       y_title: "Inverse GAS (1/mcf)",
//       base_unit: "1/mfc",
//   },
//   water_vs_cumulative_water: {
//       x: "cumulative",
//       x_title: "WATER Cumulative (bbl)",
//       y_title: "WATER (bbl)",
//       base_unit: "bbl",
//       x_type: "log",
//   },
// };

export const axis_settings = {
  production_date: {
    type: "date",
    title: "Production Date",
    unit: "",
  },
  gas_production: {
    type: "log",
    title: "Gas Production (mcf)",
    unit: "mcf",
  },
  water_production: {
    type: "log",
    title: "Water Production (bbl)",
    unit: "bbl",
  },
  gas_cumulative: {
    type: "log",
    title: "Gas Cumulative (mcf)",
    unit: "mcf",
  },
  water_cumulative: {
    type: "log",
    title: "Water Cumulative (bbl)",
    unit: "bbl",
  },
  sqrt_t: {
    type: "log",
    title: "Square Root Time (√d)",
    unit: "√d",
  },
  t: {
    type: "log",
    title: "Time since IP (days)",
    unit: "days",
  },
  reciprocal_gas_rate: {
    type: "log",
    title: "Reciprocal Gas Rate (1/mcf)",
    unit: "1/mcf",
  },
  mbt: {
    type: "log",
    title: "Material Balance Time (MBT)",
    unit: "",
  }
}

export const AllEngineeringPlotsV2 = (props) => {
    const { data, plot_colors } = props;

    const plot_x_axis = useSelector((state) => state.daily_app_settings.plot_x_axis);
    const plot_y_axis = useSelector((state) => state.daily_app_settings.plot_y_axis);
    const x_axis_scale = useSelector((state) => state.daily_app_settings.x_axis_scale);
    const y_axis_scale = useSelector((state) => state.daily_app_settings.y_axis_scale);

    const data_x = data[plot_x_axis];
    const data_y = data[plot_y_axis];

    const plot_settings = {
        connectgaps: false,
        plot_height: 700,
        plot_colors: plot_colors,
        square_ratio: plot_x_axis === "mbt" ? true : false,
    };

    const engineering_settings = {
        x_title: axis_settings[plot_x_axis].title,
        y_title: axis_settings[plot_y_axis].title,
        base_unit: axis_settings[plot_y_axis].unit,
        x_type: x_axis_scale,
        y_type: y_axis_scale,
    };

    const dispatch = useDispatch();

    const onAfterPlot = (figure) => {
        dispatch(setPlotMountedAction(true));
    };

    const plot_mounted = useSelector((state) => state.settings.plot_mounted);



    return (
        <div className="maincontainer element p-4">
            <h4>{axis_settings[plot_y_axis].title + " vs " + axis_settings[plot_x_axis].title}</h4>
            <hr />
            <MultiTracePlot
                x={data_x}
                y={data_y}
                plot_settings={plot_settings}
                extra_settings={engineering_settings}
                onAfterPlot={onAfterPlot}
                plot_mounted={plot_mounted}
                // revision={revision}
            />
        </div>
    );
};
