import ArcgisMapSelection from "bkv-components/General/arcgis/ArcgisMapSelection";
import { updateFacilities } from "bkv-components/store_lib/actions/settingsActions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const MapSelection = () => {
    const well_data_hashed = useSelector((state) => state.raw_data.well_data_hashed);
    const well_data = useSelector((state) => state.raw_data.well_data);
    const active_p2_merrick_ids = useSelector(
        (state) => state.settings.active_p2_merrick_ids
    );
    const plot_loading = useSelector((state) => state.settings.plot_loading);
    const filters = useSelector((state) => state.settings.filters);
    const current_group = useSelector((state) => state.user_data.current_group);

    const dispatch = useDispatch();

    const handleChange = async (new_selection) => {
        dispatch(updateFacilities(new_selection));
    };

    const [selection, setSelection] = useState([])

    useEffect(() => {
        let selection = active_p2_merrick_ids ? active_p2_merrick_ids : [];
        setSelection(selection)
    }, [active_p2_merrick_ids])


    return (
        <>
        {/* <MapboxMap
            filters={filters}
            well_data={well_data}
            selection={selection}
            onSelectionChange={handleChange}
            selector_type="checkbox"
            available_field="daily_data_available"
            disabled={plot_loading}
            max_selected={10}
        /> */}

        <ArcgisMapSelection
        filters={filters}
        well_data={well_data}
        current_group={current_group}
        well_data_hashed={well_data_hashed}
        selection={selection}
        onSelectionChange={handleChange}
        selector_type="checkbox"
        disabled={plot_loading}
        max_selected={5}
        
    /> 

</>
    );
};

export default MapSelection;
