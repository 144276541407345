import React, { useState } from "react";
import WellInformation from "bkv-components/General/WellInformation";
import UserPlotSettings from "bkv-components/SensorPlot/UserPlotSettings";
import { Tab, Tabs } from "react-bootstrap";
import { LeftPaneFlatLayout } from "bkv-components/Layout/LeftPaneFlatLayout";
import { SelectionComponent } from "./SelectionComponent";
import { useSelector } from "react-redux";

export const LeftPane = (props) => {
    const [pane, setPane] = useState("A");

    const onSwitchPane = () => {
        if (pane === "A") {
            setPane("B");
        } else {
            setPane("A");
        }
    };

    // const FacilitySelectionComponent = <SelectionComponent type='facility' onSwitchPane={onSwitchPane} />;
    // const MapSelectionComponent = <SelectionComponent type='map' />;

    return (
        <div>
            {pane === "A" ? (
                <LeftPaneFlat onSwitchPane={onSwitchPane} />
            ) : (
                <LeftPaneTabs onSwitchPane={onSwitchPane} />
            )}
        </div>
    );
};

const LeftPaneFlat = (props) => {
    const { onSwitchPane } = props;
    const user_plot_settings = useSelector(
        (state) => state.settings.user_plot_settings
    );
    const plot_mounted = useSelector((state) => state.settings.plot_mounted);

    return (
        <LeftPaneFlatLayout
            LeftChild={
                <SelectionComponent
                    type="facility"
                    onSwitchPane={onSwitchPane}
                />
            }
            RightChild={
                <>
                    {plot_mounted && (
                        <div>
                            <UserPlotSettings
                                user_plot_settings={user_plot_settings}
                            />
                            <br />
                        </div>
                    )}
                    <WellInformation style={{ marginBottom: "100px" }} />
                </>
            }
        />
    );
};

const LeftPaneTabs = (props) => {
    const { onSwitchPane } = props;
    const user_plot_settings = useSelector(
        (state) => state.settings.user_plot_settings
    );
    const [key, setKey] = useState("well_selection");
    const [key_2, setKey_2] = useState("facility_selection");
    return (
        <div className="ps-3 pe-3 left-pane-tab-container">
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="well_selection" title="Well Selection">
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key_2}
                        onSelect={(k) => setKey_2(k)}
                        className="mb-3"
                    >
                        <Tab
                            eventKey="facility_selection"
                            title="Filter Selection"
                        >
                            <SelectionComponent
                                type="facility"
                                onSwitchPane={onSwitchPane}
                            />
                        </Tab>
                        <Tab eventKey="map_selection" title="Map Selection ">
                            {/* {key_2 === "map_selection" && ( */}
                                <div style={{ height: "800px", width: "100%" }}>
                                    <SelectionComponent type="map" />
                                </div>
                            {/* )} */}
                        </Tab>
                    </Tabs>
                </Tab>
                <Tab eventKey="settings" title="Plot Settings">
                    <UserPlotSettings
                        user_plot_settings={user_plot_settings}
                        noTitle
                    />
                </Tab>
                <Tab eventKey="well_information" title="Well Info">
                    <WellInformation
                        style={{ marginBottom: "100px" }}
                        noTitle
                    />
                </Tab>
            </Tabs>
        </div>
    );
};
