import UserPlotSettings from "bkv-components/SensorPlot/UserPlotSettings";
import FacilitySelection from "./FacilitySelection";
import PlotSelection from "./PlotSelection";
import UdcSelection from "../daily_production_plot/UdcSelection";
import FacilitySubSelection from "../engineering_plots/FacilitySubSelection";
import PlotSubSelection from "../engineering_plots/v1/PlotSubSelection";
import { EngineeringDataSelection } from "../engineering_plots/v2/EngineeringDataSelection";
import { useSelector } from "react-redux";
import { LeftPaneFlatLayout } from "bkv-components/Layout/LeftPaneFlatLayout";

const LeftPaneFlat = (props) => {
    const { onSwitchPane } = props;

    return (
        <LeftPaneFlatLayout
        LeftChild={<FacilitySelection onSwitchPane={onSwitchPane} />}
        RightChild={<PlotSelectionAndSettings/>}
    />
    );
};

export default LeftPaneFlat;


export const PlotSelectionAndSettings = (props) => {

    const plot_selection = useSelector(
        (state) => state.daily_app_settings.plot_selection
    );

    const active_p2_merrick_ids = useSelector(
        (state) => state.settings.active_p2_merrick_ids
    );

    let facilities_selected = false;
    if (active_p2_merrick_ids) {
        facilities_selected = active_p2_merrick_ids.length > 0;
    }


    const user_plot_settings = useSelector(state => state.settings.user_plot_settings)


    return(
        <div>
                    <PlotSelection />
                    {plot_selection === "daily_production" && (
                        <>
                            <UdcSelection />
                            <UserPlotSettings
                                user_plot_settings={user_plot_settings}
                                disabled_settings={[
                                    // "merged_pressure_plots",
                                    "log_INSFLG0",
                                    // "hide_comments",
                                    // "plot_separation",
                                ]}
                            />
                        </>
                    )}
                    {plot_selection === "engineering_plots" &&
                        facilities_selected && (
                            <>
                                <FacilitySubSelection />
                                <PlotSubSelection />
                            </>
                        )}
                    {plot_selection === "engineering_data" &&
                        facilities_selected && (
                            <>
                                <FacilitySubSelection />
                                <EngineeringDataSelection />
                                {/* TODO add user plot settings / improve UserPlotSettings */}
                                {/* <UserPlotSettings
                                user_plot_settings={user_plot_settings}
                                disabled_settings={[
                                    "y_axis_zoom_and_pan",
                                    "merged_pressure_plots",
                                    "log_INSFLG0",
                                    "hide_comments",
                                    "plot_separation",
                                ]} 
                            /> */}
                            </>
                        )}

                    <br />
                </div>
    )
}