//Dimensions for device media queries and layout padding
const dimensions = {
    //pixels
    maxwidthDesktop: 500,
    maxwidthTablet: 76800,
    maxwidthMobile: 600,
    // maxwidthDesktop: 1100,
    // maxwidthTablet: 768,
    // maxwidthMobile: 600,



    //ems
    paddingHorizontalDesktop: 4,
    paddingHorizontalTablet: 2.5,
    paddingHorizontalMobile: 2,
}

export default dimensions;